<template>
  <span v-if="event" v-html="icon" :class="classes"></span>
  <span v-else v-html="icon" @click.stop="$emit('event')" :class="classes"></span>
</template>

<script>
import icons from '../UI/icons'

export default {
  props: {
    name: String,
    verAlign: Boolean,
    event: {
      type: Boolean,
      default: false
    }
  },

  emits: ['event'],

  data() {
    return {
      icon: icons[this.name],
    }
  },

  computed: {
    classes() {
      const classes = ['ico']

      if (this.verAlign) {
        classes.push('ico--align')
      }
      return classes
    },
  },
}
</script>

<style lang="scss">
.ico {
  transition: opacity 0.3s;
  display: flex;
  &:hover {
    cursor: pointer;
  }
  &--align {
    display: flex;
    align-items: center;
  }
}
</style>
