const users = '/users'
const user = '/user'
const ticket = '/ticket'
const tickets = '/tickets'
const usersInfo = '/variables'
const notifications = '/notifications'
const posts = '/posts'
const category = '/categories'
const testimonials = '/testimonials'
const videotestimonials = '/video-testimonials'
const chat = '/chat'
export default {
  users: {
    getAll:(request) => `${users}${request}`,
    getById: (id) => `${users}/${id}`,
    getAllFollowers: `${users}/methods/myfollowers`,
    isAccountByHash: (hash) => `${users}/methods/isaccount?id=${hash}`,
    getByField: `${users}/methods/getaccaount`,
    getBatchAccounts: `${users}/methods/get-accounts`,
    getTactileFollowers:`${users}/methods/get-structure-followers`,
    authMessage: `${users}/auth/message`,
    authVerify: `${users}/auth/verify`,
    register: `/new/new_user`,
    isLevelActive: '/users/methods/islevelactive',
    getParentsCount: 'users/methods/getparentscount',
    setLevel: '/users/methods/setlevel',
    getTransactions: `/users/methods/transactions`,
    sendTransaction: '/transactions/send-transaction',
    usersInfo: `${usersInfo}`,
    getUserEndpoint: `/profile`,
    searchUsers: `/search/users`,
    privacy: '/user/privacy',
  },
  wallet: {
    getWalletHistory: '/wallet-history',
  },
  ticket: {
    topics: `${ticket}/topics`,
    create: `${ticket}/create`,
    list: `${tickets}`,
    faq: `${ticket}/faq`,
    makeRead: `${ticket}/make-read`,
    state: (id) => `${tickets}/state/${id}`,
    getById: (id) => `${tickets}/${id}`,
    close: (id) => `${ticket}/${id}/close`,
    getMessages: (id) => `${ticket}/${id}/messages`,
    createMessage: (id) => `${ticket}/${id}/send-message`,
    getNotifications: (userId) => `${notifications}?user_id=${userId}`,
    setNotificationItem: (id) => `${notifications}/${id}/statuses`,
    setReadNotifications: `${notifications}/statuses`,
    feedback: (id) => `${ticket}/${id}/feedback`,
  },
  post: {
    posts: (payload) => `${posts}${payload}`,
    categories: `${category}`,
    category: (id) => `${category}/${id}`,
    like: (id) => `${posts}/${id}/like`,
    post: (id) => `${posts}/${id}`,
    landingPosts: `/landing/posts`,
    filterPosts: `/filtration${posts}`
  },
  messenger: {
    message: `/message`,
    createChat: `${chat}/create`,
    getMessages: `${chat}/get`,
    deleteChat: `${chat}/delete`,
    getMyChats: `/mychats`,
    complaint: '/complaint',
    upload: '/upload',
    getPrivacy: '/privacy',
    getBlockList: `/user/getBlockers`,
    getBlockStatus: 'user/block-status',
    unBlock: `/user/unblock`,
    setBlock: `/user/block`,
    deleteFile: '/delete'
  },
  social: {
    following: `${user}/following`,
    followers: `${user}/followers`,
    follow: `${user}/follow`,
    unFollow: `${user}/unfollow`,
    unLink: `${user}/unlink`,
  },
  publicPost: {
    myPublic: (id) => `/publications-user/${id}`,
    followPublic: '/publications-user-followers',
    likedPublic: (id) => `/publications-liked/${id}`,
    addMyPost: '/publications',
    getOnePost: (id) =>`/publications/${id}`,
    likePost: (id) =>`/publications/${id}/like`,
    addComment: (id) => `/comments/${id}/create`,
    getComments: (id) => `/comments/${id}`,
    editComments: (id) => `/comments/edit/${id}`,
    deleteComments: (id) => `/comments/delete/${id}`,
    category: (id) => `${category}/${id}`
  },
  testimonial: {
    getTestimonials: (payload) => `${testimonials}${payload}`,
    getVideoTestimonials: (payload) => `${videotestimonials}`,
    setTestimonials: `${testimonials}`,
  }
}
