<template>
  <UiModalService :visible="isModal" @closeModal="closeServiceModal">
    <div class="serviceModal" id="menuModal" @click.stop :class='!isModal && "closeModalService"'>
      <div class="serviceModal-main-title">
        {{$t('serviceModal.title')}}
      </div>
      <button class="btn-close">
        <BaseIcon name='closeServiceIcon' @event='closeServiceModal' ver-align />
      </button>
      <button
        class='serviceModal__connectBtn'
        v-if='!getAccount'
        @click.stop='handleAuthClick'
      >{{$t('connectWallet')}}</button>

      <div class="serviceModal-content">
        <div class="content-xs">
          <div class="logo">
            <BaseIcon @event='toLandingPage' name="mainLogo" verAlign />
          </div>
          <div class="currencies" v-if='getAccount'>
            <div class="currency">
              <div class="currency__img">
                <BaseIcon :name="headerMatic.iconName" verAlign />
              </div>
              <div class="currency__balance">
                {{ shortAmount(headerMatic.amount) }} {{ headerMatic.label}}
              </div>
            </div>
            <div class="currency">
              <div class="currency__img">
                <BaseIcon :name="headerDai.iconName" verAlign />
              </div>
              <div class="currency__balance">
                {{ shortAmount(headerDai.amount) }} {{ headerDai.label}}
              </div>
            </div>
            <div class="currency">
              <div class="currency__img">
                <BaseIcon :name="headerForceCoin.iconName" verAlign />
              </div>
              <div class="currency__balance">
                {{ shortAmount(headerForceCoin.amount) }} {{ headerForceCoin.label}}
              </div>
            </div>
          </div>
          <button class="menu-btn" v-if='getCurrentUser' @click.stop="isModalHandler">
            <BaseIcon name="wallet" verAlign @event="isModalHandler" />
            {{ $t('register.wallet') }}
          </button>
          <button class="menu-btn" v-if='getCurrentUser' @click.stop="openNotice">
            <span class="menu-btn_bellCount" v-show="getUnreadNotifications" />
            <BaseIcon name="bell" verAlign @event="openNotice" />
            {{ $t('dashboardInfo.notification.title') }}
          </button>
          <ul class="serviceModal-list menu-list" v-if='!showActualPages'>
            <li
              v-for="pages in renderItems"
              :key="pages.id"
              :class="$route.name === pages.link ? 'activeNavLink' : 'serviceModal-list__btn'"
              @click='toPage(pages)'
            >
              <div class="serviceModal-list__data">
                <span v-if="pages.link === 'messenger'" class="beta">beta</span>
                <span>{{ $t(pages.title) }}</span>
                <span v-if="pages.link === 'messenger' && getTotalUnread && getToken" class="bellNotice">{{ getTotalUnread }}</span>
                <span v-if="pages.link === 'help' && (ticketUpdate || unreadHelpMessages)" class="bellNotice">{{ unreadHelpMessages }}</span>
              </div>
            </li>
          </ul>

        </div>
        <div class="content-lg">
          <div class="serviceModal-title hidden-lg">
            <BaseIcon name='serviceIcon' verAlign /> {{$t('serviceModal.title')}}
          </div>
          <ul class="serviceModal-list menu-list">
            <li
              v-for='item in renderServiceItems'
              :key='item.id'
              :class="$route.name === item.link ? 'activeRouteLink' : ''"
              @click='toPage(item)'
            >
              <div :class="item.link === '' && 'notReadyLink'" class="serviceModal-list__btn">
                <div class="serviceModal-list__img">
                  <BaseIcon :name='item.iconName' @event='toPage(item)'/>
                </div>
                <div class="serviceModal-list__data">
                  <h4>{{ $t(item.title) }}</h4>
                  <p>{{ item.desc }}</p>
                </div>
              </div>
            </li>
            <div class="serviceModal-subtitle">
              Other
            </div>
            <li :class="$route.name === 'Reviews' ? 'activeRouteLink' : ''" @click='toPageReviews("Reviews")'>
              <div class="serviceModal-list__btn">
                <div class="serviceModal-list__img">
                  <BaseIcon name="starWhiteFull" :event='true'/>
                </div>
                <div class="serviceModal-list__data">
                  <h4>Testimonials</h4>
                </div>
              </div>
            </li>
            <li :class="$route.name === 'HomePromotion' ? 'activeRouteLink' : ''" @click='toPageReviews("HomePromotion")'>
              <div class="serviceModal-list__btn">
                <div class="serviceModal-list__img">
                  <BaseIcon name="promo" :event='true'/>
                </div>
                <div class="serviceModal-list__data">
                  <h4>Promotion</h4>
                </div>
              </div>
            </li>
            <li>
              <div class="serviceModal-list__btn notReadyLink">
                <div class="serviceModal-list__img">
                  <BaseIcon name="copyLink"/>
                </div>
                <div class="serviceModal-list__data">
                  <h4>Management links</h4>
                  <p>Coming soon</p>
                </div>
              </div>
            </li>
          </ul>
          <div class="serviceModal-title mobileLanguage">
            <BaseIcon name='socialMedia' verAlign /> {{$t('serviceModal.language')}}
          </div>
          <ul class="languages-list">
            <li
              v-for='lang in options'
              :key="lang"
              :class='$i18n.locale === lang && "active"'
              @click="selectOption(lang)"
            >
              {{ lang }}
            </li>
          </ul>
        </div>
        <div class="content-xs" v-if='getCurrentUser'>
          <div class='copyMyLinkService' @click.stop='copyMyLink'>
            <div class='copyMyLinkService-item'>
              <BaseIcon name='copyLink' @event='copyMyLink' />
              <p>{{ $t('settings.copyMyLink') }}</p>
            </div>
            <BaseIcon name='closedIconCopy' @event='copyMyLink' />
          </div>
          <div class="force-id" data-parent="menu-backdrop" data-toggle="dropdown" @click.stop="goToProfile">
            <div class="force-id__info">
              <div class="force-id__img">
                <UiAvatar
                  :user="getCurrentUser"
                  alt="user logo"
                  class="sidebar__closedImg-ico"
                />
              </div>
<!--              <p v-if='getCurrentUser.name'>{{ getCurrentUser.name || 'Force ID' }}</p>-->
              <p v-if='getCurrentUser.name'> {{ $t('sidebarBottom.myProfile') }}</p>

            </div>
            <BaseIcon name="arrowRight" @event="goToProfile"/>
          </div>
        </div>
      </div>
    </div>
  </UiModalService>
</template>
<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'
import { mapGetters, mapMutations } from 'vuex'
import { copyToClipboard } from '@/helpers/clipboard'
import { shortSumToken } from '@/helpers/shortSumToken'
import { LANGS } from '@/config/constants'

export default {
  components: { BaseIcon },
  props: {
    isModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      actualNewsPortal: 1,
      isOpen: false,
      options: LANGS,
      items: [
        {
          id:1,
          title: 'menu.mainPage',
          link: 'dashboard',
          auth: true,
        },
        {
          id:2,
          title: 'menu.forceSystem',
          link: 'classic',
          auth: true,
        },
        {
          id:3,
          title: 'menu.uniteVerse',
          link: 'uniteverse',
          auth: true,
        },
        {
          id:4,
          title: 'menu.forceCoin',
          link: 'forceCoin',
          auth: true,
        },
        {
          id:5,
          title: 'menu.referral',
          link: 'systems_referral',
          auth: true,
        },
        {
          id:6,
          title: 'menu.messenger',
          link: 'messenger',
          auth: true,
        },
        {
          id:7,
          title: 'menu.search',
          link: 'search',
          auth: true,
        },
        {
          id:8,
          title: 'menu.settings',
          link: 'settings',
          auth: true,
        },
        {
          id:9,
          title: 'menu.help',
          link: 'help',
          auth: true,
        },
      ],
      serviceItem: [
        {
          id:1,
          title: 'menu.mainPage',
          link: 'dashboard',
          iconName: 'homePageNew',
          // desc: 'Home page',
          auth: true,
          anotherResource: false,
        },
        {
          id:2,
          title: 'menu.newsEvents',
          link: 'academy',
          iconName: 'newsAndEvents',
          // desc: 'Portal with news and events',
          linkParams: { locale: this.$i18n.locale, id: 1 },
          anotherResource: false,
        },
        {
          id:3,
          title: 'menu.newsEvents',
          link: 'academy',
          iconName: 'newsAndEvents',
          // desc: 'Portal with news and events',
          linkParams: { locale: this.$i18n.locale, id: 1 },
          auth: true,
          anotherResource: false,
        },
        {
          id:4,
          title: 'menu.forceSystem',
          link: 'classic',
          iconName: 'forceSystem',
          // desc: 'Tactile / Uniteverse / Royalty NFT',
          auth: true,
          anotherResource: false,
        },
        {
          id:5,
          title: 'menu.academy',
          link: 'https://academy.meta-force.space/',
          iconName: 'academyNew',
          // desc: 'Portal with Academy',
          linkParams: {locale: this.$i18n.locale },
          anotherResource: true,
        },
        {
          id:6,
          title: 'menu.academy',
          link: 'https://academy.meta-force.space/',
          iconName: 'academyNew',
          // desc: 'Portal with Academy',
          linkParams: {locale: this.$i18n.locale },
          auth: true,
          anotherResource: true,
        },
        {
          id:7,
          title: 'menu.nftMarketplace',
          link: 'https://market.meta-force.space/',
          iconName: 'nftMarketplace',
          toMarket: true,
        },
        {
          id:8,
          title: 'menu.nftMarketplace',
          link: 'https://market.meta-force.space/',
          iconName: 'nftMarketplace',
          auth: true,
          toMarket: true,
        },
        {
          id:9,
          title: 'menu.contests',
          link: 'contests',
          iconName: 'contests',
          auth: true,
          anotherResource: false,
        },
        {
          id:10,
          title: 'menu.contests',
          link: 'contests',
          iconName: 'contests',
          anotherResource: false,
        },
        {
          id:11,
          title: 'menu.metaverse',
          link: '',
          iconName: 'metaverse',
          desc: 'Coming soon',
          auth: true,
          anotherResource: false,
        },
      ],
      forRegister: [

      ]
    }
  },
  computed: {
    ...mapGetters('user', ['getCurrentUser']),
    ...mapGetters('ticket', ['getUnreadNotifications', 'ticketUpdate', 'unreadHelpMessages']),
    ...mapGetters('crypto',
      ['isActiveWallet',
        'getToken',
        'getAccount',
        'getBalance',
        'getInnerCoins',
        'getTotalDai'
      ]),
    ...mapGetters('messenger', ['getTotalUnread']),
    renderItems() {
      return this.isAuthPathFilter(this.items)
    },
    renderServiceItems() {
      return this.isAuthPathFilter(this.serviceItem)
    },
    refLink() {
      return `https://${window.location.host}/r/${this.getCurrentUser.name}`
    },
    showActualPages() {
      return this.$route.name === "academy" || this.$route.name === "showCategory" || this.$route.name === "showPost";
    },
    statTokens() {
      if (!this.getBalance) return []
      const res = []
      const coinValues = Object.values(this.getBalance).map((el) => +el)
      const coinNames = ['Matic', 'SFC old', 'SFC new', 'SFC2', 'DAI', 'Energy']
      coinValues.forEach((el, idx) => {
        res.push({
          iconName: `header${coinNames[idx].replace(/[' ']/gi, '')}`,
          label: coinNames[idx],
          amount: el,
        })
      })
      return res
    },
    headerMatic() {
      return this.statTokens.find((el) => el.iconName.includes('headerMatic'))
    },
    headerDai() {
      let dai = this.statTokens.find((el) => el.iconName.includes('headerDAI'))
      dai.amount = this.getTotalDai
      return dai
    },
    headerForceCoin() {
      let forceCoin = {
        iconName: 'headerForceCoin',
        label: 'MFS',
        amount: ''
      }
      forceCoin.amount = this.getInnerCoins[1]
      return forceCoin
    },
  },
  methods: {
    ...mapMutations('ui', ['setConnectionModal', 'setIsCopyLinkModal', 'setOpenModal']),
    ...mapMutations('post', ['setCategoryId']),

    goToProfile() {
      if (this.$route.name === 'profile') {
        this.closeServiceModal()
        return
      }
      this.$router.push({name: 'profile'})
      this.closeServiceModal()

    },
    shortAmount(amount) {
      if (amount) {
        return shortSumToken(amount)
      }
      return '0.0'
    },
    handleAuthClick() {
      this.$emit('closeModal')
      this.setConnectionModal({ visible: true, type: 'connect' })
      this.closeServiceModal()
    },
    handleClipboardCopyAcc() {
      if (!this.getCurrentUser) return
      copyToClipboard(this.getCurrentUser.name)
        .then(() => {
          this.$toast.success(`${this.getCurrentUser.name} ${this.$t('account.copied')}`)
        })
        .catch(() => {
          this.$toast.error(`${this.$t('account.copyError')}`)
        })
    },
    copyMyLink() {
      this.setIsCopyLinkModal(true)
    },
    handleClipboardCopy() {
      if (!this.getCurrentUser) return
      copyToClipboard(this.refLink)
        .then(() => {
          this.$toast.success(`${this.refLink} ${this.$t('account.copied')}`)
        })
        .catch(() => {
          this.$toast.error(`${this.$t('account.copyError')}`)
        })
    },
    isAuthPathFilter(data) {
      if (this.$route.query.account) {
        return data.filter((x) => x.link !== 'uniteverse' && x.link !== 'forceCoin' && x.auth && x.link !== 'messenger' && x.auth)
      }
      if (this.isActiveWallet && this.getToken) {
        return data.filter((x) => x.auth)
      }
      return data.filter((x) => !x.auth)
    },
    isModalHandler() {
      this.$emit('openWallet')
    },
    openNotice() {
      this.$emit('openNotice')
    },
    closeServiceModal() {
      this.$emit('closeWallet')
      this.setOpenModal(false)
    },
    toPageReviews(item) {
      if (this.$route.name === item) {
        this.closeServiceModal()
        return
      }
      this.$router.push({ name: item})
      this.closeServiceModal()
    },
    toPage(item) {
      if (item.link === '') return
      if (this.$route.name === item.link) {
        this.closeServiceModal()
        return
      }
      if (item.anotherResource) {
        window.open(item.link, '_blank')
        this.closeServiceModal()
        return
      }
      if (item.toMarket) {
        window.location = item.link
        this.closeServiceModal()
        return
      }
      if (item.linkParams) {
        this.$router.push({ name: item.link,  params: item.linkParams && item.linkParams})
        this.setCategoryId(item.linkParams.id)
      } else {
        this.$router.push({ name: item.link})
      }
      this.closeServiceModal()
    },
    toLandingPage() {
      this.$router.push('/')
      this.closeServiceModal()
    },
    selectOption(lang) {
      this.$i18n.locale = lang
    },
  }
}
</script>
<style lang='scss'>
.serviceModal-list__data {
  position: relative;
  .beta {
    position: absolute;
    top: -5px;
    left: -3px;
    color: white;
    font-size: 10px !important;
  }
  .bellNotice {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2px 8px;
    font-size: var(--font-12);
    min-width: var(--indent-20);
    height: var(--indent-20);
    border-radius: 12px;
    font-weight: var(--font-600);
    color: var(--color-white);
    background: #EB7104;
  }
}
.mobileLanguage {
  @media (min-width: 1025px) {
    display: none;
  }
}
.languages-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--indent-8);
  margin-top: var(--indent-24);
  margin-bottom: var(--indent-16);

  li {
    padding: 4px 0;
    border-radius: var(--indent-8);
    border: 1px solid var(--color-white-20);
    font-weight: var(--font-500);
    font-size: var(--font-14);
    line-height: var(--line-20);
    letter-spacing: var(--spacing-14);
    text-align: center;

    &.active,
    &:hover,
    &:focus {
      border: 1px solid #EB7104;
    }
  }
  @media (min-width: 1025px) {
    display: none;
  }
}
.copyMyLinkService {
  cursor: pointer;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  border-radius: var(--indent-8);
  align-items: center;
  background: rgba(237, 138, 0, 0.20);
  padding: var(--indent-8) var(--indent-12);
  margin-bottom: 20px;
  .ico {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      path {
        fill: #EB7104;
        fill-opacity: 1;
      }
    }
  }
  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #EB7104;
    gap: 15px;
  }
}
.bottom-popup {
  min-width: 200px;
  position: absolute;
  z-index: 200;
  right: 30px;
  bottom: 80px;
  padding: var(--indent-16);
  background: var(--color-black-700);
  border-radius: var(--indent-8);
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 30%);
  transition: all 600ms;
  animation-name: isPopup;
  animation-duration: 500ms;
  @keyframes isPopup {
    from {
      right: -100px;
    }
    to {
      right: 30px;
    }
  }
}
.bottom-logout {
  display: flex;
  align-items: center;
  font-size: var(--font-14);
  line-height: var(--line-20);
  font-weight: var(--font-600);
  color: var(--color-red);
  transition: color 500ms;
  cursor: pointer;
  &:hover {
    color: var(--color-orange-900);
  }
}
.bottom-search {
  display: flex;
  align-items: center;
  font-size: var(--font-14);
  line-height: var(--line-20);
  margin-bottom: var(--indent-8);
  font-weight: var(--font-600);
  color: var(--color-black-500);
  transition: color 500ms;
  cursor: pointer;
  &:hover {
    color: var(--color-black-100);
  }
}
.bottom--mr {
  margin-right: var(--indent-12);
}
</style>
