import axios from 'axios'
const nftApi = axios.create({
  baseURL: `${process.env.VUE_APP_NFT_API_URL}`,
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
})

const nftsApi = {
  nfts(params) {
    return nftApi.get('nfts', { params: params })
  },
  nft(contractAddress, tokenId) {
    return nftApi.get(`nfts/${contractAddress}/${tokenId}`)
  },
  refreshMetadata(contractAddress, tokenId) {
    return nftApi.put(`nfts/${contractAddress}/${tokenId}`)
  },
  // return rating w burn energy amount
  getNftRating(userAccount, contractAddress, tokenId) {
    return nftApi.get(
      `nfts/rating?userAccount=${userAccount}&contractAddress=${contractAddress}&tokenId=${tokenId}`
    )
  },
  getNftRatings(userAccount, contractAddress, tokenId) {
    return nftApi.get(
      `nfts/ratings?account=${userAccount}&contractAddress=${contractAddress}&tokenId=${tokenId}`
    )
  },
  getNftV3Ratings(contractAddress, tokenId) {
    return nftApi.get(
      `nfts/v3/ratings?&contractAddress=${contractAddress}&tokenId=${tokenId}`
    )
  },
  getNftMigrationSignature(userAccount, contractAddress, tokenId) {
    return nftApi.get(
      `nfts/migrationSignature?account=${userAccount}&contractAddress=${contractAddress}&tokenId=${tokenId}`
    )
  },
  // the backend returns a message with parameters as nonce + energy with signature
  getNftBurnSignature(userAccount, contractAddress, tokenId) {
    return nftApi.get(
      `nfts/burnSignature?account=${userAccount}&contractAddress=${contractAddress}&tokenId=${tokenId}`
    )
  },
  getNftRatingUpgradeSignature(userAccount, contractAddress, tokenId) {
    return nftApi.get(
      `nfts/ratingUpgradeSignature?account=${userAccount}&contractAddress=${contractAddress}&tokenId=${tokenId}`
    )
  },
  getNftRatingUpgradeAndMigrateSignature(
    userAccount,
    contractAddress,
    tokenId
  ) {
    return nftApi.get(
      `nfts/ratingUpgradeAndMigrateSignature?account=${userAccount}&contractAddress=${contractAddress}&tokenId=${tokenId}`
    )
  },
  getNftPriceThatSystemReadyToPaySignature(
    userAccount,
    contractAddress,
    tokenId
  ) {
    return nftApi.get(
      `nfts/nftPriceThatSystemReadyToPaySignature?account=${userAccount}&contractAddress=${contractAddress}&tokenId=${tokenId}`
    )
  },
  getNftPriceThatSystemReadyToPay(userAccount, contractAddress, tokenId) {
    return nftApi.get(
      `nfts/nftPriceThatSystemReadyToPay?account=${userAccount}&contractAddress=${contractAddress}&tokenId=${tokenId}`
    )
  },
  // return rating w burn energy amount
  getUserInfo(address) {
    return nftApi.get(`nfts/userInfo?address=${address}`)
  },

  // royalty
  getRoyaltyDistributions: (level, tokenId) => {
    return nftApi.get(`RoyaltyDistributions?level=${level}&tokenId=${tokenId}`)
  },
  getRoyaltyDistributionRewardSignature: (account, distributionId, level, tokenId) => {
    return nftApi.post(`RoyaltyDistributions/claimRewards`, {
      account,
      distributionId: +distributionId,
      level: +level,
      tokenId: +tokenId
    })
  },

  // royalty to chart
  getUserDaysRewardStatistic(account) {
    return nftApi.get(`RoyaltyDistributions/userDaysRewardStatistic?account=${account}`)
  },
  getRoyaltyNFT: (account) => {
    return nftApi.get(`RoyaltyDistributions?account=${account}`)
  },

}

export default nftsApi
