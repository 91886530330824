export const DICT = {
  SERVICE_MODE: process.env.VUE_APP_SERVICE_MODE || false,
  PROVIDER: process.env.VUE_APP_CRYPTO_PROVIDER,
  CHAIN_ID: Number(process.env.VUE_APP_CRYPTO_CHAIN),
  IS_TESTNET: false,
  CHAIN_NAME: ['Polygon Mainnet', 'Polygon Mumbai Testnet'],
  NATIVE_CURRENCY_SYMBOL: 'MATIC',
  EXPLORER_URLS: [
    ['https://polygonscan.com/'],
    ['https://mumbai.polygonscan.com/'],
  ],
  VUE_APP_CONNECT_WALLET_PROJECT_ID: process.env.VUE_APP_CONNECT_WALLET_PROJECT_ID,

  CREATOR: process.env.VUE_APP_CRYPTO_CREATOR,
  CONTRACT_MAIN: process.env.VUE_APP_CRYPTO_CONTRACT_MAIN,
  CONTRACT_SFC: process.env.VUE_APP_CRYPTO_CONTRACT_SFC,
  CONTRACT_SFC_NEW: process.env.VUE_APP_CRYPTO_CONTRACT_SFC_NEW,
  CONTRACT_SFC2: process.env.VUE_APP_CRYPTO_CONTRACT_SFC2,
  CONTRACT_MFS: process.env.VUE_APP_CRYPTO_CONTRACT_MFS,

  CONTRACT_NFT_CONTROL_V1: process.env.VUE_APP_CRYPTO_CONTRACT_NFT_CONTROL_V1,
  CONTRACT_NFT_CONTROL_V2: process.env.VUE_APP_CRYPTO_CONTRACT_NFT_CONTROL_V2,
  CONTRACT_NFT_CONTROL_V3: process.env.VUE_APP_CRYPTO_CONTRACT_NFT_CONTROL_V3,
  CONTRACT_NFT_REGISTRY_V1: process.env.VUE_APP_CRYPTO_CONTRACT_NFT_REGISTRY_V1,
  CONTRACT_NFT_REGISTRY_V2: process.env.VUE_APP_CRYPTO_CONTRACT_NFT_REGISTRY_V2,
  CONTRACT_NFT_REGISTRY_V3: process.env.VUE_APP_CRYPTO_CONTRACT_NFT_REGISTRY_V3,
  CONTRACT_NFT_MIGRATION: process.env.VUE_APP_CRYPTO_CONTRACT_NFT_MIGRATION,

  CONTRACT_METACORE: process.env.VUE_APP_CRYPTO_CONTRACT_METACORE,
  CONTRACT_METAPAYMENT: process.env.VUE_APP_CRYPTO_CONTRACT_METAPAYMENT,
  CONTRACT_FORCECOIN: process.env.VUE_APP_CRYPTO_CONTRACT_FORCECOIN,
  CONTRACT_ENERGYCOIN: process.env.VUE_APP_CRYPTO_CONTRACT_ENERGYCOIN,

  CONTRACT_UV_REGISTRY: process.env.VUE_APP_CRYPTO_CONTRACT_UV_REGISTRY,

  CONTRACT_DISTRIBUTION_BANNED_NFT: process.env.VUE_APP_CRYPTO_DISTRIBUTION_BANNED_NFT,
  CONTRACT_ROYALTY_DISTRIBUTION: process.env.VUE_APP_CRYPTO_ROYALTY_DISTRIBUTION,

  DEFAULT_GAS_LIMIT: 500000,
  DEFAULT_GAS_PRICE: '40000000000',
  DEFAULT_GAS_MAX: '42000000000',
  ESTIMATED_GAS_INCREASE: 1.1, // gas limit multiply
  ESTIMATED_GAS_PRIORIY: 1.4, // ~ aggressive
  ESTIMATE_GAS_MAX_PER_BASE: 2, // defaults to 2
}

export const LANGS = ['en', 'cn', 'hi', 'ru', 'vi', 'ar', 'id', 'ur', 'fr', 'es']

export const LSTORAGE = {
  lang: 'lang',
  visited: 'force_visit',
  referral: 'force_upline',
  connected: 'force_wallet_connected',
  wallet: 'force_wallet_type',
  walletconnect: 'walletconnect',
  lostTx: 'force_lost_tx',
  token: 'force_remember_token',
}

export const DEFAULT_AVATAR = 'https://metaforce-hub.fra1.cdn.digitaloceanspaces.com/images/avatar.jpg'
