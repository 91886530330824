import { initAxios } from "./Axios"
import moment from "./Moment"
// import svgvue from "./SvgVue"
import sweetalert from "./SweetAlert"
import clickOutside from "./ClickOutside"
import toastification from "./Toast"
import tooltips from "./Tooltips"
import calendar from "./VCalendar"
import mask from "./Mask"
import select from "./Select"
import viewer from "./Viewer"
import infinityScroll from "./InfinityScroll"


const initPlugins = (app) => {
    initAxios(app)
    moment(app)
    // svgvue(app)
    sweetalert(app)
    clickOutside(app)
    toastification(app)
    tooltips(app)
    calendar(app)
    mask(app)
    select(app)
    viewer(app)
    infinityScroll(app)
}

export default initPlugins
