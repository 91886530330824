<template>
<div>
  <div class="footer" v-if="$route.path === '/metaverse'">
    <section id="join-us">
      <div class="container-metaverse">
        <h2>{{ $t("home.footer.title") }}</h2>
        <div class="description">
          {{ $t("home.footer.desc") }}
        </div>
        <a href="#" class="btn-action grey">{{ $t("home.footer.try") }}</a>
      </div>
    </section>
    <footer class='footerNew'>
      <div class="container-metaverse">
        <div class="logo">
          <img src="../../../assets/images/home/logo-footer.svg" alt="MetaForce">
        </div>
        <div class="footer-links">
          <router-link :to="{ name: 'HomeSupport' }">
            Support
          </router-link>
          <router-link :to="{ name: 'HomeTermsofservice' }">
            Terms of service
          </router-link>
          <router-link :to="{ name: 'HomeAbout' }">
            About company
          </router-link>
        </div>
        <div class="copyright">
          © {{ currentYear }} All Rights Reserved
        </div>
      </div>
    </footer>
  </div>
  <div class="footer" v-else>
    <footer class='footerNew'>
      <div class="container">
        <div class="footer-top">
          <a class="logo" href='#hero'>
            <BaseIcon name="footerLogo"/>
          </a>
          <div class="social">
            <a
              v-for="(item, idx) in linksList"
              :key="idx"
              :href="item.link"
              target="_blank"
            >
              <BaseIcon :name="item.name" />
            </a>
          </div>
        </div>
        <div class="footer-menu">
          <div class="list">
            <div class="title accordion" :class='{active: isService}' @click='isService = !isService'>
              All Services
              <BaseIcon name="plus_24" @event='isService = !isService'/>
            </div>
            <ul :style="isService ? { 'max-height': '260px', 'padding-bottom':'16px' }: {}">
              <li v-for='service in allServices' :key='service.name'>
                <a v-if='service.link' :href='service.link' target='_blank'>
                  {{ service.name }}
                </a>
                <router-link v-if='service.localLink' :to='{name: service.localLink}'>{{service.name}}</router-link>
              </li>
<!--              <li>
                Boost
              </li>
              <li>
                Over Real
              </li>-->
            </ul>
          </div>
          <div class="list">
            <div class="title accordion" :class='{active: isAccount}' @click='isAccount = !isAccount'>
              Account
              <BaseIcon name="plus_24" @event='isAccount = !isAccount'/>
            </div>
            <ul :style="isAccount ? { 'max-height': '190px', 'padding-bottom':'16px' }: {}">
              <li @click='goToPage("dashboard")'>
                  Dashboard
              </li>
              <li @click='goToPage("profile")'>
                Profile
              </li>
              <li @click='goToPage("classic")'>
                Force Systems
              </li>
              <li @click='goToPage("systems_referral")'>
                My teams
              </li>
              <li @click='goToPage("help")'>
                Support
              </li>
            </ul>
          </div>
          <div class="list">
            <div class="title accordion" :class='{active: isCommunity}' @click='isCommunity = !isCommunity'>
              Community
              <BaseIcon name="plus_24" @event='isCommunity = !isCommunity'/>
            </div>
            <ul :style="isCommunity ? { 'max-height': '160px', 'padding-bottom':'16px' }: {}">
              <li>
                <router-link :to="{ name: 'academy' }">
                  Events
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'academy' }">
                  News
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Reviews' }">
                  Reviews
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'HomePromotion' }">
                  Promotion
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'HomeSocial' }">
                  Social Media
                </router-link>
              </li>
            </ul>
          </div>
          <div class="list">
            <div class="title accordion" :class='{active: isResources}' @click='isResources = !isResources'>
              Resources
              <BaseIcon name="plus_24" @event='isResources = !isResources'/>
            </div>
            <ul :style="isResources ? { 'max-height': '210px', 'padding-bottom':'16px' }: {}">
<!--              <li>-->
<!--                <a class="liLang" href='./assets/files/pdf/white_paper_en.pdf' target='_blank'>Whitepaper</a>-->
<!--              </li>-->
              <li>
                <router-link :to="{ name: 'HomeWeb3infosmartcontracts' }">
                  Smart Contracts
                </router-link>
              </li>
              <li>
                <a class="liLang" href='./assets/files/pdf/Royalty_NFT_EN.pdf' target='_blank'>Royalty</a>
              </li>
              <li>
                <a class="liLang" href='https://drive.google.com/file/d/1HDNld4EushiWr7tWuQobC07QqYuWNFXW/view?usp=sharing' target='_blank'>Tactile</a>
              </li>
              <li>
                <a class="liLang" href='./assets/files/pdf/Uniteverse_en.pdf' target='_blank'>Uniteverse</a>
              </li>
              <li>
                <a class="liLang" href='./assets/files/pdf/WhitePaper_FORCECOIN.pdf' target='_blank'>Forcecoin</a>
              </li>
            </ul>
          </div>
          <div class="list">
            <div class="title accordion" :class='{active: isGeneral}' @click='isGeneral = !isGeneral'>
              General
              <BaseIcon name="plus_24" @event='isGeneral = !isGeneral'/>
            </div>
            <ul :style="isGeneral ? { 'max-height': '140px', 'padding-bottom':'16px' }: {}">
              <li>
                <router-link :to="{ name: 'HomeAbout' }">
                  About
                </router-link>
              </li>
<!--              <li>
                Promotions
              </li>
              <li>
                Force coin
              </li>-->
              <li>
                <router-link :to="{ name: 'academy' }">
                  News
                </router-link>
              </li>
            </ul>
          </div>
          <div class="list">
            <div class="title accordion" :class='{active: isPartners}' @click='isPartners = !isPartners'>
              Partners
              <BaseIcon name="plus_24" @event='isPartners = !isPartners'/>
            </div>
            <ul :style="isPartners ? { 'max-height': '140px', 'padding-bottom':'16px' }: {}">
              <li>
                <a href="https://chain.link/">
                  <BaseIcon name="chainLink"/>
                  Chainlink
                </a>
              </li>
              <li>
                <a href="https://amlbot.com/">
                  <BaseIcon name="amlBot"/>
                  AML Bot
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="logo">
            <BaseIcon name="polygonLogo" @event='toPolygonScan'/>
            <router-link :to="{ name: 'HomeWeb3infosmartcontracts' }">
              web3 info smart contracts
            </router-link>
          </div>
          <div class="links">
<!--            <router-link :to="{ name: 'HomeAbout' }">-->
<!--              Privacy Policy-->
<!--            </router-link>-->
            <router-link :to="{ name: 'HomeTermsofservice' }">
              Terms of Service
            </router-link>
            <a href="#faq">Support</a>
          </div>
          <div class="copyright">
            © {{ currentYear }} All Rights Reserved
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>
</template>

<script>
import BaseIcon from "@/components/UI/BaseIcon.vue";
import { mapGetters, mapMutations } from 'vuex'
import { getLocalStorageElement } from '@/helpers/localstorage'
import { LSTORAGE } from '@/config/constants'
export default {
  name: "Footer",
  components: {
    BaseIcon
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      linksList: [
        {
          name: 'socialYoutube',
          link: 'https://www.youtube.com/@MetaFORCESpace',
        },
        {
          name: 'socialDiscord',
          link: 'https://discord.gg/yZYzwpJeWg',
        },
        {
          name: 'socialTwitter',
          link: 'https://twitter.com/MetaForceSpace',
        },
        {
          name: 'socialTelegram',
          link: 'https://t.me/MetaForceSpace',
        },
        {
          name: 'socialInstagram',
          link: 'https://www.instagram.com/metaforcespace/',
        },
      ],
      allServices: [
        {name: 'NFT Marketplace', link: 'https://market.meta-force.space/'},
        {name: 'Academy', link: 'https://academy.meta-force.space/'},
        {name: 'Metaverse', localLink: 'ServiceMetaverse'},
        {name: 'Tactile', localLink: 'ServiceTactile'},
        {name: 'Mobile wallet', localLink: 'ServiceMobileWallet'},
        {name: 'Force Systems', localLink: 'ServiceForceSystem'},
        // {name: 'Chain'},
      ],
      isService: false,
      isAccount: false,
      isCommunity: false,
      isResources: false,
      isGeneral: false,
      isPartners: false
    }
  },
  computed: {
    ...mapGetters('crypto', [
      'isConnected'
    ]),
  },
  methods: {
    ...mapMutations('ui', ['setConnectionModal']),
    goToPage (name) {
      if (this.isConnected || getLocalStorageElement(LSTORAGE.connected)) {
        this.$router.push({ name: name })
      } else {
        this.setConnectionModal({ visible: true, type: 'connect' })
      }
    },
    toPolygonScan() {
      window.open('https://polygonscan.com/', '_blank')
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/mixins';
.footerNew {
  background: #0D0D0D;

  .container {
    width: 100%;
    max-width: 1200px;
    padding: 0 var(--indent-16);

    .footer-top {
      width: 100%;
      align-items: center;
      justify-content: space-between;
      display: flex;
      padding: 40px 0;
      border-bottom: 0.4px solid var(--color-black-700);

      .logo {
        .ico {
          @include flex-center-center;
        }
      }

      .social {
        @include flex-center;
        gap: var(--indent-16);

        .ico {
          @include flex-center-center;
          width: 40px;
          height: 40px;
          padding: var(--indent-10);
          border-radius: 27px;
          background: var(--color-white-5);

          svg {
            width: 20px;
            height: 20px;

            path {
              fill-opacity: 1;
            }
          }
        }

        @media (max-width: 993px) {
          justify-content: space-between;
          width: 100%;
        }
      }

      @media (max-width: 993px) {
        @include flex-column;
        align-items: flex-start;
        gap: var(--indent-24);
      }
    }

    .footer-menu {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(6, 1fr);
      gap: var(--indent-24);
      padding: 40px 0;
      border-bottom: 0.4px solid var(--color-black-700);

      .list {
        .title {
          margin-bottom: var(--indent-12);
          font-weight: var(--font-500);
          font-size: var(--font-14);
          line-height: var(--line-24);

          .ico {
            display: none;
          }

          @media (max-width: 993px) {
            @include flex-center-between;
            position: relative;
            margin: 0;
            padding: var(--indent-16) 0;
            font-size: var(--font-16);

            .ico {
              @include flex-center-center;
              width: 18px;
              height: 18px;
              transition: .3s ease;
            }

            &.active {
              span {
                transform: rotateZ(45deg);
              }
            }
          }
        }

        ul {
          @include flex-column;
          gap: var(--indent-12);

          li {
            color: var(--color-white-40);
            font-weight: var(--font-500);
            font-size: var(--font-14);
            line-height: var(--line-24);
            cursor: pointer;
            transition: .3s ease;

            a {
              @include flex-center;
              gap: var(--indent-8);
            }

            &:hover {
              color: var(--color-white);
            }
          }

          @media (max-width: 993px) {
            max-height: 0;
            overflow: hidden;
            transition: 0.3s ease;
          }
        }

        @media (max-width: 993px) {
          width: 100%;
          border-bottom: 0.4px solid var(--color-black-700);
        }
      }

      @media (max-width: 993px) {
        @include flex-column;
        align-items: flex-start;
        gap: 0;
        border-bottom: none;
      }
    }

    .footer-bottom {
      @include flex-center-between;
      padding: 40px 0;
      width: 100%;

      .logo {
        @include flex-center;
        gap: var(--indent-24);

        .ico {
          @include flex-center;
        }

        a {
          color: var(--color-white-40);
          font-weight: var(--font-400);
          font-size: var(--font-14);
          line-height: var(--line-24);
          transition: .3s ease;

          &:hover {
            color: var(--color-white);
          }
        }

        @media (max-width: 993px) {
          @include flex-column;
          align-items: flex-start;
          gap: var(--indent-16);
        }
      }

      .links {
        @include flex-center;
        gap: var(--indent-24);

        a {
          color: var(--color-white-40);
          font-weight: var(--font-400);
          font-size: var(--font-14);
          line-height: var(--line-24);
          transition: .3s ease;

          &:hover {
            color: var(--color-white);
          }
        }

        a + a {
          @include flex-center;

          &:before {
            width: 1px;
            height: var(--indent-16);
            margin-right: var(--indent-24);
            background: var(--color-black-700);
            content: '';
          }

          @media (max-width: 993px) {
            &:before {
              display: none;
            }
          }
        }

        @media (max-width: 993px) {
          @include flex-column;
          align-items: flex-start;
          gap: var(--indent-16);
        }
      }

      .copyright {
        color: var(--color-white-40);
        font-weight: var(--font-400);
        font-size: var(--font-14);
        line-height: var(--line-24);
      }

      @media (max-width: 993px) {
        @include flex-column;
        align-items: flex-start;
        gap: var(--indent-16);
      }
    }

    @media (max-width: 1281px) {
      width: calc(100% - 32px);
      padding: 0;
    }
  }
}
</style>
