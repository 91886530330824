<template>
    <div class="modalWalletBack" :class="[visible && 'showModalBack']" @click="closeModal" key='modalWallet'>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
    },
    methods: {
        closeModal() {
            this.$emit("closeModal")
        }
    }
}
</script>

<style lang="scss" scoped>
.modalWalletBack {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.2);
    backdrop-filter: blur(5px);
    z-index: 200;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;
    overflow: auto;
    &.showModalBack {
        opacity: 1;
        pointer-events: all;
    }
}
</style>
