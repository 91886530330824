<template>
  <UiModalService :visible="isModal" @closeModal="closeServiceModal">
    <div class="modal" id="walletModal" @click.stop :class='!isModal && "closeModalService"'>
      <button class="btn-close">
        <BaseIcon name='closeServiceIcon' @event='closeServiceModal' ver-align />
      </button>
      <div class="modal-header">
        <button class="disconnect-btn" @click.stop='exit'>
          {{ $t('dashboardInfo.modalWallet.disconnect') }}
        </button>
        <div class="modal-header__title">
          {{ $t('register.wallet') }}
          <span>{{ clientWalletShort }}</span>
        </div>
      </div>
      <div class="modal-content">
        <div class="wallet__total">
          <span>
              {{ $t('dashboardInfo.modalWallet.totalBalance') }}
          </span>
          <div class='wallet__total-balanceIcon'>
            $ {{ totalBalance() }}
            <BaseIcon name='addCashToBalance' ver-align/>
            <span class="tooltip">{{ $t('nfts.comingSoon') }}</span>
          </div>
          <span>
            MFS / DAI / MATIC
          </span>
        </div>
        <div class="wallet__btn-group">
          <button class="wallet-send disabledBtn">
            <BaseIcon name='sendToken' ver-align/>
            {{ $t('help.chat.send') }}
            <span class="tooltip">{{ $t('nfts.comingSoon') }}</span>
          </button>
          <button class="wallet-receive disabledBtn">
            <BaseIcon name='receiveToken' ver-align/>
            {{ $t('dashboardInfo.modalWallet.deposit') }}
            <span class="tooltip">{{ $t('nfts.comingSoon') }}</span>
          </button>
          <button class="wallet-swap disabledBtn">
            <BaseIcon name='swapToken' ver-align />
            {{ $t('dashboardInfo.modalWallet.swap') }}
            <span class="tooltip">{{ $t('nfts.comingSoon') }}</span>
          </button>
          <button class="wallet-swap" @click.stop='openTransferHandler'>
            <BaseIcon name='transferToken' ver-align @event='openTransferHandler'/>
            {{ $t('dashboardInfo.modalWallet.transfer.title') }}
          </button>
        </div>
        <div class="wallet__balances" style='overflow: auto'>
          <div class="balances__item">
            <div class="balances__item-title innerBC">
              {{ $t('dashboardInfo.modalWallet.innerBalance') }}
              <a href="https://ib.meta-force.space/" target="_blank" class="title__link">
                ib.meta-force.space
              </a>
            </div>
            <div class="balances__item-list">
              <div class="balances__item-token" @click.stop='toInnerModal(shortAmount(daiInnerBalance.amount), "inner")'>
                <div class="img">
                  <BaseIcon :name='daiInnerBalance.iconName' :event='true'  ver-align />
                </div>
                <div class="caption">
                  <div class="name">
                    <p>DAI <span>/ {{ daiInnerBalance.label }}</span></p>
                      <div class="value">
                        $1
                      </div>
                  </div>
                  <div class="qty">
                    {{ Number(daiInnerBalance.amount).toFixed(1) }}
                    <span>${{ Number(daiInnerBalance.amount).toFixed(1) }}</span>
                  </div>
                </div>
              </div>
              <div class="balances__item-token">
                <div class="img">
                  <BaseIcon :name='forceCoinInnerBalance.iconName'  ver-align />
                </div>
                <div class="caption">
                  <div class="name">
                    <p>MFS <span>/ {{ forceCoinInnerBalance.label }}</span></p>
                    <div class="value">
                      ${{shortAmount(getForceCoinCurrency)}}
<!--                      <span>+2%</span>-->
                    </div>
                  </div>
                  <div class="qty">
                    {{ shortAmount(forceCoinInnerBalance.amount) }}
                    <span>${{ shortAmount(actualInnerDai)}}</span>
                  </div>
                </div>
              </div>
              <div class="balances__item-token">
                <div class="img">
                  <BaseIcon :name='energyCoinInnerBalance.iconName'  ver-align />
                </div>
                <div class="caption">
                  <div class="name">
                    <p>RES <span>/ {{ energyCoinInnerBalance.label }}</span></p>
                  </div>
                  <div class="qty">
                    {{ shortAmount(energyCoinInnerBalance.amount) }}
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="wallet__balances-disconnect">
            <div
              class="form-group"
              @click.prevent.stop="changeDirectPaymentStatus"
              v-if="isDirectPaymentStatusLoaded && !extraWalletShort && !getInnerBlocked"
            >
              <input type="checkbox" id="receive" name="receive" hidden :checked="directPaymentStatus">
              <label for="receive">{{ $t('dashboardInfo.modalWallet.receiveReward') }}</label>
            </div>
            <div class="form-group" v-else-if='extraWalletShort'>
              <div class='extraWallet'>
                <span>EXTRA WALLET:</span>
                <p>
                  {{ extraWalletShort }}
                  <BaseIcon class="copy" ver-align name="closedIconCopy" @event="handleExtraClipBoard" />
                </p>
              </div>
            </div>
          </div>
          <div class="balances__item">
            <div class="balances__item-title outerBC">
              {{ $t('dashboardInfo.modalWallet.walletBalance') }}
              <span @click.stop='handleClipboardCopy'>
                {{ clientWalletShort }}
                <BaseIcon name='closedIconCopy' ver-align @event='handleClipboardCopy' />
              </span>
            </div>
            <div class="balances__item-list">
              <div class="balances__item-token" @click.stop='toInnerModal(shortAmount(dai.amount), "wallet")'>
                <div class="img">
                  <BaseIcon :name='dai.iconName' :event='true'  ver-align />
                </div>
                <div class="caption">
                  <div class="name">
                    <p>DAI <span>/ Dai</span></p>
                    <div class="value">
                      $1
                    </div>
                  </div>
                  <div class="qty">
                    {{ Number(dai.amount).toFixed(1) }}
                    <span>${{ Number(dai.amount).toFixed(1) }}</span>
                  </div>
                </div>
              </div>
              <div class="balances__item-token">
                <div class="img">
                  <BaseIcon :name='matic.iconName'  ver-align />
                </div>
                <div class="caption">
                  <div class="name">
                    <p>GAS <span>/ Matic</span></p>
                    <div class="value">
                      ${{ shortAmount(this.maticUsd) }}
                    </div>
                  </div>
                  <div class="qty">
                    {{ shortAmount(matic.amount) }}
                    <span>${{ shortAmount(totalCurrencyMatic) }}</span>
                  </div>
                </div>
              </div>
              <div class="balances__item-token">
                <div class="img">
                  <BaseIcon :name='energy.iconName'  ver-align />
                </div>
                <div class="caption">
                  <div class="name">
                    <p>RES <span>/ Energy</span></p>
                  </div>
                  <div class="qty">
                    {{ shortAmount(energy.amount) }}
                  </div>
                </div>
              </div>
              <div class="balances__item-token" v-for='(token, idx) in statTokensFiltered' :key='idx'>
                <div class="img">
                  <BaseIcon :name='token.iconName'  ver-align />
                </div>
                <div class="caption">
                  <div class="name">
                    <p>SFC <span>/ {{ token.label }}</span></p>
                  </div>
                  <div class="qty">
                    {{ shortAmount(token.amount) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <InnerBalanceModal
      v-if='innerModal'
      :isModal='innerModal'
      :innerDaiProps='innerDaiProps'
      :daiPropsName='daiPropsName'
      @innerEmit='innerModal = false'
      @innerEmitTransaction='openTransferHandler'
      @closeInner='closeServiceModal'
    />
    <TransferModal
      :daiPropsName='daiPropsName'
      :isModal='transferModal'
      :daiInnerOuter='{daiInnerBalance: daiInnerBalance.amount, dai: dai.amount}'
      @transferEmit='transferModal = !transferModal'
      @closeTransfer='closeServiceModal'
    />
<!--    coming soon-->
<!--    <DepositModal :isModal='depositModal' @depositEmit='depositModal = !depositModal'/>-->
<!--    <SendModal :isModal='sendModal' @sendEmit='sendModal = !sendModal'/>-->
  </UiModalService>
</template>

<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { sliceString } from '@/helpers/sliceString'
import { shortSumToken } from '@/helpers/shortSumToken'
import { copyToClipboard } from '@/helpers/clipboard'
import { SentryLog } from '@/helpers/dev'
import InnerBalanceModal from '@/components/Layout/partials/InnerBalanceModal.vue'
import TransferModal from '@/components/Layout/partials/TransferModal.vue'
// import DepositModal from '@/components/Layout/partials/DepositModal.vue'
// import SendModal from '@/components/Layout/partials/SendModal.vue'

export default {
  name: "WalletModal",
  props: {
    isModal: {
      type: Boolean,
    },
  },
  components:{
    TransferModal,
    // SendModal,
    // DepositModal,
    InnerBalanceModal,
    BaseIcon,
  },
  data() {
    return {
      metaPaymentReservedAddress: null,
      shortStrMobile: false,
      isDirectPaymentStatusLoaded: false,
      directPaymentStatus: false,
      isRewardsChecked: false,
      innerModal: false,
      transferModal: false,
      innerDaiProps: 0,
      daiPropsName: 'inner',
      maticUsd: 0,
      // depositModal: false,
      // sendModal: false
    }
  },
  created() {
    if (window.innerWidth < 400) {
      this.shortStrMobile = true
    }
  },

  async mounted() {
    await this.pageReady()
    setTimeout(() => {
      if (this.getMaticCoinCurrency) {
        this.maticUsd = Number(this.getMaticCoinCurrency)
      }
    }, 3000)
  },
  watch: {
    daiInnerBalance(oldVal, newVal) {
      if (oldVal.amount !== newVal.amount) {
        if (this.daiPropsName === 'inner') {
          this.innerDaiProps = this.daiInnerBalance.amount
        }
      }
    },
    dai(oldVal, newVal) {
      if (oldVal.amount !== newVal.amount) {
        if (this.daiPropsName === 'wallet') {
          this.innerDaiProps = this.dai.amount
        }
      }
    },
  },

  computed: {
    ...mapGetters('crypto', ['getAccount', 'getBalance', 'getInnerCoins']),
    ...mapGetters('user', ['getForceCoinCurrency', 'getMaticCoinCurrency', 'getInnerBlocked']),

    extraWalletShort() {
      if (!this.metaPaymentReservedAddress) return
      if (this.shortStrMobile) {
        return sliceString(this.metaPaymentReservedAddress)
      }
      return sliceString(this.metaPaymentReservedAddress, 12)
    },

    forceCoinInnerBalance() {
      return {
        iconName: 'mfs',
        label: 'Forcecoin',
        amount: this.getInnerCoins[1],
      }
    },
    energyCoinInnerBalance() {
      return {
        iconName: 'res',
        label: 'Energy',
        amount: this.getInnerCoins[2],
      }
    },
    daiInnerBalance() {
      return {
        iconName: 'dai',
        label: 'Dai',
        amount: this.getInnerCoins[0],
      }
    },
    clientWalletShort() {
      if (!this.getAccount) return
      if (this.shortStrMobile) {
        return sliceString(this.getAccount)
      }
      return sliceString(this.getAccount, 12)
    },
    statTokens() {
      const res = []
      let coinValues
      coinValues = Object.values(this.getBalance).map((el) => +el)
      const coinNames = ['gas', 'sfcOld', 'sfcNew', 'sfc2', 'dai', 'res']

      coinValues.forEach((el, idx) => {
        res.push({
          iconName: `${coinNames[idx].replace(/[' ']/gi, '')}`,
          label: coinNames[idx],
          amount: el,
        })
      })

      return res
    },
    actualInnerDai() {
      return this.forceCoinInnerBalance.amount * Number(this.getForceCoinCurrency)
    },
    matic() {
      return this.statTokens.find((el) => el.iconName.includes('gas'))
    },
    dai() {
      return this.statTokens.find((el) => el.iconName.includes('dai'))
    },
    energy() {
      return this.statTokens.find((el) => el.iconName.includes('res'))
    },
    statTokensFiltered() {
      return this.statTokens.filter(
        (el) =>
          !el.iconName.toLowerCase().includes('gas') &&
          !el.iconName.toLowerCase().includes('dai') &&
          !el.iconName.toLowerCase().includes('res')
          && el.amount > 0
      )
    },
    totalCurrencyMatic() {
      return this.matic.amount * this.maticUsd
    },
  },
  methods: {
    ...mapActions('crypto', [
      'logOut',
      'getMetaPaymentReservedAddress',
      'getMetaPaymentDirectPaymentStatus',
      'setMetaPaymentDirectPaymentStatus',
      'getMetaPaymentBalancesFormatted',
      'getMFSRate'
    ]),
    ...mapMutations('user', ['clearUser', 'setForceCoinCurrency']),
    async pageReady() {
      try {
        if (this.getInnerCoins.length === 0) {
          setTimeout(async () => {
            await this.getMetaPaymentBalancesFormatted(this.getAccount)
          }, 4000)
        }
      } catch (e) {
        console.log('getMetaPaymentBalancesFormatted error:', e)
      }
      await this.loadDirectPaymentStatus()

      try {
        if (this.getInnerCoins.length === 0) {
          setTimeout(async () => {
            this.metaPaymentReservedAddress =
              await this.getMetaPaymentReservedAddress(this.getAccount)
          }, 4200)
        }
      } catch (e) {
        console.log('getMetaPaymentReservedAddress error:', e)
      }

      try {
        if (this.getForceCoinCurrency === 0) {
          setTimeout(async () => {
            const curr = await this.getMFSRate();
            this.setForceCoinCurrency(curr)
          }, 5200)
        }
      } catch (e) {
        console.log('getForceCoinCurrency error:', e)
      }
    },
    openTransferHandler() {
      if (this.extraWalletShort) {
        window.location = 'https://ib.meta-force.space/'
      } else {
        this.transferModal = !this.transferModal
      }
    },

    handleExtraClipBoard() {
      if (this.metaPaymentReservedAddress) {
        copyToClipboard(this.metaPaymentReservedAddress)
          .then(() => {
            this.$toast.success(
              `${this.metaPaymentReservedAddress} ${this.$t('account.copied')}`
            )
          })
          .catch(() => {
            this.$toast.error(`${this.$t('account.copyError')}`)
          })
      }
    },

    toInnerModal(amount, name) {
      this.innerDaiProps = Number(amount)
      this.daiPropsName = name
      this.innerModal = true
    },
    totalBalance() {
      const sum =  this.dai.amount +
        this.daiInnerBalance.amount +
        (this.forceCoinInnerBalance.amount * Number(this.getForceCoinCurrency)) +
        (this.matic.amount * this.maticUsd)
      return this.shortAmount(sum)
    },
    closeServiceModal() {
      this.innerModal = false
      this.transferModal = false
      this.$emit('closeWallet')
    },
    shortAmount(amount) {
      if (amount) {
        return shortSumToken(amount)
      }
      return `0.0`
    },
    exit() {
      this.logOut()
      this.clearUser()
      this.$emit('closeWallet')
    },
    handleClipboardCopy() {
      if (!this.getAccount) return
      copyToClipboard(this.getAccount)
        .then(() => {
          this.$toast.success(`${this.getAccount} ${this.$t('account.copied')}`)
        })
        .catch(() => {
          this.$toast.error(`${this.$t('account.copyError')}`)
        })
    },
    async loadDirectPaymentStatus() {
      try {
        setTimeout(async () => {
          this.directPaymentStatus = await this.getMetaPaymentDirectPaymentStatus(
            this.getAccount
          )
          this.isDirectPaymentStatusLoaded = true
        },1400)
      } catch (e) {
        console.log('getMetaPaymentDirectPaymentStatus error:', e)
      }
    },
    async changeDirectPaymentStatus() {
      this.isRewardsChecked = !this.directPaymentStatus
      try {
        this.$store.commit('loader/showProcessingModal', {
          isShown: true,
          text: `${this.$t(
            'matrix.activationModal.blockchainPendingFirstLine'
          )} <br />
                                ${this.$t(
            'matrix.activationModal.blockchainPendingSecondLine'
          )}`,
        })

        await this.setMetaPaymentDirectPaymentStatus({
          status: !this.directPaymentStatus,
          onBlockchainPending: () => {
            this.$store.commit('loader/showProcessingModal', {
              isShown: true,
              text: `${this.$t(
                'matrix.activationModal.blockchainPendingFirstLine'
              )} <br />
                                ${this.$t(
                'matrix.activationModal.blockchainPendingSecondLine'
              )}`,
            })
          },
        })

        this.directPaymentStatus = !this.directPaymentStatus
        // await this.loadDirectPaymentStatus();
      } catch (e) {
        SentryLog(e, 'connect')
        this.$swal(e.message)
      } finally {
        this.$store.commit('loader/showProcessingModal', {
          isShown: false,
        })
        this.isRewardsChecked = this.directPaymentStatus
      }
    },
  }
}
</script>

<style scoped>
.modal {

  margin-bottom: 200px;
}
</style>