import { getTestimonialsService, getTestimonialsVideoService, setTestimonialsService } from '@/api/testimonials'


export default {
  state: {
    testimonials: [],
    videotestimonials: [],
  },
  getters: {
    getTestimonials(state) {
      return state.testimonials
    },
    getVideoTestimonials(state) {
      return state.videotestimonials
    },
  },
  mutations: {
    updateTestimonials(state, testimonials) {
      if( state.testimonials.length === 0 ) {
        state.testimonials = testimonials
      } else {
        state.testimonials.data.reviews.push(...testimonials.data.reviews)
      }
    },
    updateVideoTestimonials(state, testimonials) {
      state.videotestimonials = testimonials
    },

  },
  actions: {
    async fetchTestimonials({ commit }, { page = 1}) {

      let queryParams = `?page=${page}`;

      const [err, result] = await getTestimonialsService(queryParams)
      if (err) throw err

      commit('updateTestimonials', result)
    },

    async fetchVideoTestimonials({ commit }) {

      const [err, result] = await getTestimonialsVideoService()
      if (err) throw err



      commit('updateVideoTestimonials', result)
    },

    async setTestimonialsAction({ commit, getters }, data ) {
      //await commit('setReadNotifications')
      console.log('222',data)
      const [err, result]  = await setTestimonialsService({
        estimation: data.estimation,
        description: data.description,
      })

      if (err) throw err
      console.log("~!!~!~!~!~!")
      return result
    },
  },

  namespaced: true,
}
