<template>
  <UiModalService :visible="isModal" @closeModal="closeServiceModal">
    <div class="copy-link-window" @click.stop :class='!isModal && "closeModalService"' ref="contentToConvert" style='width: 1200px; height: 675px;'>
      <div class="modal-content" v-if='getCurrentUser'>
        <div class="info">
          <div class="avatar" v-if='getCurrentUser'>
            <img :src='getCurrentUser.avatar' alt="avatar">
          </div>

          <div class="user-name" v-if='getCurrentUser && getCurrentUser.nickname'>
            {{getCurrentUser.nickname}}
          </div>
          <div class="user-name" v-else>
            {{getCurrentUser.name && getCurrentUser.name}}
          </div>

          <div class="user-stats" :style="{ display: isCopyLinkWindow.data ? 'flex' : 'none' }">
<!--            <div class="item">
              <div class="title">
                income
              </div>
              <div class="amount">
                10k USD
              </div>
            </div>-->
            <div class="item">
              <div class="title">
                {{ $t('copyLinkModal.partners') }}
              </div>
              <div class="amount">
                {{getReferral.length}}
              </div>
            </div>
            <div class="item">
              <div class="title">
                {{ $t('copyLinkModal.withMetaForce') }}
              </div>
              <div class="amount">
                {{getCurrentUser.created_at | formatMonthYearDifference }}
              </div>
            </div>
          </div>
        </div>
        <div class="qr-title">
          {{ $t('copyLinkModal.joinWithMe') }}
        </div>
        <div class="qr-code">
          <qrcode-vue :value="idRefLink + 'id:' + getCurrentUser.id" :size="size" background='transparent' foreground='#fff' ref="qrcode"></qrcode-vue>
        </div>
        <div class="user-link">
          {{ idRefLink }}<span>{{getCurrentUser.name && getCurrentUser.name}}</span>
        </div>
      </div>
    </div>
  </UiModalService>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment';
import QrcodeVue from 'qrcode.vue'
import html2canvas from 'html2canvas';
import { copyToClipboard } from '@/helpers/clipboard'


export default {
  name: "CopyLinkWindow",
  components: {
    QrcodeVue
  },
  data() {
    return {
      size: 250,
      dataShow: true,
      user: null,
      countdown: 3,
    }
  },
  props: {
    isModal: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('user', ['getReferral', 'getCurrentUser']),
    ...mapGetters('ui', ['isCopyLinkWindow']),
    refLink() {
      return `https://${window.location.host}/r/${this.getCurrentUser.name && this.getCurrentUser.name}`
    },
    idRefLink() {
      return `https://${window.location.host}/r/`
    },
  },
  mounted() {
    this.user = this.getCurrentUser
  },
  created() {

    this.$watch(
      () => this.isCopyLinkWindow.visible,
      (newVal, oldVal) => {
        if(newVal) {
          this.convertToImage()
            .then(() => {
              this.$toast.success(`Download has started`)
            })
            .catch(() => {
              this.$toast.error(`Download error`)
            })
          this.setIsCopyLinkWindow({visible: false})
        }
      }
    );

  },
  methods: {
    ...mapMutations('ui', ['setIsCopyLinkModal','setIsCopyLinkWindow']),
    closeServiceModal() {
      this.setIsCopyLinkWindow({visible: false})
      this.setIsCopyLinkModal(true)
    },

    handleClipboardCopy() {
      if (!this.getCurrentUser) return
      copyToClipboard(this.refLink)
        .then(() => {
          this.$toast.success(`${this.refLink} ${this.$t('account.copied')}`)
        })
        .catch(() => {
          this.$toast.error(`${this.$t('account.copyError')}`)
        })
    },

    async convertToImage() {
      // Check if the avatar image is loaded
      const avatarImage = new Image();
      avatarImage.src = this.getCurrentUser.avatar;

      avatarImage.onload = async () => {
        const content = this.$refs.contentToConvert;
        const canvas = await html2canvas(content, {allowTaint: true, useCORS: true});
        const dataURL = canvas.toDataURL('image/png').replace("image/png", "image/octet-stream");
        const a = document.createElement('a');
        a.href = dataURL;
        a.download = 'meta-force-space.png';
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
    },
  },
  filters: {
    formatMonthYearDifference(targetDate) {
      const currentDate = moment();
      const targetMoment = moment(targetDate);

      const diffInMonths = -targetMoment.diff(currentDate, 'months');
      if (diffInMonths < 1) {
        return 'less than a month';
      } else if (diffInMonths >= 1 && diffInMonths <= 12) {
        return `${diffInMonths} month`;
      } else {
        const years = Math.floor(diffInMonths / 12);
        const months = diffInMonths % 12;
        const yearString = years > 0 ? `${years} year` : '';
        const monthString = months > 0 ? `${months} month` : '';
        return `${yearString}${yearString && monthString ? ' and ' : ''}${monthString}`;
      }
    },
  },
}
</script>


<style lang="scss">
@import "~@/assets/scss/mixins";

.copy-link-window {
  @include flex-center-center;
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(237, 138, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%), #000;
  overflow: hidden;

  .close-item {
    @include flex-center-center;
    width: 40px;
    height: 40px;
    position: absolute;
    top: var(--indent-8);
    right: var(--indent-8);
    z-index: 2;

    svg path {
      transition: .3s ease;
    }

    &:hover {
      svg path {
        fill-opacity: 1;
      }
    }
  }

  .modal-content {
    @include flex-column;
    align-items: center;
    position: relative;
    max-width: 508px;
    margin: 0 auto;
    z-index: 1;

    .info {
      @include flex-column;
      align-items: center;
      justify-content: center;
      width: 100%;

      .avatar {
        @include flex-center-center;
        width: 64px;
        height: 64px;
        margin-bottom: var(--indent-8);
        border: var(--indent-4) solid var(--color-white);
        border-radius: var(--radius-50);
        background: var(--color-white);
        overflow: hidden;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .user-name {
        margin-bottom: var(--indent-8);
        color: var(--color-white);
        text-align: center;
        font-weight: var(--font-500);
        @include font-16;
      }

      .user-stats {
        display: flex;
        width: 100%;
        padding: var(--indent-12) 0;
        border-radius: var(--indent-8);
        background: var(--color-white-5);
        text-align: center;

        .item {
          @include flex-column;
          align-items: center;
          justify-content: center;
          flex: 1;

          .title {
            color: var(--color-white-40);
            font-weight: var(--font-400);
            @include font-14;
            text-transform: uppercase;
          }

          .amount {
            color: var(--color-white);
            font-weight: var(--font-500);
            @include font-16;
          }
        }

        .item + .item {
          position: relative;

          &:before {
            position: absolute;
            left: 0;
            width: 1px;
            height: var(--indent-32);
            background: var(--color-white-40);
            content: '';
          }
        }
      }
    }

    .qr-title {
      margin: var(--indent-32) 0 var(--indent-8);
      color: var(--color-white);
      text-align: center;
      font-weight: var(--font-600);
      @include font-24;
    }

    .qr-code {
      @include flex-center-center;
      position: relative;
      margin-bottom: var(--indent-8);

      .qr-logo {
        @include flex-center-center;
        position: absolute;
      }
    }

    .user-link {
      color: var(--color-white-40);
      font-weight: var(--font-500);
      @include font-24;

      span {
        color: var(--color-white);
      }
    }
  }

  &.closeModalService {
    right: 0 !important;
  }
}
</style>
