import { getAllFollowersService } from '@/api/user'
import {
    createChatService,
    deleteChatService,
    getChatMessagesService,
    getMyChatService,
    complaintChatService,
    getBlockListService,
    getPrivacyService,
    setPrivacyService,
    deleteFileService,
    unBlockService,
    uploadFileService,
    setBlockStatusService,
    setBlockService
} from '@/api/messenger'
import User from "./user"

export default {
    state: {
        chat: null,
        socketChatInstance: null,
        selectedUsers: [],
        showMessages: false,
        showCreate: false,
        activeChats: [],
        actualChat: [],
        followers: [],
        messages: [],
        messagesPage: {},
        isCreatedChat: false,
        modalConformation: false,
        hasToken: false,
        loadMessages: false,
        isCreateChatModal: false,
        messengerLoader: false,
        unreadChatMessages: [],
        privacy: null,
        blockList: [],
        blockListFromSocket: [],
        blockListPage: {},
        blockListFetched: false,
        infoForModal: null,
        uploadedFiles: [],
        uploadedModal: false
    },
    getters: {
        getMessagesPage(state) {
          return state.messagesPage
        },
        getUnreadChatMessages(state) {
          return state.unreadChatMessages
        },
        getActualChats(state) {
            state.actualChat.sort((a, b) => {
                const dateA = new Date(a.created_at);
                const dateB = new Date(b.created_at);

                if (dateB.getTime() !== dateA.getTime()) {
                    return dateB - dateA;
                }
                return a.unread - b.unread;
            });
            return state.actualChat
        },
        getTotalUnread(state) {
            if (state.unreadChatMessages.length !== 0) {
                return state.unreadChatMessages.reduce((acc, num) => {
                    return acc + num.unread
                }, 0)
            }
            return 0
        },
        getMessengerLoader(state) {
            return state.messengerLoader
        },
        getSocketChatInstance(state) {
          return state.socketChatInstance
        },
        getLoadMessages(state) {
            return  state.loadMessages
        },
        getHasToken(state) {
          return state.hasToken
        },
        isCreatedChat(state) {
            return state.isCreatedChat
        },
        chat(state) {
            return state.chat
        },
        getChatMessages(state) {
            return state.messages
        },
        activeChats(state) {
            return state.activeChats
        },
        showMessages(state) {
            return state.showMessages
        },
        followers(state) {
            return state.followers
        },
        modalConformation(state) {
            return state.modalConformation
        },
        isCreateChatModal(state) {
            return state.isCreateChatModal
        },
        getPrivacy(state) {
            return state.privacy
        },
        getBlockList(state) {
            return state.blockList
        },
        getBlockListFetched(state) {
          return state.blockListFetched
        },
        infoForModal(state) {
            return state.infoForModal
        },
        uploadedFiles(state) {
            return state.uploadedFiles
        },
        uploadedModal(state) {
           return state.uploadedModal
        },
        getBlockListPage(state) {
            return state.blockListPage
        },
        getAllBlockList(state) {
            const filteredArray = state.blockListFromSocket.length !== 0 ?
              state.blockListFromSocket.filter(item => item.id !== User.state.currentUser.id) : []
            const combinedArray = [...state.blockList, ...filteredArray];
            const uniqueIds = new Set();
            return combinedArray.filter(obj => {
                if (!uniqueIds.has(obj.id)) {
                    uniqueIds.add(obj.id);
                    return true;
                }
                return false;
            })
        }
    },
    mutations: {
        setMessagesPage(state, data) {
            state.messagesPage = data
        },
        filterUnreadChat(state, response) {
            state.unreadChatMessages = state.unreadChatMessages.filter(item => item.chat_key !== response.chat_key)
        },
        setActualChat(state, chats) {
            state.actualChat = chats
        },
        addActualChat(state, chats) {
            state.actualChat = [...state.actualChat,...chats]
        },
        setChatFromSocket(state, { index, message, attachments_count, created_at }) {
            state.actualChat[index].message = message;
            state.actualChat[index].attachments_count = attachments_count;
            state.actualChat[index].created_at = created_at;
        },
        addUnreadMessagesChat(state, unread) {
            const hasUnread = state.unreadChatMessages.findIndex(item => item.chat_key === unread.chat_key)
            if (hasUnread !== -1) {
                state.unreadChatMessages[hasUnread].unread += 1

            } else {
                state.unreadChatMessages.push(unread)
            }
        },
        setUnreadChatMessagesInit(state, listUnread) {
            state.unreadChatMessages = listUnread
        },
        setUnreadChat(state) {
            if (state.unreadChatMessages.length === 0) return
            state.activeChats.forEach(obj1 => {
                let matchingObj2 = state.unreadChatMessages?.find(obj2 => obj2.chat_key === obj1.chat_key);
                if (matchingObj2) {
                    obj1.unread = matchingObj2.unread;
                } else {
                    obj1.unread = 0
                }
            });
        },
        SET_MESSENGER_LOADER(state, bool) {
          state.messengerLoader = bool
        },
        setSocketChatInstance(state, socket) {
          state.socketChatInstance = socket
        },
        SET_LOAD_MESSAGES(state, bool) {
          state.loadMessages = bool
        },
        setHasToken(state, bool) {
          state.hasToken = bool
        },
        SET_IS_CREATED_CHAT(state, bool) {
            state.isCreatedChat = bool
        },
        ADD_SELECTED_USER(state, user)  {
            state.selectedUsers.push(user)
        },
        REMOVE_SELECTED_USER: (state, user) => {
            const index = state.selectedUsers.findIndex(u => u.id === user.id)
            if (index !== -1) {
                state.selectedUsers.splice(index, 1)
            }
        },
        SET_SHOW_MESSAGES(state, show) {
            state.showMessages = show;
        },
        SET_FOLLOWERS(state, response) {
            state.followers = response
        },
        SET_CHAT(state, response) {
            if (response === null) {
                state.chat = null
                return
            }
            state.chat = { ...response, unread: 0 }
            const idxUser = state.activeChats?.findIndex(item => item.chat_key === response.chat_key)
            if (idxUser !== -1) {
                state.activeChats[idxUser].unread = 0
            }
            state.unreadChatMessages = state.unreadChatMessages?.filter(item => item.chat_key !== response.chat_key)
        },
        SET_ACTIVE_CHATS(state, chats) {
            state.activeChats = chats
        },
        ADD_ACTIVE_CHATS(state, chats) {
            state.activeChats = [...state.activeChats, chats]
        },
        SET_MESSAGES(state, message) {
            state.messages.push(message)
        },
        UPDATE_MESSAGES(state, messages) {
            state.messages = messages
        },
        UPDATE_MESSAGES_MORE(state, messages) {
            state.messages.unshift(...messages)
        },
        DELETE_MESSAGE(state, key) {
            state.messages = state.messages.filter(item => item.key !== key);
        },
        EDIT_MESSAGE(state, message) {

            state.messages = state.messages.map(item => {
                if (item.key === message.key) {
                    return {
                        ...item,
                        message: message.message,
                        edited: message.edited
                    };
                }
                return item;
            });
        },
        DELETE_MESSAGES(state) {
            state.messages = []
        },
        SET_MODAL_CONFIRMATION(state, show) {
            state.modalConformation = show
        },
        SET_OPENED_CHAT(state, user) {
            state.chat = state.activeChats.find(item => item.id === user.id)
        },
        SET_IS_CREATED_CHAT_MODAL(state, bool) {
            state.isCreateChatModal = bool
        },
        SET_DELETE_CHAT(state, chat) {
            state.activeChats = state.activeChats.filter(item => {
                return item.id !== chat.id
            })
        },
        setPrivacy(state, data) {
            state.privacy = data
        },
        setBlockList(state, data) {
            state.blockList = data
        },
        setMoreBlockList(state,data) {
            state.blockList.push(...data)
        },
        setBlockListPage(state, data) {
            state.blockListPage = data
        },
        setBlockListFetched(state) {
            state.blockListFetched = true
        },
        SET_INFO_FOR_MODAL(state, info) {
            state.infoForModal = info
        },
        SET_UNLOCK_USER(state, id) {
            if (state.blockList.length === 0) return
            state.blockList = state.blockList.filter(item => item.id !== id)
        },
        SET_BLOCK_CHAT(state, chat) {
            const isHas = state.blockList.find(item => item.id === chat.id)
            if (isHas) {
                return
            }
            state.blockList.unshift(chat)
        },
        SET_UNLOCK_USER_WS(state, payload) {
            if (state.blockListFromSocket.length === 0) return
            state.blockListFromSocket = state.blockListFromSocket.filter(item => item.id !== payload.id)
        },
        SET_BLOCK_CHAT_WS(state, chat) {
            state.blockListFromSocket.unshift(chat)
        },
        SET_UPLOADED_FILES( state, files) {
            if (files === null ) {
                state.uploadedFiles = []
            } else {
                state.uploadedFiles.push(...files)
            }
        },
        SET_UPLOADED_MODAL(state, data) {
            state.uploadedModal = data
        },
        SET_REMOVE_FILE(state, file) {
            const index = state.uploadedFiles.findIndex(item => item.key === file.key);

            if (index !== -1) {
                state.uploadedFiles.splice(index, 1);
            }
        },
        SET_ONLINE_WS(state, [data, isTrue]) {
            const idx = state.activeChats.findIndex(item => item.id === data.user_id)
            if (idx !== -1) {
                state.activeChats[idx].online = isTrue
            }
            if (state.chat !== null) {
                const chatIdx = state.chat.id === data.user_id
                if (chatIdx) {
                    state.chat.online = isTrue
                }
            }
        },
    },
    actions: {
        UPDATE_MODAL_CONFIRMATION({commit}, show) {
            commit('SET_MODAL_CONFIRMATION', show)
        },
        async GET_FOLLOWERS({ commit }) {
            const [err, data] = await getAllFollowersService()
            if (err) throw err
            commit('SET_FOLLOWERS', data.users)
        },
        SELECT_CHAT({commit}, user) {
            commit('SET_CHAT', user)
        },
        UPDATE_USER({ commit }, user) {
            if (user.checked) {
                commit('ADD_SELECTED_USER', user)
            } else {
                commit('REMOVE_SELECTED_USER', user)
            }
        },
        UPDATE_SHOW_MESSAGES({ commit }, show) {
            commit('SET_SHOW_MESSAGES', show);
        },
        async CREATE_CHAT({commit}, user) {
            const [err, data] = await createChatService(user.id)
            if (err) throw err
            commit('ADD_ACTIVE_CHATS', {
                ...user,
                chat_key: data.chat_key
            });
            commit('SET_CHAT', {
                ...user,
                chat_key: data.chat_key
            });
        },
        async GET_ACTIVE_CHATS({commit}){
            const [err, data] = await getMyChatService()
            if (err) throw err
            commit('SET_ACTIVE_CHATS', data.users);
        },

        async GET_CHATS_MESSAGES({commit}, payload){
            const [err, data] = await getChatMessagesService(payload)
            if (err) {
                console.log('messenger-CREATE_CHAT', err)
                return err
            }
            commit('UPDATE_MESSAGES', data.list);
            commit('setMessagesPage', {page: data.page, total: data.total})
        },

        async GET_MORE_CHATS_MESSAGES({commit}, payload){
            const [err, data] = await getChatMessagesService(payload)
            if (err) {
                console.log('messenger-CREATE_CHAT', err)
                return err
            }
            commit('UPDATE_MESSAGES_MORE', data.list);
            commit('setMessagesPage', {page: data.page, total: data.total})
        },

        async DELETE_CHAT({commit},chat) {
            const [err, data] = await deleteChatService(chat.chat_key)
            if (err) throw err
            commit('SET_DELETE_CHAT', chat)
        },

        async BLOCK_CHAT({commit},chat) {
            const [err, data] = await setBlockService(chat.id)
            if (err) throw err
            commit('SET_BLOCK_CHAT', chat)
            commit('SET_CHAT', null)
        },
        async GET_PRIVACY_ACTION({commit}){
            const [err, data] = await getPrivacyService()
            if (err) throw err
            commit('setPrivacy', data)
        },
        async SET_PRIVACY_ACTION({commit}, id){
            const [err, data] = await setPrivacyService(id)
            if (err) throw err
            commit('setPrivacy', data)
        },
        async GET_BLOCK_LIST_ACTION({commit}, [payload, isMore]){
            const [err, data] = await getBlockListService(payload)
            if (err) throw err
            if (isMore) {
                commit('setMoreBlockList', data.data)
            } else {
                commit('setBlockList', data.data)
            }
            commit('setBlockListPage', {page: data.page, total: data.total})
            commit('setBlockListFetched')
        },
        async COMPLAINT_CHAT({ commit }, request) {
            const [err, result] = await complaintChatService(request)

            if (err) throw err
            return result
        },
        async UPLOAD_FILES({ commit }, request) {
            const [err, result] = await uploadFileService(request)
            commit('SET_UPLOADED_FILES', result)

            if (err) throw err

            return result
        },
        async REMOVE_FILE({commit}, file) {
            commit('SET_REMOVE_FILE', file)
            const [err, result] = await deleteFileService(file)
            if (err) throw err
            return result
        },
        async UNBLOCK_USER_ACTION({commit}, id){
            const [err, result] = await unBlockService(id)

            if (err) throw err
            commit('SET_UNLOCK_USER', id)
            commit('SET_CHAT', null)
            return result
        },
        async IS_BLOCKED_USER_ACTION({commit}, id){
            const [err, result] = await setBlockStatusService(id)

            if (err) throw err
            return result
        }
    },
    namespaced: true
}
