import { $axiosNewApi } from '@/plugins/Axios'
import endpoint from '@/config/api-endpoint'
import { vm } from '@/main'
import { mapApiError, mapData } from '@/api/helpers'

export const getFollowPublicPostService = async (payload) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.publicPost.followPublic,{
      params: payload
    })
    if (data && (data.status === 'error')) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, data]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}

export const getLikedPublicPostService = async (payload) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.publicPost.likedPublic(payload.id), {
      params: {page: payload.page ,limit: payload.limit}
    })
    if (data && (data.status === 'error')) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, data]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}

export const getMyPublicPostService = async (payload) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.publicPost.myPublic(payload.id), {
      params: {page: payload.page ,limit: payload.limit}
    })
    if (data && (data.status === 'error')) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, data]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}

export const addMyPublicPostService = async (payload) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.publicPost.addMyPost,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    if (data && (data.status === 'error')) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}

export const updatePublicPostService = async ([formData, id]) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.publicPost.getOnePost(id),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    if (data && (data.status === 'error')) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}

export const getOnePublicPostService = async (id) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.publicPost.getOnePost(id))
    if (data && (data.status === 'error')) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}

export const deletePublicPostService = async (id) => {
  try {
    const { data } = await $axiosNewApi.delete(endpoint.publicPost.getOnePost(id))
    if (data && (data.status === 'error')) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}

export const likePublicPostService = async (id) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.publicPost.likePost(id))
    if (data && (data.status === 'error')) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}

export const addCommentService = async (payload) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.publicPost.addComment(payload.id), {
      content: payload.text
    })
    if (data && (data.status === 'error')) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}
export const editCommentService = async (payload) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.publicPost.editComments(payload.id), {
      content: payload.text
    })
    if (data && (data.status === 'error')) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}

export const getCommentService = async (payload) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.publicPost.getComments(payload.id), {
      params: {page: payload.page ,limit: payload.limit}
    })
    if (data && (data.status === 'error')) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, data]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}

export const deleteCommentService = async (id) => {
  try {
    const { data } = await $axiosNewApi.delete(endpoint.publicPost.deleteComments(id))
    if (data && (data.status === 'error')) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}