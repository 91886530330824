import { $axiosNewApi } from '@/plugins/Axios'
import endpoint from '@/config/api-endpoint'
import { mapApiError, mapData } from './helpers'

export const getPostsService = async (request) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.post.posts(request))

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}
export const getCategoriesService = async () => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.post.categories)

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}
export const getCategoryService = async (id = 1) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.post.category(id))

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}
export const postLikeService = async (request) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.post.like(request))

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}
export const getSinglePostService = async (request) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.post.post(request))

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}
export const getLandingPostsService = async () => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.post.landingPosts)

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}
export const getFilterPostsService = async () => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.post.filterPosts)

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}


export const getContestsService = async () => {
  try {
    const { data } = await $axiosNewApi.get("/contest?perPage=8")
    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}