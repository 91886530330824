import VueTippy from "vue-tippy"

const init = (app) => {
    const options = {
        directive: "tippy", // => v-tippy
        popperOptions: {
            modifiers: {
                preventOverflow: {
                    enabled: true,
                },
            },
        },
    }

    app.use(VueTippy, options)
}

export default init
