export const copyToClipboard = (textToCopy) => {
    // fallback function (old browsers and TrustWallet)
    function copyViaGhost() {
        // text area method
        let textArea = document.createElement("textarea")
        textArea.value = textToCopy
        // make the textarea out of viewport
        textArea.style.position = "fixed"
        textArea.style.left = "-999999px"
        textArea.style.top = "-999999px"
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        return new Promise((res, rej) => {
            // here the magic happens
            document.execCommand("copy") ? res() : rej()
            textArea.remove()
        })
    }

    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy).catch((err) => {
            return copyViaGhost()
        })
    } else {
        // text area method
        return copyViaGhost()
    }
}
