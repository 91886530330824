import { isDevelopment } from "@/helpers/dev"

export const programArray = (program) => {
    const classic = JSON.parse(program)
    const classicArr = Object.keys(classic).map((k) => classic[k])

    return classicArr
}

export const computeStats = (program) => {
    const classicArr = programArray(program)

    const totalPartners = classicArr.reduce((acc, x) => {
        acc += Number(x.partners)

        return acc
    }, 0)

    return {
        partners: totalPartners,
        busd: 0,
    }
}

export const getClassicTypeByLevel = (lvl) => {
    return (Number(lvl) + 1) % 3 === 0 ? "s3" : "s6"
}

export const getClassicPriceByLevel = (lvl) => {
    // const basePrice = isDevelopment() ? 0.00005 : 5
    const basePrice = 5;
    return basePrice * 2 ** Number(lvl)
}

export const defineStructureIndexes = ({ type, slot }) => {
    let res = {}

    if (type === "s3") {
        const base = 3 * Number(slot)
        res.indexesS3 = [base, base + 1, base + 2].map((x) => x.toString())
    } else if (type === "s6") {
        const base1 = 2 * Number(slot)
        const base2 = 4 * Number(slot)
        res.indexesS6Lvl1 = [base1, base1 + 1].map((x) => x.toString())
        res.indexesS6Lvl2 = [base2, base2 + 1, base2 + 2, base2 + 3].map((x) => x.toString())
    }

    return res
}

export const nullEmptyHash = (address) => {
    const processing = (x) => (x === "0x0000000000000000000000000000000000000000" ? null : x)

    if (typeof address === "string") {
        return processing(address)
    } else if (Array.isArray(address)) {
        return address.map((x) => processing(x))
    }
    return address
}

export const countPartnersInLvl = (type, obj) => {
    let frozen = 0
    let total = 0
    if (type === "s3") {
        frozen = obj["frozenMoneyS3"]
        total = total + +obj["lastChild"]
    } else if (type === "s6") {
        frozen = obj["frozenMoneyS6"]
        total = total + +obj["lastChild1"]
        total = total + +obj["lastChild2"]
    }

    return {
        partners: total,
        frozen,
    }
}

export const countSpendInLvl = (totalPartners, level) => {
    const price = getClassicPriceByLevel(level)

    return Number(price * totalPartners).toFixed(2)
}

export const countPureRevenue = ({ totalSlots, slot, lvl1, lvl2, level, forCurrentSlot }) => {
    const price = getClassicPriceByLevel(level)
    const type = getClassicTypeByLevel(level)

    const filledSlots = +totalSlots > 0 ? +totalSlots - 1 : 0
    let filledTotal = 0
    let currentTotal = 0

    if (slot !== totalSlots && !forCurrentSlot) return null

    const S3PricedPositions = 3
    const S6PricedPositionsLvl2 = 4

    // calc filled slots by predefined prices positions
    // current slot based on lvl length (only 2lvl for s6)
    // last slot goes to reactivation (// TODO)
    if (type === "s3") {
        if (!forCurrentSlot) {
            filledTotal = price * (filledSlots * (S3PricedPositions - 1))
        }

        const childLength = lvl1.filter((x) => x).length
        currentTotal = price * childLength
        if (childLength === S3PricedPositions) {
            currentTotal = currentTotal - price
        }
    } else if (type === "s6") {
        if (!forCurrentSlot) {
            filledTotal = price * (filledSlots * (S6PricedPositionsLvl2 - 1))
        }

        const childLength = lvl2.filter((x) => x).length
        currentTotal = price * childLength

        if (childLength === S6PricedPositionsLvl2) {
            currentTotal = currentTotal - price
        }
    }

    return Number(filledTotal + currentTotal).toFixed(2)
}
