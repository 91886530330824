<template>
  <div
    @click="areOptionsVisible = !areOptionsVisible"
    class="languages"
    :class="areOptionsVisible ? 'show' : ''"
  >
    <p class="languages__current">
      {{ selectedUpperCase }}
      <span></span>
    </p>
    <ul v-if="areOptionsVisible" class="options list-reset">
      <li
        v-for="option in options"
        :key="option"
        :class="option === $i18n.locale ? 'options__item active' : 'options__item'"
        @click.stop="switchLocale(option)"
      >
        {{ option }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {

  data() {
    return {
      areOptionsVisible: false,
    }
  },
  props: {
    options: {
      type: Array,
      default() {
        return []
      },
    },
  },
  methods: {
    switchLocale(locale) {
      let lang = locale.toLowerCase()
      localStorage.setItem('lang', lang)
      this.$i18n.locale = lang
      this.$moment.locale(lang)
    },
  },
  computed: {
    selectedUpperCase() {
      return `${this.$i18n.locale.toUpperCase() }`
    },
  },

  mounted() {
    window.addEventListener('click', (event) => {
      if (!this.$el.contains(event.target)) {
        this.areOptionsVisible = false
      }
    })
  },
  
}
</script>

<style lang="scss">
.languages {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--indent-8);
  border: 1px solid var(--color-black-700);
  color: var(--color-black-500);
  width: 50px;
  height: 32px;
  cursor: pointer;
  position: relative;
  transition: border-radius 0.25s ease-in-out;
  text-transform: uppercase;
  @media (max-width: 1025px) {
    display: none;
  }
  li,
  p {
    font-weight: var(--font-500);
    font-size: var(--font-14);
    line-height: var(--line-20);
  }
  &__current {
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 0 0 10px 10px;
    z-index: 10;

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 32px;
      border: 1px solid var(--color-white-20);
      background: var(--color-white-5);
      backdrop-filter: blur(var(--indent-20));
      border-radius: 8px;
      text-align: center;
      color: white;
      transition: .3s ease;
      &:hover,
      &.active {
        border: 1px solid var(--color-orange-900);
      }
    }
  }

  &--light {
    background-color: var(--color-charade);
    .options {
      &__item {
        background-color: var(--color-charade);
      }
    }
  }
}
</style>
