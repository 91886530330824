<template>
  <div class="modal" id="transferModal" @click.stop :class='!isModal && "closeModalService"'>
    <div class='successModal'>
      <div><BaseIcon name='successTransfer' ver-align /></div>
      <h2>{{ daiAmount }} DAI</h2>
      <p v-if='from === "Wallet"'>The transfer from Wallet to Inner balance was successful</p>
      <p v-else>The transfer from Inner balance to Wallet was successful</p>
      <button @click.stop='done'>Done</button>
    </div>
  </div>
</template>
<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'

export default {
  components: { BaseIcon },
  props: {
    isModal: {
      type: Boolean
    },
    daiAmount: {
      type: Number
    },
    from: {
      type: String
    }
  },
  methods: {
    done() {
      this.$emit('done')
    }
  }
}
</script>
<style lang='scss'>
.successModal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 24px;
  h2 {
    color: white;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -0.16px;
  }
  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.064px;
    color: white;
  }
  button {
    margin-top: 26px;
    display: flex;
    width: 180px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #EB7104;
    &:hover {
      opacity: .8;
    }
  }
}
</style>