const checkImageExists = (src) => {
    return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = resolve
        img.onerror = reject
        img.src = src
    })
}

export const createImageHref = (url, replaceAvatar = true) => {
    const defaultAvatarUrl = "/assets/avatar.jpg"
    let returnUrl = defaultAvatarUrl

    if (!url && replaceAvatar) return returnUrl

    const imgUrl = url
    returnUrl = imgUrl

    // await checkImageExists(imgUrl).catch(() => {
    //     returnUrl = defaultAvatarUrl
    //     console.log("error, return default", returnUrl)
    // })

    return returnUrl
}
