<template>
  <img
    :src="avatarPath"
    class="avatar-image"
    :alt="[user?.name ? user?.name : 'avatar']"
    v-if="user"
    @error="defaultAvatarCallback"
  />
</template>

<script>
import { createImageHref } from '@/helpers/images'
import { DEFAULT_AVATAR } from '@/config/constants'

export default {
  props: {
    user: Object
  },
  computed: {
    avatarPath () {
      return createImageHref(this.user?.avatar)
    }
  },
  methods: {
    defaultAvatarCallback (e) {
        e.target.src = DEFAULT_AVATAR
    }
  }
}
</script>

<style scoped>
.avatar-image {
  object-fit: cover;
}
</style>
