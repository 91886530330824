import { DICT } from "@/config/constants"

export const buildRefCode = (account, id) => {
    const first6 = account.slice(2, 8)
    const last4 = account.slice(-2)

    return `${first6}${id}${last4}`
}

export const decodeRefCode = (ref) => {
    return ref.slice(6, ref.length).slice(0, -2)
}

export const isRefCode = (str) => {
    return str.length >= 9 && !Number(str)
}

export const isForceId = (str) => {
    const defaultName = str?.length === 10
    const customName = str?.length >= 3 && str.length <= 20

    return defaultName || customName
}

export const isHashAddress = (str) => {
    // return str.startsWith("0x") && str.length === 42
    return /0x[a-fA-F0-9]{40}/g.test(str)
}

export const getUserRequestFunction = () => {}

export const isSameAccount = (acc1, acc2) => {
    if (!acc1 || !acc2) return false

    return acc1.toUpperCase() === acc2.toUpperCase()
}

export const getExplorerLinkByTx = (tx) => {
    const explorer = DICT.EXPLORER_URLS[DICT.IS_TESTNET ? 1 : 0]
    return `${explorer}tx/${tx}`
}
