<template>
  <div class="modalWalletBack" :class="[visible && 'showModalBack']" @click="closeModal" key='modalService'>
    <slot></slot>
  </div>
</template>

<script>
import {  mapMutations } from 'vuex'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
  },
  methods: {
    ...mapMutations('ui', ['setOpenModal']),

    closeModal() {
      this.$emit("closeModal")
    }
  },
  watch: {
    visible(newValue, oldValue) {
      this.setOpenModal(newValue)
    }
  },
}
</script>

<style lang="scss" scoped>
.modalWalletBack {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.2);
  backdrop-filter: blur(5px);
  z-index: 400;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease-in-out;
  overflow: hidden;
  &.showModalBack {
    opacity: 1;
    pointer-events: all;
  }
}
</style>
