<template>
  <div
    class="sidebar"
    :class="[isDesctopSideBar && 'hideDesctopMenu']"
  >
    <div class="sidebar-inner">
      <div class="sidebar__body">
        <div class="sidebar-inner__top">
          <p @click='toLandingPage'
             class="sidebar__logo logo d-flex align-center"
             :class="isDesctopSideBar && 'closeSideBar__btn'"
          >
            <BaseIcon @event='toLandingPage' name="mainLogo" verAlign />
          </p>
          <BaseIcon
            name="logout"
            class="open-close__btn"
            :class="isDesctopSideBar && 'closeBtn'"
            @event="isDesctopMenu"
          />
        </div>
        <Menu :count="unreadMessages" :isClosed="isDesctopSideBar" />
      </div>
      <div class="sidebar-inner__buttons" v-show='!isDesctopSideBar'>
<!--        {{this.getCurrentUser}}-->
        <CopyMyLink
          v-show='!isDesctopSideBar && getAccount && getCurrentUser'
        />

        <SidebarBottom
          :isClosed="isDesctopSideBar"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import Menu from './Menu.vue'
import BaseIcon from '@/components/UI/BaseIcon.vue'
import SidebarBottom from '../Dashboard/SidebarBottom.vue'
import CopyMyLink from '@/components/Dashboard/partials/CopyMyLink.vue'

export default {
  name: 'Sidebar-Page',
  data() {
    return {
      isDesctopSideBar: false,
      intervalCount: 0,
    }
  },
  async mounted() {
    window.addEventListener('click', (event) => {
      if (!this.$el.contains(event.target)) {
        this.isDesctopSideBar = false
        this.setIsSideBarClose(false)
      }
    })
  },
  components: {
    CopyMyLink,
    Menu,
    BaseIcon,
    SidebarBottom,
  },
  computed: {
    ...mapGetters('crypto', ['getToken', 'getAccount']),
    ...mapGetters('ticket', ['unreadMessages']),
    ...mapGetters('user', ['getInitDone', 'getCurrentUser']),
    isSameAccount() {
      return this.getCurrentUser?.account === this.getAccount
    }
  },
  methods: {
    ...mapMutations('ui', ['setIsSideBarClose']),

    toLandingPage() {
      this.$router.push('/')
    },

    isDesctopMenu() {
      this.isDesctopSideBar = !this.isDesctopSideBar
      this.setIsSideBarClose(true)
    },

  },
}
</script>

<style lang="scss">
.closeBtn {
  position: absolute;
  left: -10px;
  top: 10px;
}
.open-close__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  background: rgba(255, 255, 255, 0.05);
  border-radius: var(--indent-8);
  cursor: pointer;
  transition: .3s ease;
  svg path {
    transition: .3s ease;
  }
  &:hover {
    background: var(--color-white-10);
    opacity: 1;

    svg path {
      fill-opacity: 1;
    }
  }
  @media (max-width: 991px) {
    display: none;
  }
}

.sidebar-burger {
  width: 34px;
  height: 34px;
  color: var(--color-white-50);
  stroke: var(--color-white-50);
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.closeSideBar__btn {
  display: none !important;
}
</style>
