<template>
  <UiModalService :visible="isModal" v-if='isModal' @closeModal="closeServiceModal">
    <div class="copy-link-modal" @click.stop :class='!isModal && "closeModalService"'>
      <BaseIcon class="close-item" name='closeServiceIcon' @event='closeServiceModal' />

      <div class="modal-content" v-if='getCurrentUser'>
        <div class="info">
          <div class="avatar" v-if='getCurrentUser'>
            <img :src='getCurrentUser.avatar' alt="">
          </div>
          <div class="user-name" v-if='getCurrentUser && getCurrentUser.nickname'>
            {{getCurrentUser.nickname}}
          </div>
          <div class="user-name" v-else>
            {{getCurrentUser.name}}
          </div>
          <div class="user-stats" v-if='isCopyLinkWindow.data'>
<!--            <div class="item">
              <div class="title">
                income
              </div>
              <div class="amount">
                10k USD
              </div>
            </div>-->
            <div class="item">
              <div class="title">
                {{ $t('copyLinkModal.partners') }}
              </div>
              <div class="amount">
                {{getReferral.length}}
              </div>
            </div>
            <div class="item">
              <div class="title">
                {{ $t('copyLinkModal.withMetaForce') }}
              </div>
              <div class="amount" v-if='getCurrentUser'>
               {{getCurrentUser.created_at | formatMonthYearDifference }}
              </div>
            </div>
          </div>
          <button class="switch-info show" @click='dataShowFunc'>
            {{isCopyLinkWindow.data ? 'Hide data' : 'Show data'}}
          </button>
        </div>
        <div class="qr-title">
          {{ $t('copyLinkModal.joinWithMe') }}
        </div>
        <div class="qr-code">
<!--          <BaseIcon class="qr-logo" name="qrLogo" />-->
          <qrcode-vue :value="idRefLink + 'id:' + getCurrentUser.id" :size="size" background='transparent' foreground='#fff' ref="qrcode"></qrcode-vue>
        </div>
        <div class="user-link">
          {{ idRefLink }}<span>{{getCurrentUser.name}}</span>
        </div>
        <div class="group">
          <button class="copy" @click='handleClipboardCopy' >
            {{ $t('copyLinkModal.copyMyLink') }}
          </button>
          <button  class="download" download="document" @click="downloadModall">
            {{ $t('copyLinkModal.downloadImage') }}
          </button>
        </div>
        <div class="description">
          <div class="title">{{ $t('copyLinkModal.description0') }}</div>
          <div class="list">
            <div class="item" v-for='(val,i) in descriptionText' :key='i'>
              <p>{{ val }}</p>
              <BaseIcon name="closedIconCopy" @event='handleClipboardCopyDescription(i)'/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </UiModalService>
</template>
<script>
import BaseIcon from "@/components/UI/BaseIcon.vue";
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment';
import QrcodeVue from 'qrcode.vue'
import { copyToClipboard } from '@/helpers/clipboard'
import { vm } from "@/main.js";

export default {
  name: "CopyLinkModal",
  components: {
    BaseIcon,
    QrcodeVue
  },
  data() {
    return {
      size: 250,
      dataShow: true,
      user: null,
    }
  },
  props: {
    isModal: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('user', ['getReferral', 'getCurrentUser']),
    ...mapGetters('ui', ['isCopyLinkWindow']),

    descriptionText() {
      return [
        `${vm.$t("copyLinkModal.description1")}`,
        `${vm.$t("copyLinkModal.description2")}`,
        `${vm.$t("copyLinkModal.description3")}`,
      ]
    },
    refLink() {
      return `https://${window.location.host}/r/${this.getCurrentUser.name}`
    },
    idRefLink() {
      return `https://${window.location.host}/r/`
    },
  },
  mounted() {
    this.user = this.getCurrentUser
  },
  methods: {
    ...mapMutations('ui', ['setIsCopyLinkModal','setIsCopyLinkWindow',]),
    dataShowFunc() {
      if (this.isCopyLinkWindow.data) {
        this.setIsCopyLinkWindow({data: false})
      } else {
        this.setIsCopyLinkWindow({data: true})
      }
    },
    closeServiceModal() {
      this.setIsCopyLinkModal(false)
    },
    downloadModall() {
      this.setIsCopyLinkWindow({visible: true})
    },

    handleClipboardCopy() {
      if (!this.getCurrentUser) return
      copyToClipboard(this.refLink)
        .then(() => {
          this.$toast.success(`${this.refLink} ${this.$t('account.copied')}`)
        })
        .catch(() => {
          this.$toast.error(`${this.$t('account.copyError')}`)
        })
    },
    handleClipboardCopyDescription(i) {
      copyToClipboard(this.descriptionText[i])
        .then(() => {
          this.$toast.success(`${this.$t('copyLinkModal.copied')}`)
        })
        .catch(() => {
          this.$toast.error(`${this.$t('copyLinkModal.copyError')}`)
        })
    }


  },
  filters: {
    formatMonthYearDifference(targetDate) {
      const currentDate = moment();
      const targetMoment = moment(targetDate);

      const diffInMonths = -targetMoment.diff(currentDate, 'months');
      if (diffInMonths < 1) {
        return 'less than a month';
      } else if (diffInMonths >= 1 && diffInMonths <= 12) {
        return `${diffInMonths} month`;
      } else {
        const years = Math.floor(diffInMonths / 12);
        const months = diffInMonths % 12;
        const yearString = years > 0 ? `${years} year` : '';
        const monthString = months > 0 ? `${months} month` : '';
        return `${yearString}${yearString && monthString ? ' and ' : ''}${monthString}`;
      }
    },
  },
}
</script>
<style lang="scss">
@import "~@/assets/scss/mixins";

.copy-link-modal {
  position: relative;
  margin: 0 auto;
  width: calc(100% - 32px);
  max-width: 540px;
  height: auto;
  padding: 0;
  border-radius: var(--indent-12);
  background: linear-gradient(180deg, rgba(237, 138, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 70%), #000;
  overflow: hidden;

  .close-item {
    @include flex-center-center;
    width: 40px;
    height: 40px;
    position: absolute;
    top: var(--indent-8);
    right: var(--indent-8);
    z-index: 2;

    svg path {
      transition: .3s ease;
    }

    &:hover {
      svg path {
        fill-opacity: 1;
      }
    }
  }

  .modal-content {
    @include flex-column;
    align-items: center;
    position: relative;
    max-height: calc(100vh - 32px);
    padding: 40px;
    overflow: auto;
    z-index: 1;

    .info {
      @include flex-column;
      align-items: center;
      justify-content: center;
      width: 100%;

      .avatar {
        @include flex-center-center;
        width: 64px;
        height: 64px;
        margin-bottom: var(--indent-8);
        border: var(--indent-4) solid var(--color-white);
        border-radius: var(--radius-50);
        background: var(--color-white);
        overflow: hidden;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        @include _560 {
          width: 48px;
          height: 48px;
        }
      }

      .user-name {
        margin-bottom: var(--indent-16);
        color: var(--color-white);
        text-align: center;
        font-weight: var(--font-500);
        @include font-16;

        @include _560 {
          margin-bottom: var(--indent-12);
        }
      }

      .user-stats {
        display: flex;
        width: 100%;
        margin-bottom: var(--indent-16);
        padding: var(--indent-8) 0;
        border-radius: var(--indent-8);
        background: var(--color-white-5);
        text-align: center;

        .item {
          @include flex-column;
          align-items: center;
          justify-content: center;
          flex: 1;

          .title {
            color: var(--color-white-40);
            font-weight: var(--font-400);
            @include font-12;
            text-transform: uppercase;
          }

          .amount {
            color: var(--color-white);
            font-weight: var(--font-500);
            @include font-16;

            @include _560 {
              @include font-14;
            }
          }
        }

        .item + .item {
          position: relative;

          &:before {
            position: absolute;
            left: 0;
            width: 1px;
            height: var(--indent-32);
            background: var(--color-white-40);
            content: '';
          }
        }

        @include _560 {
          margin-bottom: var(--indent-12);
        }
      }

      .switch-info {
        height: 32px;
        padding: var(--indent-8) var(--indent-16);
        border-radius: var(--indent-16);
        transition: .3s ease;
        font-weight: var(--font-500);
        @include font-12;

        &.show {
          background: #EB7104;
          color: var(--color-white);

          &:hover {
            background: #EB5704;
          }

          &:focus {
            background: #EB7104;
            outline: 1px solid #64D2FF;
          }
        }

        &.hide {
          background: var(--color-white-5);
          color: var(--color-white-40);

          &:hover {
            color: var(--color-white);
            background: var(--color-white-10);
          }

          &:focus {
            outline: 1px solid #64D2FF;
            background: var(--color-white-10);
            color: var(--color-white);
          }
        }

        @include _1024 {
          display: none;
        }
      }
    }

    .qr-title {
      margin: var(--indent-32) 0 var(--indent-8);
      color: var(--color-white);
      text-align: center;
      font-weight: var(--font-600);
      font-size: var(--indent-20);
      line-height: var(--line-24);
      letter-spacing: -0.08px;

      @include _560 {
        margin: 57px 0 var(--indent-8);
        @include font-16;
      }
    }

    .qr-code {
      @include flex-center-center;
      position: relative;
      margin-bottom: var(--indent-8);

      & > div {
        @include flex-center-center;
        width: 224px;
        height: 224px;
        padding: var(--indent-14);

        canvas {
          width: 100%!important;
          height: 100%!important;
        }

        @include _560 {
          width: 180px;
          height: 180px;
          padding: var(--indent-12);
        }
      }

      //.qr-logo {
      //  @include flex-center-center;
      //  position: absolute;
      //
      //  @include _560 {
      //    width: 41px;
      //    height: 41px;
      //
      //    svg {
      //      width: 100%;
      //    }
      //  }
      //}
    }

    .user-link {
      color: var(--color-white-40);
      font-weight: var(--font-500);
      @include font-16;

      span {
        color: var(--color-white);
      }

      @include _560 {
        @include font-14;
      }
    }

    .group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      gap: var(--indent-16);
      margin-top: var(--indent-32);

      .copy {
        width: 100%;
        padding: var(--indent-12) var(--indent-16);
        border-radius: var(--indent-8);
        background: #EB7104;
        transition: .3s ease;
        font-weight: var(--font-500);
        @include font-15;

        &:hover {
          background: #EB5704;
        }

        &:focus {
          background: #EB7104;
          outline: 1px solid #64D2FF;
        }

        @media (max-width: 400px) {
          padding: var(--indent-8) 0;
          @include font-12;
        }
      }

      .download {
        width: 100%;
        padding: var(--indent-12) var(--indent-16);
        border-radius: var(--indent-8);
        background: var(--color-white-5);
        transition: .3s ease;
        text-align: center;
        color: var(--color-white-40);
        font-weight: var(--font-500);
        @include font-15;

        &:hover {
          color: var(--color-white);
          background: var(--color-white-10);
        }

        &:focus {
          outline: 1px solid #64D2FF;
          background: var(--color-white-10);
          color: var(--color-white);
        }

        @include _1024 {
          display: none;
        }

        @include _560 {
          padding: var(--indent-8) 0;
        }

        @media (max-width: 400px) {
          @include font-12;
        }
      }

      @include _1024 {
        display: flex;
      }

      @include _560 {
        margin-top: 57px;
      }
    }

    .description {
      margin-top: var(--indent-24);

      .title {
        margin-bottom: var(--indent-12);
        padding: 0 var(--indent-12);
        color: var(--color-white-40);
        font-weight: var(--font-400);
        @include font-12;
        text-transform: uppercase;
      }

      .list {
        padding-left: var(--indent-16);
        border-radius: var(--indent-8);
        background: var(--color-black-850);

        .item {
          @include flex-center;
          gap: var(--indent-16);
          padding: var(--indent-12) var(--indent-16) var(--indent-12) 0;
          font-weight: var(--font-400);
          @include font-14;

          .ico {
            @include flex-center-center;
            min-width: var(--indent-24);
            height: var(--indent-24);

            svg path {
              transition: .3s ease;
            }

            &:hover {
              svg path {
                fill-opacity: 1;
              }
            }
          }
        }

        .item + .item {
          border-top: var(--border-main);
        }
      }
    }

    @include _560 {
      min-height: 606px;
      padding: var(--indent-16);
    }
  }

  &.closeModalService {
    left: 0;
    right: 0 !important;
    margin: 0 auto;
  }
}
</style>
