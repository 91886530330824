import { vm } from "@/main.js";
export const mapApiError = (error) => {
    if (!error) {
        return {
            data: `${vm.$t("api.helpers.connectionError")}`,
            message: `${vm.$t("api.helpers.CorsOrServer")}`,
            code: 401,
        }
    }

    let data = `${vm.$t("api.helpers.error")}`
    let message = `${vm.$t("api.helpers.unknownError")}`

    if (error.response) {
        // сервер ответил
        data = error.response.data
        message = Array.isArray(data.message) ? data.message[0] : data.message || `${vm.$t("api.helpers.unknown")}`
    } else {
        // сервер вернул пустой body
        switch (error.status) {
            case 500:
                data = `${vm.$t("api.helpers.serverError")}`
                message = `${vm.$t("api.helpers.serverErrorResponse")}`
                break

            case 404:
                data = `${vm.$t("api.helpers.notFound")}`
                message = `${vm.$t("api.helpers.notFoundOnServer")}`
                break

            default:
                break
        }
    }

    return {
        data,
        message,
        code: error.status || (error.response && error.response.status),
    }
}

export const mapData = (data) => {
    if (data.data) {
        // json responce
        if (data.meta) {
            return data
        }
        return data.data
    } else {
        // true/false responce
        return data
    }
}

export const handleBooleanResponce = (data, errorText) => {
    const mData = mapData(data)

    if (mData === 1) {
        return [null, true]
    }

    return [{ message: errorText || `${vm.$t("api.helpers.notFound")}` }, false]
}
