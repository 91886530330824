<template>
    <button
        :class="['btn', `btn-${type}`, `btn--${size}`, isDisabled && 'btn-primary-disabled']"
        :style="{color: textColor}"
        v-bind="$attrs"
        v-on="$listeners"
        :disabled="isDisabled"
    >
        {{ text }}
    </button>
</template>

<script>
export default {
    props: {
        text: String,
        type: {
            type: String,
            default: "primary",
        },
        size: {
            type: String,
            default: "lg",
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        textColor: {
            type: String,
            default: ''
        }
    },
    computed: {
        isBlock() {
            if (this.$attrs.block !== undefined) {
                return "block"
            }
            return ""
        },
    },
}
</script>

<style lang="scss">
.btn {
    &-primary {
        background-color: var(--color-orange-800);
        color: var(--color-cinder);
        transition: background-color 0.25s ease-in-out;
        &-disabled {
            pointer-events: none;
            background-color: #c5c5c5;
        }
        &:hover {
            background-color: var(--color-orange-900);
        }
    }
    &-secondary {
        background-color: var(--color-white-10);
        color: var(--color-yellow);
        transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
        border: 1px solid var(--color-yellow);
        &.disabled {
            pointer-events: none;
            border: 1px solid var(--color-silver);
            color: var(--color-silver);
        }
        &:hover {
            background-color: var(--color-yellow);
            color: var(--color-cinder);
        }
    }

    &--lg {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding: 14px 14px 13px;
        border-radius: var(--second-offset);
    }
    &--md {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding: 14px 14px 13px;
        border-radius: var(--second-offset);
    }
    &--sm {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding: 6px 14px;
        border-radius: var(--second-offset);
    }
    &--em {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        padding: 5px;
        border-radius: 10px;
    }
    &.block {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
</style>
