<template>
    <div class="loader" :class="[theme]">
        <BeatLoader :loading="loading" :color="color" :size="size" />
    </div>
</template>

<script>
import BeatLoader from "vue-spinner/src/BeatLoader.vue"

export default {
    name: "Loader",
    components: {
        BeatLoader,
    },
    props: {
        color: {
            type: String,
            default: "#FEDD00",
        },
        size: {
            type: String,
            default: "12px",
        },
        loading: {
            type: Boolean,
            default: false,
        },
        theme: {
            type: String,
            default: "inline",
            validator: (theme) => ["inline", "block", "btn"].includes(theme),
        },
    },
}
</script>

<style lang="scss" scoped>
.loader {
    &.block {
        display: flex;
        justify-content: center;
        margin: 24px 0;
    }
    &.btn {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 24px;
    }
}

.v-spinner {
    display: flex;
    font-size: 0;
}
</style>
