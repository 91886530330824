import Vue from "vue"
import Vuex from "vuex"
import crypto from "./modules/cryptoModule/crypto"
import user from "./modules/user"
import ticket from "./modules/ticket"
import ui from "./modules/ui"
import post from "./modules/post"
import category from "./modules/category"
import loader from "./modules/loader"
import messenger from "./modules/messenger"
import testimonials from "./modules/testimonial"
import social from "./modules/social"
import userPosts from '@/store/modules/userPosts'
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        crypto,
        user,
        ticket,
        ui,
        post,
        category,
        loader,
        testimonials,
        messenger,
        social,
        userPosts
    }
})
