<template>
<!--    <component :is="dynamicComponent" role="img" class="SvgVue" />-->
  <img svg-inline svg-sprite :alt="icon" :src="`/assets/icons/${preparedIcon}.svg`" class="SvgVue" />
</template>

<script>
// import { defineAsyncComponent } from "vue"

export default {
    name: "SvgVue",
    props: ["icon"],
    components: {},
    computed: {
      preparedIcon() {
        return this.icon.replaceAll(".", "_");
      }
        // dynamicComponent() {
        //     return defineAsyncComponent(() => import(`../../assets/icons/${this.icon}.svg`))
        // }
        // async dynamicComponent() {
        //   return await import(`/src/assets/icons/${this.icon.replaceAll(".", "/")}.svg`)
        // }
    }
}
</script>
