export default {
    state: {
        connectionModal: {
            visible: false,
            type: "connect",
        },
        searchModal: {
            visible: false,
            type: "modal",
        },
        activationModal: {
            visible: false,
            type: "",
            level: 0,
        },
        cancelModal: {
            visible: false,
        },
        cancelSuccessModal: {
            visible: false,
        },
        termsModal: {
            visible: false,
        },
        isSideBarClose: false,
        isCopyLinkModal: false,
        isCopyLinkWindow: {
            visible: false,
            data: true
        },
        isOpenModal: false,
        isHackedWalletModal: false
    },
    getters: {
        getIsHackedWalletModal(state) {
            return state.isHackedWalletModal
        },
        connectionModal(state) {
            return state.connectionModal
        },
        connectionModalVisible(state) {
            return state.connectionModal.visible
        },

        searchModal(state) {
            return state.searchModal
        },

        activationModal(state) {
            return state.activationModal
        },
        activationModalVisible(state) {
            return state.activationModal.visible
        },

        cancelModal(state) {
            return state.cancelModal
        },
        cancelSuccessModal(state) {
            return state.cancelSuccessModal
        },
        termsModal(state) {
            return state.termsModal
        },
        isSideBarClose(state) {
            return state.isSideBarClose
        },
        isCopyLinkModal(state) {
            return state.isCopyLinkModal
        },
        isCopyLinkWindow(state) {
            return state.isCopyLinkWindow
        },
        isOpenModal(state) {
            return state.isOpenModal
        }
    },

    mutations: {
        setIsHackedWalletModal(state, bool) {
            state.isHackedWalletModal = bool
        },
        setOpenModal(state, bool) {
            state.isOpenModal = bool
        },
        setIsSideBarClose(state, bool) {
            state.isSideBarClose = bool
        },
        setIsCopyLinkModal(state, bool) {
            state.isCopyLinkModal = bool
        },
        setIsCopyLinkWindow(state, payload) {
            state.isCopyLinkWindow = {
                ...state.isCopyLinkWindow,
                ...payload,
            }
        },
        setConnectionModal(state, payload) {
            state.connectionModal = {
                ...state.connectionModal,
                ...payload,
            }
        },
        setActivationModal(state, payload) {
            state.activationModal = {
                ...state.activationModal,
                ...payload,
            }
        },
        setSearchModal(state, payload) {
            state.searchModal = {
                ...state.activationModal,
                ...payload,
            }
        },
        setCancelModal(state, payload) {
            state.cancelModal = {
                ...state.cancelModal,
                ...payload,
            }
        },
        setCancelSuccessModal(state, payload) {
            state.cancelSuccessModal = {
                ...state.cancelSuccessModal,
                ...payload,
            }
        },
        setTermsModal(state, payload) {
            state.termsModal = {
                ...state.termsModal,
                ...payload,
            }
        },
    },
    actions: {},

    namespaced: true,
}
