<template>
  <div v-if='loader' class="modal" id="transferModal" @click.stop :class='!isModal && "closeModalService"'>
    <div class='transferLoader'>
      <UiLoader  theme="block" :loading="true" />
      <p>{{ $t('dashboardInfo.modalWallet.transfer.waitTransfer') }}</p>
    </div>
  </div>
  <div v-else class="modal" id="transferModal" @click.stop :class='!isModal && "closeModalService"'>
    <button class="btn-close">
      <BaseIcon name='closeServiceIcon' @event='closeServiceModal' ver-align />
    </button>
    <div class="modal-header">
      <button class="modal-back" @click.stop='backServiceModal'>
        <BaseIcon name='pagArrowLeft' @event='backServiceModal' ver-align />
        {{ $t('matrix.back') }}
      </button>
      <div class="modal-header__title">
        {{ $t('dashboardInfo.modalWallet.transfer.title') }}
      </div>
    </div>
    <div class="modal-content">
      <div class="modal-transfer" v-if='isDesktop'>
        <lottie-animation
            v-if='isModal && !isReverse'
            path="./assets/files/data.json"
            :auto-play='isModal'
            :loop="false"
            :speed="1"
            :width="280"
            :height="animateHeight"
        />
        <lottie-animation
          v-else-if='isModal && isReverse'
          path="./assets/files/data-reverse.json"
          :auto-play='isModal'
          :loop="false"
          :speed="1"
          :width="280"
          :height="animateHeight"
        />
      </div>
      <div class="form-group swap">
        <div class="form-control">
          <label for="from">{{ $t('dashboardInfo.modalWallet.from') }}</label>
          <input type="text" id="from" :value="from" disabled>
        </div>
        <div class="form-control">
          <label for="to">{{ $t('dashboardInfo.modalWallet.to') }}</label>
          <input type="text" id="to" :value="to" disabled>
        </div>
        <button class="wallet__swap-btn" @click='changeFrom'>
          <span class="animated__arrows up" @click='changeFrom'>
            <BaseIcon name='swapArrow' @event='changeFrom' />
            <BaseIcon name='swapArrow' @event='changeFrom' />
          </span>
          <span class="animated__arrows down" @click='changeFrom'>
            <BaseIcon name='swapArrow' @event='changeFrom' />
            <BaseIcon name='swapArrow' @event='changeFrom' />
          </span>
        </button>
      </div>
      <div class="form-group">
        <label for="amount">{{ $t('dashboardInfo.modalWallet.number') }}</label>
        <input type="number" id="amount" v-model='daiAmount' placeholder='0'>
        <label for="amount">
          {{$t('dashboardInfo.modalWallet.balance')}}:
          <span>{{ shortActualDai(actualDai) }}</span>
        </label>
        <div class="deposit-token">
          <button class='maxValue' @click.stop='addMaxValue'>{{ $t('dashboardInfo.modalWallet.max') }}</button>
          <BaseIcon name='dai' />
          DAI
          <button class="change-token">
            <BaseIcon name='pagArrowRight' ver-align />
          </button>
        </div>
      </div>
      <div class="wallet__btn-group">
        <button class="transfer__btn" @click.stop='transferToken' :disabled='disabled' :class='disabled && "disabledTransfer"'>
          {{ $t('dashboardInfo.modalWallet.transfer.transferBtn') }}
        </button>
      </div>
    </div>
    <SuccessTransfer
      :isModal='successTransfer'
      @done='successTransfer = false'
      :daiAmount='Number(daiAmount)'
      :from='from'
    />
  </div>
</template>
<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import { mapActions, mapGetters } from 'vuex'
import SuccessTransfer from '@/components/Layout/partials/SuccessTransfer.vue'

export default {
  props: {
    isModal: {
      type: Boolean
    },
    daiPropsName: {
      type: String
    },
    daiInnerOuter: {
      type: Object
    }
  },
  data() {
    return {
      from: 'Wallet',
      to: 'Inner balance',
      daiAmount: null,
      isReverse: false,
      successTransfer: false,
      loader: false,
      animateHeight: 280,
      disabled: true
    }
  },
  components: {
    SuccessTransfer,
    BaseIcon,
    LottieAnimation
  },
  mounted() {
    if (this.daiPropsName === 'inner') {
      this.from = 'Inner balance'
      this.to = 'Wallet'
      this.isReverse = true
    } else {
      this.from = 'Wallet'
      this.to = 'Inner balance'
      this.isReverse = false
    }
    if (window.innerHeight < 750) {
      this.animateHeight = 240
    }
  },
  watch: {
    daiAmount(oldVal, newVal) {
      if (oldVal !== newVal) {
        if (+this.daiAmount > 0) {
          this.disabled = false
        }
      }
    },
    daiPropsName(oldVal, newVal) {
      if (this.daiPropsName === 'inner') {
        this.from = 'Inner balance'
        this.to = 'Wallet'
        this.isReverse = true
      } else {
        this.from = 'Wallet'
        this.to = 'Inner balance'
        this.isReverse = false
      }
    },
  },
  computed: {
    ...mapGetters('user', ['getInnerBlocked']),
    isDesktop() {
      return window.innerWidth > 768
    },
    actualDai() {
      if (this.from === 'Wallet') {
        return this.daiInnerOuter.dai
      } else {
        return this.daiInnerOuter.daiInnerBalance
      }
    }

  },
  methods: {
    ...mapActions('crypto', ['transferTokenFromInner', 'transferTokenFromWallet', 'setTransactionDaiHistory']),

    async transferToken() {
      if (this.daiAmount === null || +this.daiAmount === 0)  return this.$swal('empty value!')
      this.loader = true
      if (!this.validateNumberWithMaxDecimalPlaces(Number(this.daiAmount))) {
        this.$toast.error('Number has more than 2 decimal places.')
        this.loader = false
        return
      }
      if (this.from === 'Wallet') {
        const [data, err] = await this.transferTokenFromWallet(this.shortActualDai(this.daiAmount))
        if (data === true) {
          this.loader = false
          this.successTransfer = true
          await this.setTransactionDaiHistory({
            title: 'Transfer to inner balance',
            amount: +this.daiAmount,
            amount_type: 'DAI',
            status: 'Successfully',
            type: 'wallet'
          })
        }
        if (err) {
          this.loader = false
          this.$swal(err.message)
          this.daiAmount = null
        }
      } else {
        if (this.getInnerBlocked) {
          this.$swal('Token transfer is not possible due to internal wallet blocking')
          return
        }
        const [data, err] =  await this.transferTokenFromInner(this.shortActualDai(this.daiAmount))
        if (data === true) {
          this.loader = false
          this.successTransfer = true
          await this.setTransactionDaiHistory({
            title: 'Transfer to wallet',
            amount: +this.daiAmount,
            amount_type: 'DAI',
            status: 'Successfully',
            type: 'inner'
          })
        }
        if (err) {
          if (this.getInnerBlocked) {
            this.$swal('Token transfer is not possible due to internal wallet blocking')
            this.loader = false
            this.daiAmount = null
          } else {
            this.loader = false
            this.$swal(err.message)
            this.daiAmount = null
          }
        }
      }
    },
    validateNumberWithMaxDecimalPlaces(number) {
      const decimalPlaces = (number.toString().split('.')[1] || '').length;
      return decimalPlaces <= 2;
    },
    shortActualDai(value) {
      const number = Number(value)
      if (Number.isInteger(number)) {
        return number.toFixed(1);
      } else {
        return (Math.floor(number * 100) / 100).toFixed(2).toString();
      }
    },
    addMaxValue() {
      this.daiAmount = this.shortActualDai(this.actualDai)
    },

    backServiceModal() {
      this.$emit('transferEmit')
    },
    closeServiceModal() {
      this.$emit('closeTransfer')
    },

    changeFrom() {
      let from = this.from
      this.from = this.to
      this.to = from
      this.isReverse = !this.isReverse
    }
  }
}
</script>
<style lang='scss'>
.transferLoader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.disabledTransfer {
  opacity: .6 !important;
}
</style>