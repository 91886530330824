import { followService, getFollowersService, getFollowingService, unFollowService, unLinkService } from '@/api/social'

export default {
  state: {
    following: [],
    followers: [],
    guestFollowing: [],
    guestFollowers: [],
    loading: false,
    followDataSaved: false,
    followersDataSaved: false,
    guestFollowDataSaved: false,
    guestFollowersDataSaved: false
  },
  getters: {
    getFollowDataSaved(state) {
      return state.followDataSaved
    },
    getFollowersDataSaved(state) {
      return state.followersDataSaved
    },
    getGuestFollowDataSaved(state) {
      return state.guestFollowDataSaved
    },
    getGuestFollowersDataSaved(state) {
      return state.guestFollowersDataSaved
    },
    getLoading(state) {
      return state.loading
    },
    getFollowing(state) {
      return state.following
    },
    getFollowers(state) {
      return state.followers
    },
    getGuestFollowing(state) {
      return state.guestFollowing
    },
    getGuestFollowers(state) {
      return state.guestFollowers
    },
  },
  mutations: {
    addGuestFollowers(state, user) {
      state.guestFollowers.unshift(user)
    },
    decreaseGuestFollowers(state, user) {
      state.guestFollowers = state.guestFollowers.filter(item => item.id !== user.id)
    },
    setFollowDataSaved(state, bool) {
      state.followDataSaved = bool
    },
    setFollowersDataSaved(state, bool) {
      state.followersDataSaved = bool
    },
    setGuestFollowDataSaved(state, bool) {
      state.guestFollowDataSaved = bool
    },
    setGuestFollowersDataSaved(state, bool) {
      state.guestFollowersDataSaved = bool
    },
    setLoading(state, bool) {
      state.loading = bool
    },
    setFollowing(state, following) {
      state.following = following
    },
    setFollowers(state, followers) {
      state.followers = followers
    },
    setUnfollow(state, id) {
      state.following = state.following.filter(user => user.id !== id)
    },
    setUnLinkAction(state, id) {
      state.followers = state.followers.filter(user => user.id !== id)
    },
    setFollow(state, user) {
      state.following.unshift(user)
    },
    setGuestFollowing(state, following) {
      state.guestFollowing = following
    },
    setGuestFollowers(state, followers) {
      state.guestFollowers = followers
    },
    clearGuestData(state) {
      state.guestFollowing = []
      state.guestFollowers = []
      state.guestFollowDataSaved = false
    }
  },
  actions: {
    async clearGuestAction({commit}) {
      commit('clearGuestData')
    },
    async getFollowingAction({ commit }, payload) {
      const [err, result] = await getFollowingService(payload)
      if (err) throw err
      if (payload) {
        commit('setGuestFollowing', result)
      } else {
        commit('setFollowing', result)
      }
    },
    async getFollowersAction({ commit }, payload) {
      const [err, result] = await getFollowersService(payload)
      if (err) throw err
      if (payload) {
        commit('setGuestFollowers', result)
      } else {
        commit('setFollowers', result)
      }
    },
    async unFollowAction({commit}, id) {
      commit('setUnfollow', id)
      const [err, result] = await unFollowService({
        user_id: id
      })
      if (err) throw err
    },
    async unLinkAction({commit}, id) {
      commit('setUnLinkAction', id)
      const [err, result] = await unLinkService({
        user_id: id
      })
      if (err) throw err
    },
    async followAction({commit}, user) {
      const [err, result] = await followService({
        user_id: user.id
      })
      if (err) throw err
      commit('setFollow', user)
    }
  },
  namespaced: true,
}