<template>
    <div class="notifications" @click.stop :class='!isNoticeMessage && "closeModalService"'>
      <div class="dropdown" >
        <div class="title">
          <div class='title-header'>
            <span
              class='title-text'
            >{{ $t('dashboardInfo.notification.title') }} {{getUnreadNotifications > 0 ? '(' + getUnreadNotifications + ')' : ''}}
            </span>
            <BaseIcon name="closeServiceIcon" @event='closeNotificationModal' verAlign />
          </div>
          <div class='title-buttons'>
            <div class='title-buttons__group'>
              <button
                class="notificationBtn"
                :class="currentGroup !== 'All' && 'noticeDisabled'"
                @click.stop="selectGroup('All')"
              >
                {{ $t('dashboardInfo.notification.all') }}
              </button>
              <button
                class="notificationBtn"
                :class="currentGroup === 'All' && 'noticeDisabled'"
                @click.stop="selectGroup('Following')"
              >
                {{ $t('dashboardInfo.userWidget.following') }}
              </button>
            </div>
            <button
              class="notificationBtn"
              :class="getUnreadNotifications === 0 && 'noticeDisabled'"
              @click.stop="readAllMessages"
              :disabled='getUnreadNotifications === 0'
            >
              {{ $t('dashboardInfo.notification.markRead') }}
            </button>
          </div>
        </div>

        <div class="notifications-list" v-if='getNotifications.length'>
          <div
            v-for="item in getActualNotices"
            :key="item.id"
            class="notifications-list__item"
            :class="item.status['is_read'] === false && 'new'"
            @click.stop='openNotice(item)'
          >
            <div class="notifications-list__item-img" :style='{background: item.type === "subscribe" && "transparent"}'>
              <img v-if='item.payload' :src='item.payload.avatar' alt='avatar' @click.stop='toSearchProfile(item.payload.id)'>
              <BaseIcon v-else :name="defineIcon(item.type)" verAlign @event='openNotice(item)' />
            </div>
            <div class="notifications-list__item-caption">
              <button v-if='isShortText === item.id' class='notifications-list__item-btn' @click.stop='closeNoticeItem'>
                <BaseIcon name="closeServiceIcon" @event='closeNoticeItem' verAlign />
              </button>
              <div class="notifications-list__item-title">
                {{ localeText(item.title, item.id) }}
              </div>
              <div class="notifications-list__item-description">
                {{ localeText(item.content, item.id) }}
                <button v-if='isShortText !== item.id' class='notifications-list__item-description__btn'>
                  {{ $t('academy.readMore') }}
                </button>
              </div>
              <div class="notifications-list__item-time">
                {{ noticeTime(item['created_at']) }}
              </div>
            </div>
          </div>
<!--          <div class="historyBtn">-->
<!--            <ButtonDark :text="$t('showMore')" :classes="'historyShowActive'"/>-->
<!--          </div>-->
        </div>
        <UiLoader v-if='getNoticeLoader' theme="block" :loading="true" />
        <div v-if='!getNoticeLoader && getNotifications.length === 0' class='empty'>{{ $t('notFound.noticeNotFound') }}</div>
      </div>
    </div>
</template>
<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import { LSTORAGE } from '@/config/constants'
import { getLocalStorageElement, setLocalStorageElement } from '@/helpers/localstorage'
// import ButtonDark from '@/components/UI/ButtonDark.vue'

export default {
  props: {
    isNoticeMessage: {
      type: Boolean,
    },
  },
  components: {
    // ButtonDark,
    BaseIcon,
  },

  data() {
    return {
      notice: {},
      isShortText: null,
      currentGroup: "All"
    }
  },
  async mounted() {
    if (this.getNotifications.length === 0 && getLocalStorageElement(LSTORAGE.wallet) === 'walletconnect') {
      setTimeout(async () => {
        await this.getNotificationAction(this.getCurrentUser.id)
      }, 20000)
    }
  },
  computed: {
    ...mapGetters('user', ['getCurrentUser', 'getSearchUser']),
    ...mapGetters('ticket', [
      'getNotifications',
      'getUnreadNotifications',
      'getFollowingNotices',
      'getNoticeLoader'
    ]),

    getActualNotices() {
      if (this.currentGroup === "All") {
        return this.getNotifications
      }
      return this.getFollowingNotices
    }
  },

  methods: {
    ...mapActions('ticket', [
      'setReadNotificationAction',
      'setReadNotificationItemAction',
      'getNotificationAction'
    ]),
    ...mapActions('user', [
      'resetGuestAction',
      'getCurrentUserAction'
    ]),
    ...mapMutations('user', [
      'setSearchUser',
      'setFromProfile'
    ]),
    ...mapActions('social', ['clearGuestAction']),
    ...mapActions('userPosts', ['clearGuestPostAction']),
    ...mapMutations('social', ['setLoading']),

    closeNotificationModal(text) {
      this.$emit('closeNotificationModal', text)
      this.isShortText = null
    },

    async toSearchProfile(acc) {
      if (acc === this.getCurrentUser.id) {
        return
      }
      this.closeNotificationModal('all')
      await this.resetGuestAction()
      await this.clearGuestAction()
      await this.clearGuestPostAction()

      this.$store.commit('loader/showProcessingModal', {
        isShown: true,
        text: `Loading ${acc} profile`,
      })
      let selectUser
      try {
        selectUser = await this.getCurrentUserAction(acc)
        setLocalStorageElement('searchUser', selectUser.id)
      } catch (e) {
        if (e.code === 422) {
          this.$toast.error(`${e.data.message}`)
        }
        console.log(e)
        return
      } finally {
        this.$store.commit('loader/showProcessingModal', {
          isShown: false,
        })
      }
      this.setSearchUser(selectUser)
      this.setFromProfile(true)
      if (this.$route.name !== 'searchProfile') {
        await this.$router.push({ name: 'searchProfile', params: { name: this.getSearchUser.name } })
      } else {
        await this.$router.replace({ params: { name: this.getSearchUser.name } })
      }

    },

    selectGroup(name) {
      this.currentGroup = name
    },
    defineIcon(priority) {
      switch (priority) {
        case 'general':
          return 'notificationDefault'

        case 'auth':
          return 'notificationImportant'

        case 'academy':
          return 'notificationUpdates'

        case 'news':
          return 'notificationAttention'

        default:
          return 'notificationDefault'
      }
    },
    closeNoticeItem() {
      this.isShortText = null
    },
    openNotice(notice) {
      this.isShortText = notice.id
      if (notice.status['is_read'] !== true) {
        this.setReadNotificationItemAction({id: notice.id, userId: this.getCurrentUser.id})
      }
    },
    readAllMessages() {
      this.setReadNotificationAction()
    },
    shortText(text, id) {
      if (this.isShortText !== id && text) {
        if (text.length > 65) {
          return `${text.substr(0, 65)}`
        }
      }
      return text
    },
    localeText(text, id) {
      if(Object.keys(text).includes(this.$i18n.locale)){
        return this.shortText(text[this.$i18n.locale], id)
      } else {
        return this.shortText(text.en, id)
      }
    },
    noticeTime(time){
      const currentTime = moment().utc();
      const createdTime = moment.utc(time);
      const duration = moment.duration(currentTime.diff(createdTime));

      let timeElapsed = '';
      const ago = `${this.$t('dashboardInfo.notification.timeAgo')}`
      if (duration.asSeconds() < 60) {
        timeElapsed = `${Math.floor(duration.asSeconds())}s ${ago}`;
      } else if (duration.asMinutes() < 60) {
        timeElapsed = `${Math.floor(duration.asMinutes())}m ${ago}`;
      } else if (duration.asHours() < 24) {
        timeElapsed = `${Math.floor(duration.asHours())}h ${ago}`;
      } else {
        timeElapsed = `${Math.floor(duration.asDays())}d ${ago}`;
      }

      return timeElapsed;
    }
  },
}
</script>
<style lang='scss' scoped>
.empty {
  color: var(--color-white-50);
  position: relative;
  top: 40%;
  left: 26%;
}
</style>
