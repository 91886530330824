import {
  allUsersInfoService,
  getCurrentUserService,
  getParentsCountService,
  getTransactionsService,
  getUserByFieldService,
  getUserByIdService,
  registerAccountService, searchUsersService,
  sendTransactionService,
  setLevelService,
  privacy,
  privacyUpdate,
} from '@/api/user'
import { getClassicPriceByLevel, getClassicTypeByLevel, programArray } from '@/helpers/matrix'
import { isForceId, isHashAddress } from '@/helpers/user'
import { getLocalStorageElement, setLocalStorageElement } from '@/helpers/localstorage'
import nftsApi from '@/api/nftsApi'

export default {
  state: {
    innerBlocked: false,
    guestMode: false,
    isProfile: false,
    fromProfile: false,
    allSearchUsers: [],
    searchUser: null,
    guestTotalTactile: null,
    guestClassicMatrix: [],
    guestNft: null,
    guestUvLevel: null,
    guestUserHistory: {},
    usersTotal: {
      page: 1,
      total: 0
    },
    totalTactile: null,
    currentUser: null,
    currentParent: null,
    classicMatrix: [],
    nft: null,
    uvLevel: null,
    boostMatrix: [],
    allUsersInfo: {},
    initDone: false,
    userHistory: {},
    referral: [],
    addedReferral: false,
    forceCoinCurrency: 0,
    users: null,
    privacySettings: [],
    checkedRegister: false,
    privacyLoad: false,
    hackedWalletLoad: false,
    lastInitDone: false
  },
  getters: {
    getInnerBlocked(state) {
      return state.innerBlocked
    },
    getLastInitDone(state) {
      return state.lastInitDone
    },
    getFromProfile(state) {
      return state.fromProfile
    },
    getHackedWalletLoad(state) {
      return state.hackedWalletLoad
    },
    getPrivacyLoad(state) {
      return state.privacyLoad
    },
    getCheckedRegister(state) {
      return state.checkedRegister
    },
    getTotalTactilePartners(state) {
      if (state.totalTactile !== null) {
        return state.totalTactile.reduce((acc, val) => {
          if (val) {
            return acc + Number(val.totalPartners)
          }
        }, 0)
      }
      return 0
    },
    getTotalTactileCycles(state) {
      if (state.totalTactile !== null) {
        return state.totalTactile.reduce((acc, val) => {
          if (val) {
            return acc + Number(val.slot)
          }
        }, 0)
      }
      return 0
    },
    getGuestTotalTactilePartners(state) {
      if (state.guestTotalTactile !== null) {
        return state.guestTotalTactile.reduce((acc, val) => {
          if (val) {
            return acc + Number(val.totalPartners)
          }
        }, 0)
      }
      return 0
    },
    getGuestTotalTactileCycles(state) {
      if (state.guestTotalTactile !== null) {
        return state.guestTotalTactile.reduce((acc, val) => {
          if (val) {
            return acc + Number(val.slot)
          }
        }, 0)
      }
      return 0
    },
    getGuestMode(state) {
      return state.guestMode
    },
    getGuestClassicMatrix(state) {
      return state.guestClassicMatrix
    },
    getIsProfile(state) {
      return state.isProfile
    },
    getUsersPage(state) {
      return state.usersTotal
    },
    getAllSearchUsers(state) {
      return state.allSearchUsers
    },
    getSearchUser(state) {
      return state.searchUser
    },
    getCurrentUser(state) {
      return state.currentUser
    },
    getClassicMatrix(state) {
      return state.classicMatrix
    },
    getAddedReferral(state) {
      return state.addedReferral
    },
    getNft(state) {
      return state.nft
    },
    getGuestNft(state) {
      return state.guestNft
    },
    getUserHistory(state) {
      return state.userHistory
    },
    getGuestUserHistory(state) {
      return state.guestUserHistory
    },
    getCurrentParent(state) {
      return state.currentParent || getLocalStorageElement('currentParent') || null
    },
    getInitDone(state) {
      return state.initDone
    },
    getLastActive(state) {
      if (state.classicMatrix && state.classicMatrix.length > 0) {
        const lastActive = state.classicMatrix.findLast((x) => x.active)
        return lastActive || null
      }
      return null
    },
    getGuestLastActive(state) {
      if (state.guestClassicMatrix && state.guestClassicMatrix.length > 0) {
        const lastActive = state.guestClassicMatrix.findLast((x) => x.active)
        return lastActive || null
      }
      return null
    },
    previousBuyLevel(state, getters) {
      // find previous active program, to indicate autoupgrade buttons
      if (getters.getLastActive === null) return 0

      return getters.getLastActive.lvl
    },
    currentBuyLevel(state, getters) {
      // find last active program, add +1 to indicate next possible buy
      if (getters.getLastActive === null) return 0

      return getters.getLastActive.lvl + 1
    },
    totalActiveLevels(state) {
      let returnable = 0
      if (state.classicMatrix && state.classicMatrix.length) {
        return state.classicMatrix.reduce(
          (acc, x) => (x.active ? acc + 1 : acc),
          0
        )
      }

      return returnable
    },

    gappedLevel(state) {
      if (state.classicMatrix && state.classicMatrix.length > 0) {
        const gapLevel = state.classicMatrix.find((x) => x.active === 'gap')
        return gapLevel ? gapLevel.lvl : null
      }

      return null
    },
    getForceCoinCurrency(state) {
      return state.forceCoinCurrency
    },
    getAllUsersInfoVariables(state) {
      return Object.keys(state.allUsersInfo).length || 0
    },
    getMaticCoinCurrency(state) {
      return state.allUsersInfo['matic-coin']?.value
    },
    getAllUsersInfo(state) {
      return state.allUsersInfo['all-users']?.value.replace(/\s/g, '')
    },
    getAllUsersEarned(state) {
      return state.allUsersInfo['total-money']?.value.replace(/\s/g, '')
    },
    getUsersInPerMonths(state) {
      return state.allUsersInfo['user-per-month']?.value.replace(/\s/g, '')
    },
    getRoyaltyTreasury(state) {
      return state.allUsersInfo['royalty-nft-treasury']?.value.replace(/\s/g, '')
    },
    getTotalTactileStatistic(state) {
      return state.allUsersInfo['total-tactile-cycles']?.value.replace(/\s/g, '')
    },
    getAllUsersInfoTactile() {
      // return state.allUsersInfo['all-users-tactile']?.value
      return '-'
    },
    getAllUsersEarnedTactile() {
      // return state.allUsersInfo['total-money-tactile']?.value
      return '-'
    },
    getAllUsersInfoNft() {
      // return state.allUsersInfo['all-users-nft']?.value
      return '-'
    },
    getAllUsersEarnedNft() {
      // return state.allUsersInfo['total-money-nft']?.value
      return '-'
    },
    getAllUsersStatusesNft() {
      // return state.allUsersInfo['total-status-nft']?.value
      return '-'
    },
    getAllEarnedFollowers() {
      return '-'
    },
    getAllUsers(state) {
      return state.users
    },
    getReferral(state) {
      return state.referral
    },
    getUv(state) {
      return state.uvLevel
    },
    getGuestUv(state) {
      return state.guestUvLevel
    },
    privacySettings(state) {
      return state.privacySettings
    },
  },
  mutations: {
    setInnerBlocked(state, bool) {
      state.innerBlocked = bool
    },
    setLastInitDone(state, bool) {
      state.lastInitDone = bool
    },
    setHackedWalletLoad(state) {
      state.hackedWalletLoad = true
    },
    setPrivacyLoad(state) {
      state.privacyLoad = true
    },
    setCheckedRegister(state) {
      state.checkedRegister = true
    },
    resetGuest(state) {
      state.guestTotalTactile = null
      state.searchUser = null
      state.guestUserHistory = {}
      state.guestClassicMatrix = []
      state.guestUvLevel = null
      state.guestNft = null
    },
    setTotalTactile(state, data) {
      state.totalTactile = data
    },
    setGuestTotalTactile(state, data) {
      state.guestTotalTactile = data
    },
    setGuestMode(state, bool) {
      state.guestMode = bool
    },
    setIsProfile(state, bool) {
      state.isProfile = bool
    },
    setFromProfile(state, bool) {
      state.fromProfile = bool
    },
    setSearchUser(state, user) {
      state.searchUser = user
    },
    setForceCoinCurrency(state, curr) {
      state.forceCoinCurrency = curr
    },
    setAddedReferral(state, bool) {
      state.addedReferral = bool
    },
    setNft(state, nfts) {
      state.nft = nfts
    },
    setGuestNft(state, guestNfts) {
      state.guestNft = guestNfts
    },
    setCurrentParent(state, parent) {
      state.currentParent = parent
      setLocalStorageElement('currentParent', parent)
    },
    setInitDone(state) {
      state.initDone = true
    },
    setClassicMatrix(state, obj) {
      const classic = programArray(obj)
      let cards = classic.map((x, i) => ({
        lvl: i, // fixed
        type: getClassicTypeByLevel(i), // fixed
        cost: getClassicPriceByLevel(i), // fixed
        partners: x.partners, //backend
        slots: x.slot, //backend
        active: x.setLevelactive, // contract
      }))

      state.classicMatrix = cards
    },
    setBoostMatrix(state) {
      const classic = [0, 1, 2, 3]

      let cards = classic.map((x, i) => ({
        lvl: i, // fixed
        type: getClassicTypeByLevel(i),
        cost: getClassicPriceByLevel(i),
        partners: 0,
        slots: 0,
        active: false,
      }))

      state.boostMatrix = cards
    },
    updateClassicMatrixLevels(state, arr) {
      if (arr && arr.length) {
        state.classicMatrix = arr.map((x) => {
          return {
            ...state.classicMatrix[x.lvl],
            ...x,
          }
        })
      }
    },
    updateGuestClassicMatrixLevels(state, arr) {
      if (arr && arr.length) {
        state.guestClassicMatrix = arr.map((x) => {
          return {
            ...state.guestClassicMatrix[x.lvl],
            ...x,
          }
        })
      }
    },
    setGuestClassicMatrix(state, tactile) {
      const classic = programArray(tactile)
      let cards = classic.map((x, i) => ({
        lvl: i, // fixed
        type: getClassicTypeByLevel(i), // fixed
        cost: getClassicPriceByLevel(i), // fixed
        partners: x.partners, //backend
        slots: x.slot, //backend
        active: x.setLevelactive, // contract
      }))
      state.guestClassicMatrix = cards
    },
    setClassMatrixLevel(state, { lvl, active }) {
      let matrix = state.classicMatrix
      matrix[lvl] = {
        ...matrix[lvl],
        ...{
          lvl,
          active,
        },
      }

      state.classicMatrix = matrix.map((x) => ({ ...x }))
    },
    saveUserToStore(state, res) {
      state.currentUser = res
    },
    clearUser(state) {
      state.currentUser = null
      localStorage.removeItem('currentUserLocal')
      localStorage.removeItem('currentParent')
    },
    setUsersInfoFromLocal(state) {
      state.allUsersInfo = JSON.parse(localStorage.getItem('allUsersInfo'))
    },
    setUsersInfo(state, res) {
      state.allUsersInfo = res
      // setLocalStorageElement('allUsersInfo', res)
    },
    setUserHistory(state, res) {
      state.userHistory = res
    },
    setGuestUserHistory(state, res) {
      state.guestUserHistory = res
    },
    setReferral(state, child) {
      state.referral = child
    },
    setUvLevels(state, levels) {
      state.uvLevel = levels
    },
    setGuestUvLevels(state, levels) {
      state.guestUvLevel = levels
    },
    setAllSearchUsers(state, users) {
      state.allSearchUsers = users
    },
    addAllSearchUsers(state, users) {
      state.allSearchUsers = [...state.allSearchUsers, ...users]
    },
    setUsersPage(state, page) {
      state.usersTotal.total = page.total ? page.total : state.usersTotal.total
      state.usersTotal.page = page.page ? page.page : state.usersTotal.page
    },
    setPrivacySetting(state, response) {
      state.privacySettings = response
    },
    setFollowingCount(state, payload) {
      if (payload.increment === true) {
        state.currentUser.social.following += +payload.count
      } else {
        if (+state.currentUser.social.following === 0) return
        state.currentUser.social.following -= +payload.count
      }
    },
    setFollowersCount(state, payload) {
      if (payload.increment === true) {
        state.currentUser.social.followers += +payload.count
      } else {
        if (+state.currentUser.social.followers === 0) return
        state.currentUser.social.followers -= +payload.count
      }
    },
    setGuestFollowersCount(state, payload) {
      if (payload.increment === true) {
        state.searchUser.social.followers += +payload.count
      } else {
        if (+state.searchUser.social.followers === 0) return
        state.searchUser.social.followers -= +payload.count
      }
    }
  },
  actions: {
    async getPrivacySettings({commit}) {
      const [err, result] = await privacy()
      commit('setPrivacySetting', result)

      if (err) throw err

    },
    async updatePrivacySettings({commit}, payload) {
      const [err] = await privacyUpdate(payload)

      if (err) throw err
    },
    async resetGuestAction({commit}) {
      commit('resetGuest')
    },
    async guessUser({ dispatch }, input) {
      let userResponse = null
      if (!input) {
        userResponse = await dispatch('getCurrentUserAction')
        return userResponse
      }
      if (isHashAddress(input)) {
        const { users } = await dispatch('getUserByField', { account: input })
        userResponse = users
        return userResponse
      } else if (isForceId(input)) {
        const { users } = await dispatch('getUserByField', { name: input })
        userResponse = users
        return userResponse

      } else if (Number(input)) {
        const { users } = await dispatch('getUserByField', { id: input })
        userResponse = users
        return userResponse
      }

    },
    async getCurrentUserAction({commit},id) {
      const [err, result] = await getCurrentUserService(id)
      if (err) throw err

      return result
    },
    // async getUsers({ commit}, request) {
    //   const [err, result] = await getUsersService(request)
    //   commit('setUsers', result)
    //   if (err) console.log(err)
    // },

    async getUser({ commit, dispatch }, request) {
      const [err, result] = await getUserByIdService(request)

      if (err) throw err
      return result
    },

    async getUserByField({ commit, dispatch }, request) {
      const [err, result] = await getUserByFieldService(request)

      if (err) throw err

      return result
    },

    async registerAccount({ commit, dispatch }, request) {
      const [err, result] = await registerAccountService(request)

      if (err) throw err

      return result
    },
    async getParentsCount({ commit, dispatch }, request) {
      const [err, result] = await getParentsCountService(request)

      if (err) throw err

      return result
    },
    async setLevel({ commit, dispatch }, request) {
      const [err, result] = await setLevelService(request)

      if (err) throw err

      return result
    },
    async getTransactions({ commit }, request) {
      const [err, result] = await getTransactionsService(request)

      if (err) throw err
      commit('setUserHistory', result)
      return result
    },
    async getGuestTransactions({ commit }, request) {
      const [err, result] = await getTransactionsService(request)

      if (err) console.log(err)
      commit('setGuestUserHistory', result)
      return result
    },
    async sendTransaction({ commit, dispatch }, request) {
      const [err, result] = await sendTransactionService(request)

      if (err) throw err

      return result
    },
    async saveUser({ state, commit }, obj) {
      let userLocal = localStorage.getItem('currentUserLocal')
      if (!Boolean(userLocal) || userLocal === 'undefined') {
        commit('saveUserToStore', obj)
        localStorage.setItem(
          'currentUserLocal',
          JSON.stringify(state.currentUser)
        )
      } else {
        commit('saveUserToStore', JSON.parse(userLocal))
      }
    },
    async updateLocalUser({ commit }, obj) {
      await localStorage.setItem(
        'currentUserLocal',
        JSON.stringify(obj)
      )
      commit('saveUserToStore', obj)
    },
    async getUsersInfo({ commit }) {
      const [err, result] = await allUsersInfoService()
      if (err) throw err
      commit('setUsersInfo', result)
    },
    async getNftAction({commit},user) {
      try {
        const {data} = await nftsApi.nfts({ owner: user.toLowerCase() })
        commit('setNft',data)
      } catch (err) {
        console.log('getNft err', err)
      }
    },
    async getGuestNftAction({commit},user) {
      try {
        const {data} = await nftsApi.nfts({ owner: user.toLowerCase() })
        commit('setGuestNft',data)
      } catch (err) {
        console.log('getNft err', err)
      }
    },
    async searchUsersAction({commit},payload) {
      try {
        const [err, result] = await searchUsersService(payload)
        if (err) throw err
        if (result.total === 0) throw err
        commit('setUsersPage', {
          total: result.total,
        })
        if (payload.page === 1) {
          commit('setAllSearchUsers',result.users)
        } else {
          commit('addAllSearchUsers',result.users)
        }
        return result.users
      } catch (err) {
        console.log('usersSearch', err)
        throw err
      }
    },
    async setPageAction({commit},count) {
      commit('setUsersPage', {
        page: count
      })
    }
  },
  namespaced: true,
}
