import { $axiosNewApi } from '@/plugins/Axios'
import endpoint from '@/config/api-endpoint'
import { vm } from '@/main'
import { mapApiError, mapData } from '@/api/helpers'

export const getFollowingService = async (id) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.social.following,  id && {
      params: id
    })
    if (data && (data.status === 'error' || data.users === null)) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}
export const getFollowersService = async (id) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.social.followers, id && {
      params: id
    })
    if (data && (data.status === 'error' || data.users === null)) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}
export const followService = async (id) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.social.follow, id)
    if (data && (data.status === 'error' || data.users === null)) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}
export const unFollowService = async (id) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.social.unFollow, id)
    if (data && (data.status === 'error' || data.users === null)) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}
export const unLinkService = async (id) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.social.unLink, id)
    if (data && (data.status === 'error' || data.users === null)) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}