<template>
  <div class="sidebar__bottom" v-show="isActiveWallet" :class='{isProfilePage: $route.name === "profile"}'>
<!--    <div v-if="isClosed">-->
<!--      <div class="sidebar__closedImg" >-->
<!--        <UiAvatar-->
<!--          :user="getCurrentUser"-->
<!--          alt="user logo"-->
<!--          class="sidebar__closedImg-ico"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
    <div
      v-if="!isClosed && getCurrentUser"
      class="sidebar__force-id"
      @click.stop='goToProfile'
    >
      <div class="sidebar__force-id-ico">
        <UiAvatar
          :user="getCurrentUser"
          alt="user logo"
          class="sidebar__closedImg-ico"
        />
      </div>
      <div v-if='getCurrentUser?.account' class="sidebar__force-id-text">
        {{ $t('sidebarBottom.myProfile') }}
      </div>
      <div class="sidebar__force-id-btn-inner">
        <BaseIcon name="arrowRight" />
      </div>
    </div>
    <UiLoader v-else theme="block" :loading="true" />
  </div>
</template>

<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    BaseIcon,
  },
  props: {
    isClosed: {
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters('user', ['getCurrentUser']),
    ...mapGetters('crypto', ['isActiveWallet']),
  },

  methods: {
    ...mapActions('user', ['saveUser']),
    ...mapMutations('user', ['clearUser']),
    goToProfile() {
      if (this.$route.name === 'profile') return
      this.$router.push({name: 'profile'})
    }
  },

  mounted() {
    this.saveUser()
  },
  unmounted() {
    this.clearUser()
  },
}
</script>

<style lang="scss">
.isProfilePage {
  background: var(--color-white-5);
  .sidebar__force-id-text {
    color: var(--color-white);
  }
}
.sidebar {
  &__closedImg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    width: 56px;
    height: 56px;
    margin-bottom: 15px;
    margin-left: 12px;
    &-ico {
      width: 100%;
      height: 100%;
      border-radius: var(--radius-50);
    }
  }
  &__bottom {
    font-family: 'Poppins', sans-serif;
    position: relative;
  }
  &__force-id {
    display: flex;
    align-items: center;
    position: relative;
    padding: var(--indent-16);
    background: var(--color-white-5);
    color: var(--color-black-500);
    transition: .3s ease;
    width: 100%;
    cursor: pointer;


    &:hover {
      background: var(--color-white-10);

      .sidebar__force-id-text {
        color: var(--color-white);
      }

      .sidebar__force-id-btn-inner {
        svg path {
          fill-opacity: 1;
        }
      }
    }
  }
  &__force-id-ico {
    width: 32px;
    height: 32px;
    border-radius: var(--radius-50);
    margin-right: var(--indent-8);
  }
  &__force-id-btnCopy {
    padding-top: 5px;
    margin-left: 10px;

    &:hover {
      svg path {
        fill-opacity: 1;
      }
    }
  }
  &__force-id-text {
    font-weight: var(--font-500);
    font-size: var(--font-16);
    line-height: var(--line-24);
    letter-spacing: var(--spacing-16);
    transition: .3s ease;
  }
  &__force-id-btn-inner {
    margin-top: 5px;
    margin-left: auto;
  }
  &__bottom-popup {
    min-width: 200px;
    position: absolute;
    z-index: 100;
    left: 0;
    top: -90px;
    padding: var(--indent-16);
    background: var(--color-black-800);
    border-radius: var(--indent-8);
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 30%);
    transition: all 600ms;
    @media (max-width: 991px) {
      min-width: 260px;
      right: 0;
      top: -90px;
    }
  }
  &__bottom-popupClosed {
    width: 60px;
    position: absolute;
    z-index: 100;
    left: 10px;
    top: -100px;
    padding: var(--indent-16);
    background: var(--color-black-800);
    border-radius: var(--indent-8);
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 30%);
    transition: all 600ms;
  }
  &__bottom-logout {
    display: flex;
    align-items: center;
    font-size: var(--font-14);
    line-height: var(--line-20);
    font-weight: var(--font-600);
    color: var(--color-red);
    transition: color 500ms;
    cursor: pointer;
    &:hover {
      color: var(--color-orange-900);
    }
  }
  &__bottom-search {
    display: flex;
    align-items: center;
    font-size: var(--font-14);
    line-height: var(--line-20);
    margin-bottom: var(--indent-8);
    font-weight: var(--font-600);
    color: var(--color-black-500);
    transition: color 500ms;
    cursor: pointer;
    &:hover {
      color: var(--color-black-100);
    }
  }
  &__bottom--mr {
    margin-right: var(--indent-12);
  }
}
</style>
