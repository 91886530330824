<template>
  <main class="main">
    <HeaderNew @openService='openServiceFrom' />
    <router-view />
    <FooterNew></FooterNew>
    <ServiceModal
      :isModal="isServiceModal"
      @closeWallet="isServiceModal = false"
    />
    <div class="page-loader" :class="[processingModal.isShown && 'is-active']">
      <UiLoader :loading="true" />
      <div class="page-loader__message" v-html="processingModal.text" />
    </div>
    <ConnectModal />
  </main>
</template>

<script>
import FooterNew from '@/components/Home/partials/FooterNew.vue'
import HeaderNew from '@/components/Home/partials/HeaderNew.vue'
import ServiceModal from '@/components/Layout/ServiceModal.vue'
import ConnectModal from '@/components/Layout/ConnectModal.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { getLocalStorageElement } from '@/helpers/localstorage'
import { LSTORAGE } from '@/config/constants'

export default {
  components: { ConnectModal, ServiceModal, HeaderNew, FooterNew },
  data() {
    return {
      isServiceModal: false
    }
  },
  async mounted() {
    await this.initUser()
  },

  computed: {
    ...mapGetters('loader', ['processingModal']),
    ...mapState('crypto', ['remember_token'])
  },

  methods: {
    ...mapActions({ initCrypto: 'crypto/init' }),

    async initUser() {
      if (getLocalStorageElement(LSTORAGE.token)) {
        if (!this.remember_token) {
          await this.initCrypto()
        }
      }
    },
    openServiceFrom() {
      this.isServiceModal = true
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  //overflow-y: hidden;
}
</style>
