<template>
  <div class="bell" @click.stop="$emit('bellEvent')">
    <span class="bell__count" v-show="count" />
    <BaseIcon name="bell" verAlign @event="$emit('bellEvent')" />
  </div>
</template>

<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'

export default {
  props: ['count'],
  components: { BaseIcon },
}
</script>

<style lang="scss">
.bell {
  position: relative;
  background: var(--color-white-5);
  padding: var(--indent-4) var(--indent-8);
  border-radius: var(--indent-8);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 32px;
  transition: .3s ease;
  cursor: pointer;

  svg path {
    transition: .3s ease;
  }

  &__count {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: var(--indent-12);
    height: var(--indent-12);
    border-radius: var(--radius-50);
    background: #5E5CE6;
  }

  @media (max-width: 1025px) {
    display: none;
  }

  &:hover {
    background: var(--color-white-10);
    transition: .3s ease;
    svg path {
      fill-opacity: 1;
    }
  }
}
</style>
