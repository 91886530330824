<template>
  <div class='copyMyLink' @click.stop='openCopyLinkModal'>
    <div class='copyMyLink-item'>
      <BaseIcon name='copyLink' @event='openCopyLinkModal' />
      <p>{{ $t('settings.copyMyLink') }}</p>
    </div>
    <BaseIcon name='closedIconCopy' @event='openCopyLinkModal' />
  </div>
</template>
<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'
import { mapGetters, mapMutations } from 'vuex'
import { copyToClipboard } from '@/helpers/clipboard'

export default {
  components: { BaseIcon },
  computed: {
    ...mapGetters('user', ['getCurrentUser']),
    refLink() {
      return `https://${window.location.host}/r/${this.getCurrentUser.name}`
    },
  },
  methods: {
    ...mapMutations('ui', ['setIsCopyLinkModal']),
    openCopyLinkModal() {
      this.setIsCopyLinkModal(true)
    },
    handleClipboardCopy() {
      if (!this.getCurrentUser) return
      copyToClipboard(this.refLink)
        .then(() => {
          this.$toast.success(`${this.refLink} ${this.$t('account.copied')}`)
        })
        .catch(() => {
          this.$toast.error(`${this.$t('account.copyError')}`)
        })
    },
  }
}
</script>
<style lang='scss'>
.copyMyLink {
  cursor: pointer;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgba(237, 138, 0, 0.20);
  padding: var(--indent-16);
  transition: .3s ease;
  &-item {
    display: flex;
    flex-direction: row;
    color: #EB7104;
    align-items: center;
    gap: 15px;
  }

  &:hover {
    background: #EB7104;

    p {
      color:var(--color-white);
    }

    .ico {
      svg {
        path {
          fill: var(--color-white);
          fill-opacity: 1;
        }
      }
    }
  }
}
</style>
