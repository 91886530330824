<template>
  <div class="container-wrapper">
    <Sidebar />
    <main class="main">
      <Header />
      <router-view />
      <Footer />
    </main>

    <!-- move to store -->
    <div class="page-loader" :class="[processingModal.isShown && 'is-active']">
      <UiLoader :loading="true" />
      <div class="page-loader__message" v-html="processingModal.text" />
    </div>

<!--    <ActivationModal />-->
    <NewActivationTactile />
    <HackedWalletModal />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import Sidebar from '@/components/Layout/Sidebar.vue'
import Header from '@/components/Layout/Header.vue'
import Footer from '@/components/Layout/Footer.vue'
import ethEvents from '@/mixin/ethEvents'
import NewActivationTactile from '@/components/Matrix/NewActivationTactile.vue'
import HackedWalletModal from '@/components/Layout/HackedWalletModal.vue'
// import ActivationModal from '@/components/Matrix/ActivationModal.vue'

export default {
  name: 'AppLayoutDefault',
  components: {
    HackedWalletModal,
    NewActivationTactile,
    Header,
    Footer,
    Sidebar,
    // ActivationModal,
  },
  mixins: [ethEvents],
  created() {
    const head = document.getElementsByTagName('head')[0]

    const plugin = head.getElementsByClassName('chatBotWidget');
    const link = head.getElementsByClassName('jv-css');
    const jivoDiv = document.getElementById('jivo-iframe-container');
    const jivoTagDiv = document.getElementsByTagName('jdiv')[0];

    const scriptElement = document.querySelector('.chatBotWidget');
    if (scriptElement) {
      scriptElement.parentNode.removeChild(scriptElement);
    }

    if (head && plugin[0]) {
      head.removeChild(plugin[0])
    }
    if (head && link[0]) {
      head.removeChild(link[0])
    }
    if (jivoTagDiv && jivoTagDiv.parentNode) {
      jivoTagDiv.parentNode.removeChild(jivoTagDiv)
    }
    if (jivoDiv && jivoDiv.parentNode) {
      jivoDiv.parentNode.removeChild(jivoDiv)
    }
    localStorage.removeItem('savedChatBot')

    const scriptToRemove = document.querySelector('script[src="https://app.embed.im/snow.js"]');
    if (scriptToRemove) {
      scriptToRemove.parentNode.removeChild(scriptToRemove);
    }

    const elementToRemove = document.getElementById('embedim--snow');
    if (elementToRemove) {
      elementToRemove.parentNode.removeChild(elementToRemove);
    }
  },

  async mounted() {
    if (!this.getToken) {
      await this.init()
    }
    if (!this.getAddedDashboardData) {
      const [gasErr, gasRes] = await this.getGas()
      if (gasErr) console.log(gasErr)
      await this.getMetaPaymentBalancesFormatted(this.getAccount)
      const [accErr, accRes] = await this.getBalances(this.getAccount)
      if (accErr) console.log(accErr)
      this.setAddedDashboardData(true)
    }
  },
  // watch: {
  //   getCurrentUser(newVal, oldVal) {
  //     if (newVal !== null) {
  //       setTimeout(() => {
  //         if (this.getAccount !== this.getCurrentUser.account) {
  //           localStorage.clear()
  //           localStorage.setItem('walletChanged', true)
  //           window.location = '/'
  //         }
  //       }, 3000)
  //     }
  //   },
  //   getToken(newVal, oldVal) {
  //     if (newVal !== oldVal) {
  //       if (this.getAccount !== this.getCurrentUser.account) {
  //         localStorage.clear()
  //         localStorage.setItem('walletChanged', true)
  //         window.location = '/'
  //       }
  //     }
  //   },
  // },

  computed: {
    ...mapGetters('loader', ['processingModal']),
    ...mapGetters('user', ['getCurrentUser']),
    ...mapGetters('crypto', ['getToken', 'getAccount', 'getAddedDashboardData'])
  },

  methods: {
    ...mapActions('crypto', ['init', 'getGas', 'getMetaPaymentBalancesFormatted', 'getBalances']),
    ...mapMutations('crypto', ['setAddedDashboardData'])
  },
}
</script>

<style lang="scss" scoped>
.container-wrapper {
  display: flex;
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
}
.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-top: 56px;
  background: var(--color-black-850);
  z-index: 1;
}
</style>
