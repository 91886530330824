import { vm } from "@/main.js";
import { DICT, LSTORAGE } from "@/config/constants"
import { Log, Warn } from "@/helpers/dev"
import { getLocalStorageElement, setLocalStorageElement } from "@/helpers/localstorage"
import cryptoStore from "@/store/modules/cryptoModule/crypto"

const actionsWhitelist = ["buy"]
const timerInterval = 5000
const clearAfterMins = 60
let timer

export const getTxs = () => {
    return getLocalStorageElement(LSTORAGE.lostTx) || []
}

export const saveTx = ({ tx, action, params }) => {
    if (!actionsWhitelist.includes(action)) return
    let txs = getTxs()

    txs.push({ tx, action, params, done: false, timestamp: Date.now() })

    setLocalStorageElement(LSTORAGE.lostTx, txs)
}

export const watchLostTxs = () => {
    const txs = getTxs()
    // initial notification
    if (txs && txs.length > 0) {
        vm.$toast.clear()
        vm.$toast.error(vm.$t("lostTxs.hasLost"), { id: "lostError", timeout: false })
    }

    if (timer) return

    timer = setInterval(checkTxs, timerInterval)
}

export const checkTxs = () => {
    const txs = getTxs()
    if (!txs || txs.length === 0) return

    const { web3 } = cryptoStore.state

    clearOldTxs()

    // Log("lost txs", txs)

    txs.forEach((transaction) => {
        if (transaction.done) return

        web3.eth.getTransactionReceipt(transaction.tx).then((resp) => {
            // processSuccess(transaction)
            Log("tx check", { transaction }, { resp })
            if (resp != null && resp.blockNumber > 0) {
                processSuccess(transaction)
            }
        })
    })
}

export const clearOldTxs = () => {
    let txs = getTxs()
    const timeNow = Date.now()

    txs = txs.filter((t) => {
        const minuteDiff = (timeNow - t.timestamp) / 1000 / 60
        return minuteDiff < clearAfterMins
    })
    setLocalStorageElement(LSTORAGE.lostTx, txs)
}

export const processSuccess = async (tx) => {
    const { params, action } = tx
    let txs = getTxs()

    txs = txs.filter((t) => t.tx !== tx.tx)
    setLocalStorageElement(LSTORAGE.lostTx, txs)

    if (action === "buy") {
        vm.$toast.clear()
        vm.$toast.success(`${vm.$t("lostTxs.buyDone", { lvl: Number(params.lvl) + 1 })}`, { timeout: false })

        vm.$store.commit(
            "user/setClassMatrixLevel",
            {
                lvl: params.lvl,
                active: true,
            },
            { root: true }
        )
        // мы не используем данные этого запроса так как берем из rpc а не из Апи
        // vm.$store.dispatch(
        //     "user/setLevel",
        //     {
        //         account: params.account,
        //         level: Number(params.lvl) + 1,
        //     },
        //     { root: true }
        // )
    }
}
