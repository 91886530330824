<template>
    <div class="input" :class="[{ 'has-error': error }, theme, variant]">
        <div class="input__input">
            <UiLabel :for="_uid" :error="error" @click="handleClick">
                <input
                    :id="_uid"
                    :value="value"
                    :type="type"
                    v-bind="$attrs"
                    v-on="$listeners"
                    @input="() => !clickEvent && setValue()"
                />
                <span class="input__box" :class="value && 'checked'">
                    <svg-vue icon="checkmark" />
                </span>

                <slot />
            </UiLabel>
        </div>
    </div>
</template>

<script>
export default {
    name: "Checkbox",
    props: {
        value: {
            type: [String, Boolean],
            required: false,
        },
        type: {
            type: String,
            default: "checkbox",
        },
        clickEvent: {
            type: Boolean,
            default: false,
        },
        error: {
            type: [String, Boolean],
            required: false,
        },
        theme: {
            type: String,
            required: false,
        },
        variant: {
            type: String,
            required: false,
        },
    },
    computed: {
        isRadio() {
            return this.type === "radio"
        },
    },

    methods: {
        handleClick(e) {
            if (this.clickEvent) {
                e.preventDefault()
                this.setValue()
            }
        },
        setValue(e) {
            this.$emit("onChange", !this.value)
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/mixins";
.input {
    position: relative;

    .label {
        margin-bottom: 0;
        @include font-12;
        color: var(--color-white-40);
        font-weight: var(--font-400);
        @include flex-center;

    }

    &__input {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            pointer-events: none;
        }
        ::v-deep a {
            color: var(--color-orange-850);
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        &:hover {
            .input__box {
                border-color: var(--color-orange-850);
            }
        }
    }

    &__box {
      position: relative;
      padding: 5.5px;
      opacity: .8;
      margin-left: 5px;
        width: 15px;
        height: 15px;
        background: transparent;
        border: 2px solid var(--color-white-40);
        border-radius: 2px;
        color: white;
        cursor: pointer;
       margin-right: 13px;
      transition: border 0.25s ease-in-out, background 0.25s ease-in-out;
        svg, img {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 12px;
            transform: translate(-50%, -50%);
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.25s ease-in-out;
        }
        &.checked {
            background: var(--color-orange-850);
            border-color: var(--color-orange-850);
            svg, img {
                opacity: 1;
            }
        }
    }

    &.has-error {
        .input__box {
            border-color: var(--color-red);
        }
        .label {
            color: var(--color-red);
        }
    }

    input[type="radio"] + & {
        &__box {
            border-radius: 50% !important;
        }
    }
}
</style>
