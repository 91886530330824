import { render, staticRenderFns } from "./TransferModal.vue?vue&type=template&id=1e5868d8&"
import script from "./TransferModal.vue?vue&type=script&lang=js&"
export * from "./TransferModal.vue?vue&type=script&lang=js&"
import style0 from "./TransferModal.vue?vue&type=style&index=0&id=1e5868d8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports