Promise.delay = (t, val) => {
    return new Promise((resolve) => {
        setTimeout(resolve.bind(null, val), t)
    })
}

Promise.raceAll = (promises, timeoutTime, timeoutVal) => {
    return Promise.all(
        promises.map((p) => {
            return Promise.race([p, Promise.delay(timeoutTime, timeoutVal)])
        })
    )
}
