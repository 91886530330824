<template>
  <UiModalService :visible="activationModal.visible" @closeModal="closeServiceModal">
    <div class="new-activation-modal" @click.stop :class='!activationModal.visible && "closeModalService"'>
      <BaseIcon class="close-item" name='closeServiceIcon' @event='closeServiceModal' />
      <div class="modal-content">
        <BaseIcon name='activationIcon' />
        <div class="modal-info">
          <div class="title">
            {{ $t("matrix.activationModal.activationOfLevel") }} {{ activationModal.level + 1 }} ({{
              activationModal.type
            }})
          </div>
          <div class="amount">
            {{ $t("matrix.activationModal.youAreBuying") }}:
            <span>lvl {{ activationModal.level + 1 }} — {{ activationModal.cost }} DAI</span>
          </div>
          <div class="agreement">
            <UiCheckbox :value="agreeTerms" :error="agreeError" @onChange="(v) => (agreeTerms = v)">
              <span v-html="$t('matrix.activationModal.agree')"></span>
            </UiCheckbox>
            <div class="error" v-if='someErrors'>
              <span
                  v-for='(error,idx) in process'
                  :key='idx'
                  v-show='typeof error === "string"'
              >{{error}}</span>
              <button
                  class="replenish"
                  @click='replenishBalance'
                  v-show='typeof process[1] === "string"'
              >Replenish balance</button>
            </div>
          </div>
        </div>
        <button class="submit-btn" @click.stop.prevent="handleBuyLevel" :disabled='loading'>
          <UiLoader v-if="loading" :loading="true" theme="btn" color="#000" />
          <span v-else>{{ $t("matrix.activationModal.proceed") }}</span>
        </button>
      </div>
    </div>
  </UiModalService>
</template>
<script>
import BaseIcon from "@/components/UI/BaseIcon.vue";
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
export default {
  name: "NewActivationTactile",
  components: {
    BaseIcon,
  },
  data() {
    return {
      loading: false,
      process: [null, null, null, null],
      estimatedGas: "0",
      agreeTerms: false,
      agreeError: false
    }
  },
  watch: {
    activationModalVisible() {
      this.process = [null, null, null, null]
      this.estimatedGas = "0"
    }
  },
  computed: {
    ...mapGetters("ui", ["activationModal", "activationModalVisible"]),
    ...mapGetters("crypto", ["getAccount"]),
    ...mapState("crypto", ["balance"]),
    someErrors() {
      return this.process.find((x) => typeof x === 'string')
    },
  },
  methods: {
    ...mapMutations("ui", ["setActivationModal"]),
    ...mapActions("crypto", ["getBalances", "verboseBuyGas", "buyLevel"]),

    async handleBuyLevel() {
      if (this.loading) return

      this.agreeError = false

      if (!this.agreeTerms) {
        this.agreeError = true
        return
      }

      this.process = [null, null, null, null]
      this.loading = true
      this.estimatedGas = "0"

      const updateProgress = (i, v) => {
        this.process = [...this.process.map((x, idx) => (idx === i ? v : x))]
      }

      const account = this.getAccount
      updateProgress(0, true)

      const [balErr] = await this.getBalances(account)
      const { busd } = this.balance
      const enoughBusd = busd >= this.activationModal.cost

      if (balErr || !enoughBusd) {
        updateProgress(1, 'Balance DAI is insufficient')
        this.loading = false
        return
      }
      updateProgress(1, true)

      const [estimateErr, estimatePrice] = await this.verboseBuyGas({
        lvl: this.activationModal.level,
        priceInEther: this.activationModal.cost,
        onAllowancePending: () => {
          this.$store.commit("loader/showProcessingModal", {
            isShown: true,
            text: this.$t("matrix.activationModal.allowancePending")
          })
        },
        onBlockchainPending: () => {
          this.$store.commit("loader/showProcessingModal", {
            isShown: true,
            text: `${this.$t("matrix.activationModal.blockchainPendingFirstLine")} <br />
                              ${this.$t("matrix.activationModal.blockchainPendingSecondLine")}`
          })
        }
      })

      if (estimateErr) {
        updateProgress(2, estimateErr.message)
        this.loading = false
        this.$store.commit("loader/showProcessingModal", {
          isShown: false
        })

        return
      }
      updateProgress(2, true)
      this.estimatedGas = Number(estimatePrice).toFixed(4)

      this.$store.commit("loader/showProcessingModal", {
        isShown: true
      })
      const [err, response] = await this.buyLevel({
        lvl: this.activationModal.level,
        priceInEther: this.activationModal.cost,
        onAllowancePending: () => {
          this.$store.commit("loader/showProcessingModal", {
            isShown: true,
            text: this.$t("matrix.activationModal.allowancePending")
          })
        },
        onBlockchainPending: () => {
          this.$store.commit("loader/showProcessingModal", {
            isShown: true,
            text: `${this.$t("matrix.activationModal.blockchainPendingFirstLine")} <br />
                              ${this.$t("matrix.activationModal.blockchainPendingSecondLine")}`
          })
        },
        onTransactionPending: () => {
          this.$store.commit("loader/showProcessingModal", {
            isShown: true,
            text: this.$t("matrix.activationModal.transactionPending")
          })
        }
      })

      this.$store.commit("loader/showProcessingModal", {
        isShown: false,
      })
      this.loading = false

      if (err) {
        this.$swal(err.message || err)
        updateProgress(3, err.message || err)
        if (err.status === 202) {
          this.closeModal()
        }
        return
      }
      if (response) {
        this.$toast.success('you bought a new level')
      }

      this.closeModal()
    },
    replenishBalance(){
      const url = 'https://portfolio.metamask.io/buy';
      window.open(url, '_blank');
    },
    closeServiceModal() {
      this.setActivationModal({ visible: false })
    }
  },
}
</script>
<style lang="scss">
@import "~@/assets/scss/mixins";

.new-activation-modal {
  position: relative;
  width: calc(100% - 32px);
  max-width: 540px;
  height: auto;
  margin: 0 auto;
  border-radius: var(--indent-12);
  background: var(--color-black-800);
  overflow: hidden;

  .close-item {
    @include flex-center-center;
    width: 40px;
    height: 40px;
    position: absolute;
    top: var(--indent-8);
    right: var(--indent-8);
    z-index: 2;
    cursor: pointer!important;

    svg path {
      transition: .3s ease;
    }

    &:hover {
      svg path {
        fill-opacity: 1;
      }
    }
  }

  .modal-content {
    @include flex-column;
    align-items: center;
    max-height: 80vh;
    gap: 40px;
    padding: 40px;
    overflow: auto;

    .modal-info {
      @include flex-column;
      width: 100%;
      gap: var(--indent-24);

      .title {
        text-align: center;
        font-weight: var(--font-600);
        @include font-32;
      }

      .amount {
        @include flex-center-between;
        padding: var(--indent-12) var(--indent-16);
        border-radius: var(--indent-8);
        background: var(--color-white-5);
        color: var(--color-white-40);
        font-weight: var(--font-400);
        @include font-14;
        text-transform: uppercase;

        span {
          color: var(--color-white);
          font-weight: var(--font-600);
          @include font-16;
          text-transform: none;
        }
      }

      .agreement {
        @include flex-column;
        position: relative;
        gap: 40px;

        input[type=checkbox] + label {
          @include flex-center;
          justify-content: flex-start;
          padding-left: var(--indent-32);
          color: var(--color-white-40);
          font-weight: var(--font-400);
          @include font-12;
          cursor: pointer;

          .ico {
            @include flex-center-center;
            position: absolute;
            left: 0;
            transition: .3s ease;

            &.checked {
              opacity: 0;
              pointer-events: none;
            }
          }

          &:hover {
            .ico {
              svg path {
                fill-opacity: 1;
                transition: .3s ease;
              }
            }
          }
        }

        input[type=checkbox]:checked + label {
          .ico {
            &.unchecked {
              opacity: 0;
            }

            &.checked {
              opacity: 1;
            }
          }
        }
      }
    }

    .error {
      @include flex-center-between;
      width: 100%;
      padding: var(--indent-8) var(--indent-8) var(--indent-8) var(--indent-16);
      border-radius: var(--indent-8);
      border: 1px solid rgba(222, 34, 22, 0.20);
      background: rgba(222, 34, 22, 0.08);

      span {
        max-width: 50%;
        color: var(--color-red);
        font-weight: var(--font-400);
        @include font-14;
      }

      .replenish {
        padding: var(--indent-8) var(--indent-16);
        border-radius: var(--indent-4);
        border: 1px solid transparent;
        background: var(--color-white-5);
        transition: .3s ease;
        color: var(--color-white-40);
        font-weight: var(--font-500);
        @include font-12;

        &:hover {
          background: var(--color-white-10);
          color: var(--color-white);
        }

        &:focus {
          border-radius: 8px;
          border: 1px solid #64D2FF;
          background: var(--color-white-10);
          color: var(--color-white);
        }
      }
    }

    .submit-btn {
      width: 100%;
      padding: var(--indent-8) var(--indent-16);
      border-radius: var(--indent-8);
      background: #EB7104;
      transition: .3s ease;
      font-weight: var(--font-500);
      @include font-15;
      text-align: center;

      &:hover {
        background: #EB5704;
      }

      &:focus {
        background: #EB7104;
        outline: 1px solid #64D2FF;
      }

      &:disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }

  &.closeModalService {
    left: 0;
    right: 0!important;
  }
}
</style>