<template>
  <UiModalService :visible="isNoticeMessage" @closeModal="closeNoticeMessage">
    <SupportNotifications  @closeNotificationModal='(text) => closeNoticeMessage(text)' :isNoticeMessage='isNoticeMessage' />
  </UiModalService>
</template>
<script>
import SupportNotifications from '@/components/Dashboard/SupportNotifications.vue'

export default {
  props: {
    isNoticeMessage: {
      type: Boolean,
    },
  },
  components: {
    SupportNotifications,
  },
  methods: {
    closeNoticeMessage(text) {
      if (text) {
        this.$emit('closeService')
      } else {
        this.$emit('closeNoticeMessage')
      }
    },
  },
}
</script>
