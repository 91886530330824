import CryptoJS from 'crypto-js'

export const encryptUser = (user_id, secret, salt) => {
  try {
    const Sha256 = CryptoJS.SHA256;
    const AES = CryptoJS.AES;
    const id = user_id.toString()

    const {Hex, Utf8, Base64} = CryptoJS.enc;

    const key = Sha256(secret).toString(Hex).substr(0,32);
    const iv = Sha256(salt).toString(Hex).substr(0,16);

    const output = AES.encrypt(id, Utf8.parse(key), { iv: Utf8.parse(iv) }).toString();
    return Utf8.parse(output).toString(Base64);
  } catch (error) {
    console.error('Ошибка при хешировании данных:', error);
    throw error;
  }
}