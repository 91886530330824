import { tokenFormatFromWei } from '@/helpers/crypto'

export function shortAccount(v) {
  return v.slice(0, 10) + '...' + v.slice(-8)
}

export function hiddenAccount(v) {
  return v.slice(0, 6) + '...' + v.slice(-4)
}

export function formatMoney(value) {
  let val = (value / 1).toFixed(2).replace('.', ',')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export function formatWeiInEther(val) {
  return formatMoney(tokenFormatFromWei(val))
}
