<template>
    <label class="label" :class="[error && 'has-error']" v-bind="$attrs" v-on="$listeners">
        {{ displayLabel }}
        <slot></slot>
    </label>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: false,
        },
        error: {
            type: [String, Boolean],
            required: false,
        },
    },
    computed: {
        displayLabel() {
            return typeof this.error === "string" ? this.error : this.label
        },
    },
}
</script>

<style lang="scss" scoped>
.label {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: calc(21 / 14);
    color: var(--color-white);
    margin-bottom: 10px;
    &.has-error {
        color: var(--color-red);
    }
    a {
        color: var(--color-emerald);
        transition: color 0.25s ease-in-out;
        text-decoration: underline;
        &:hover {
            color: var(--color-yellow);
            text-decoration: none;
        }
    }
}
</style>
