import { $axiosNewApi } from '@/plugins/Axios'
import endpoint from '@/config/api-endpoint'
import { vm } from '@/main.js'
import { mapApiError, mapData, handleBooleanResponce } from './helpers'
// import axios from 'axios'
// import { LSTORAGE } from '@/config/constants'

export const getUsersService = async (request) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.users.getAll(request), {
      ...request,
    })
    if (data && (data.status === 'error' || data.users === null)) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }
    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const getAllFollowersService = async () => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.users.getAllFollowers)
    if (data && (data.status === 'error' || data.users === null)) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }
    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}

export const getUserByIdService = async (request) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.users.getById(request.id), {
      ...request,
    })

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}
export const getCurrentUserService = async (id) => {
  try {
    const { data } = await $axiosNewApi.get(id ? endpoint.users.getUserEndpoint + `/${id}` : endpoint.users.getUserEndpoint)
    if (data && (data.status === 'error' || data.users === null)) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}

export const updateCurrentUserService = async (updateData) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.users.getUserEndpoint,
      updateData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}

export const getUserByFieldService = async (request) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.users.getByField, {
      ...request,
    })

    if (data && (data.status === 'error' || data.users === null)) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const getTactileFollowers = async (request) => {
  try {
    const data  = await $axiosNewApi.post(endpoint.users.getTactileFollowers, {
      ...request,
    })

    if (data && (data.status === 'error' || data.users === null)) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      console.log(error)
      throw error
    }

    return mapData(data)
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}

export const getUsersBatchService = async (request) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.users.getBatchAccounts, {
      ...request,
    })

    if (data && (data.status === 'error' || data.users === null)) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      console.log(error)
      throw error
    }

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const isValidAccountService = async (request) => {
  try {
    const { data } = await $axiosNewApi.get(
      endpoint.users.isAccountByHash(request.hash),
      {
        ...request,
      }
    )

    return handleBooleanResponce(data, `${vm.$t('api.helpers.notFound')}`)
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const getAuthMessageService = async (request) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.users.authMessage, {
      ...request,
    })

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const verifyAuthMessageService = async (request) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.users.authVerify, {
      ...request,
    })
    if (data && (data.status === 'error' || data.users === null)) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const registerAccountService = async (request) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.users.register, {
      ...request,
    })
    if (data && (data.status === 'error' || data.users === null)) {
      const error = new Error(`${vm.$t('api.helpers.notFound')}`)
      error.status = 404
      throw error
    }

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const isLevelActiveService = async (request) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.users.isLevelActive, {
      ...request,
    })

    return handleBooleanResponce(data, `${vm.$t('api.helpers.notActivated')}`)
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const getParentsCountService = async (request) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.users.getParentsCount, {
      ...request,
    })

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const setLevelService = async (request) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.users.setLevel, {
      ...request,
    })

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const getTransactionsService = async (request) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.users.getTransactions, {
      params: { ...request },
    })

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const sendTransactionService = async (request) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.users.sendTransaction, {
      ...request,
    })

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const allUsersInfoService = async () => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.users.usersInfo)
    // console.log('user ALL DATA', data)
    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}
export const searchUsersService = async (payload) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.users.searchUsers, {
      params: {
        term: payload.term,
        page: payload.page
      }
    })
    // console.log('user ALL DATA', data)
    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}
export const privacy = async (payload) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.users.privacy, {
      ...payload,
    })

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}
export const privacyUpdate = async (payload) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.users.privacy, {
      ...payload,
    })

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const getWalletHistoryService = async (payload) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.wallet.getWalletHistory, {
      params: payload
    })

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const setWalletHistoryService = async (payload) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.wallet.getWalletHistory, {
      ...payload
    })

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}
