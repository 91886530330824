import i18n from '@/plugins/i18n'

export default {
  state: {
    isShow: false,
    processingModal: {
      isShown: false,
      text: '',
      defaultText: `${i18n.t(
        'matrix.activationModal.blockchainPendingFirstLine'
      )} <br /> ${i18n.t(
        'matrix.activationModal.blockchainPendingSecondLine'
      )}`,
    },
  },
  getters: {
    processingModal(state) {
      return state.processingModal
    },
  },
  mutations: {
    showPreloader: (state, isShow) => {
      state.isShow = isShow
    },
    showLoader: (state) => {
      state.processingModal.isShown = true
      state.processingModal.text = state.processingModal.defaultText
    },
    hideLoader: (state) => {
      state.processingModal.isShown = false
      state.processingModal.text = ''
    },
    showProcessingModal: (state, { isShown = true, text }) => {
      state.processingModal.isShown = isShown
      state.processingModal.text =
        text !== undefined
          ? text
          : isShown
            ? state.processingModal.defaultText
            : ''
    },
  },
  namespaced: true,
}
