import Vue from 'vue'
import Router from 'vue-router'
import preloader from '../plugins/preloader'
import { getLocalStorageElement } from '@/helpers/localstorage'
import { LSTORAGE } from '@/config/constants'
import { isServiceMode } from '@/helpers/dev'
import HomeLayout from '@/layouts/HomeLayout.vue'
import HomeLayoutNew from '@/layouts/HomeLayoutNew.vue'
import AppLayoutDefault from '@/layouts/AppLayoutDefault.vue'
import AppLayoutService from '@/layouts/AppLayoutService.vue'
import store from '@/store'

Vue.use(Router)

const serviceMode = isServiceMode()
const serviceModeProps = () => {
  return serviceMode
    ? {
      redirect: (to) => {
        return 'service'
      },
    }
    : {}
}

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '*',
      redirect: '/news-portal/:locale?',
    },
    {
      path: '/',
      redirect: { name: 'landing' },
      name: 'AppLayoutLanding',
      component: HomeLayoutNew,
      children: [
        {
          path: '/',
          name: 'landing',
          component: () => import('@/pages/landing/LandingNew.vue'),
        },
        {
          path: '/web3info',
          name: 'HomeWeb3infosmartcontracts',
          component: () =>
            import('@/components/Home/Info/Web3infosmartcontractsNew.vue'),
        },
        {
          path: '/terms',
          name: 'HomeTermsofservice',
          component: () => import('@/components/Home/Info/TermsofserviceNew.vue'),
        },
        {
          path: '/about',
          name: 'HomeAbout',
          component: () => import('@/components/Home/Info/AboutNew.vue'),
        },
        {
          path: '/promotion',
          name: 'HomePromotion',
          component: () => import('@/components/Home/Info/Promotion.vue'),
        },
        {
          path: '/sources',
          name: 'HomeSocial',
          component: () => import('@/components/Home/Info/Social.vue'),
        },
        {
          path: '/service-metaverse',
          name: 'ServiceMetaverse',
          component: () => import('@/components/Home/Info/ServiceMetaverse.vue'),
        },
        {
          path: '/service-all',
          name: 'ServiceAll',
          component: () => import('@/components/Home/Info/ServiceAll.vue'),
        },
        {
          path: '/service-tactile',
          name: 'ServiceTactile',
          component: () => import('@/components/Home/Info/ServiceTactile.vue'),
        },
        {
          path: '/service-force-system',
          name: 'ServiceForceSystem',
          component: () => import('@/components/Home/Info/ServiceForceSystem.vue'),
        },
        {
          path: '/service-nft-marketplace',
          name: 'ServiceNftMarketplace',
          component: () => import('@/components/Home/Info/ServiceNftMarketplace.vue'),
        },
        {
          path: '/service-mobile-wallet',
          name: 'ServiceMobileWallet',
          component: () => import('@/components/Home/Info/ServiceMobileWallet.vue'),
        },
      ],
    },
    {
      path: '/',
      redirect: { name: 'landing' },
      name: 'AppLayoutLanding',
      component: HomeLayout,
      children: [
        {
          path: '/landing-old',
          name: 'landing',
          component: () => import('@/pages/landing/Home.vue'),
        },
        {
          path: '/metaverse',
          name: 'Metaverse',
          component: () => import('@/components/Home/Metaverse/LandingMetaverse.vue'),
        },
        {
          path: '/boost',
          name: 'HomeBoost',
          component: () => import('@/components/Home/Info/Boost.vue'),
        },
        {
          path: '/overreal',
          name: 'HomeOverreal',
          component: () => import('@/components/Home/Info/Overreal.vue'),
        },
        {
          path: '/support',
          name: 'HomeSupport',
          component: () => import('@/components/Home/Info/Support.vue'),
        },
        {
          path: '/tactile',
          name: 'HomeTactile',
          component: () => import('@/components/Home/Info/Tactile.vue'),
        },
        // {
        //   path: '/terms',
        //   name: 'HomeTermsofservice',
        //   component: () => import('@/components/Home/Info/Termsofservice.vue'),
        // },
        {
          path: '/uniteverse',
          name: 'HomeUniteverse',
          component: () => import('@/components/Home/Info/Uniteverse.vue'),
        },
        // {
        //   path: '/web3info',
        //   name: 'HomeWeb3infosmartcontracts',
        //   component: () =>
        //     import('@/components/Home/Info/Web3infosmartcontracts.vue'),
        // },

      ],
    },
    /*{
      path: '/AppLayoutSimple',
      redirect: { name: 'notFound' },
      name: 'AppLayoutSimple',
      component: AppLayoutSimple,
      children: [
        {
          path: '/signup',
          name: 'signup',
          component: () => import('@/pages/register/Register.vue'),
        },
        {
          path: '/r/:referral',
          name: 'referral',
          component: () => import('@/pages/register/RegisterAlighting.vue'),
          props: true,
          ...serviceModeProps(),
        },
      ],
    },*/
    {
      path: '/AppLayoutDefault',
      redirect: { name: 'notFound' },
      name: 'AppLayoutDefault',
      component: AppLayoutDefault,
      children: [
        {
          path: '/Settings',
          name: 'settings',
          component: () => import('@/pages/settings/Settings.vue'),
          meta: {
            requiresWallet: true,
            requiresAuth: true,
          },
          ...serviceModeProps(),
        },
        {
          path: '/Home',
          name: 'dashboard',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/pages/dashboard/HomeNew.vue'),
          ...serviceModeProps(),
        },
        {
          path: '/My_profile',
          name: 'profile',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/pages/profile/Profile.vue'),
          ...serviceModeProps(),
        },
        {
          path: '/home-old',
          name: 'home',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/pages/dashboard/Dashboard.vue'),

        },

        {
          path: '/home-new-statistics',
          name: 'home-new-statistics',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/pages/dashboard/HomeNewStatistics.vue'),

        },
        {
          path: '/home-new-income',
          name: 'home-new-income',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/pages/dashboard/HomeNewIncome.vue'),

        },
        {
          path: '/dashboard',
          meta: {
            requiresWallet: true,
          },
          redirect: { name: 'dashboard' },
        },
        {
          path: '/Systems',
          name: 'classic',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/pages/dashboard/RealForce.vue'),
          ...serviceModeProps(),
        },
        {
          path: '/Systems/uniteverse',
          name: 'uniteverse',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/pages/dashboard/UniteVerse.vue'),
          ...serviceModeProps(),
          beforeEnter(to, from, next) {
            location.href = process.env.VUE_APP_UV_LINK
            next()
          },
        },
        {
          path: '/Systems/forceCoin',
          name: 'forceCoin',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/pages/dashboard/ForceCoin.vue'),
          ...serviceModeProps(),
          beforeEnter(to, from, next) {
            location.href = process.env.VUE_APP_FORCECOIN_LINK
            next()
          },
        },
        {
          path: '/Systems/Tactile',
          name: 'classicAllLevels',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/pages/dashboard/RealForceAllLevels.vue'),
          props: true,
          ...serviceModeProps(),
        },
        {
          path: '/Systems/Tactile/Level:lvl',
          name: 'classiclevel',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/pages/dashboard/RealForceLevel.vue'),
          props: true,
          ...serviceModeProps(),
        },
        {
          path: '/Systems/referral',
          name: 'systems_referral',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/pages/dashboard/MyTeam.vue'),
          ...serviceModeProps(),
        },
        {
          path: '/Messenger',
          name: 'messenger',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/pages/dashboard/Messenger.vue'),
          ...serviceModeProps(),
        },
        {
          path: '/Profile/:name',
          name: 'searchProfile',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/pages/user-search/SearchProfile.vue'),
          ...serviceModeProps()
        },
        {
          path: '/Profile/:name/Tactile',
          name: 'guestAllTactile',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/components/Search/GuestAllTactile.vue'),
          ...serviceModeProps(),
        },
        {
          path: '/Profile/:name/Tactile/Level:lvl',
          name: 'guestTactile',
          meta: {
            requiresWallet: true,
          },
          props: true,
          component: () => import('@/components/Search/GuestTactile.vue'),
          ...serviceModeProps(),
        },
        {
          path: '/Profile/:name/nfts',
          name: 'guestNfts',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/components/Search/GuestNftList.vue'),
          ...serviceModeProps(),
        },
        {
          path: '/Profile/:name/nfts/:contractAddress-:tokenId',
          name: 'guestNft',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/components/Search/GuestNft.vue'),
          ...serviceModeProps(),
        },
        {
          path: '/Profile/:name/nfts/reputationInfo',
          name: 'guestReputationInfoPage',
          meta: {
            requiresWallet: true,
          },
          props: true,
          component: () => import('@/components/Search/GuestReputationInfo.vue'),
          ...serviceModeProps(),
        },
        {
          path: '/User_Search',
          name: 'search',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/pages/user-search/Search.vue'),
          ...serviceModeProps(),
        },

        {
          path: '/reviewsold',
          name: 'reviewsold',
          component: () => import('@/pages/reviews/Reviews.vue'),
        },
        {
          path: '/about',
          name: 'about',
          component: () => import('@/pages/info/About.vue'),
        },
        {
          path: '/Help',
          name: 'help',
          component: () => import('@/pages/help/Help.vue'),
          meta: {
            requiresWallet: true,
            requiresAuth: true,
          },
        },
        {
          path: '/Help/create',
          name: 'helpcreate',
          component: () => import('@/pages/help/Create.vue'),
          meta: {
            requiresWallet: true,
            requiresAuth: true,
          },
        },
        {
          path: '/Help/ticket:id',
          name: 'helpticket',
          component: () => import('@/pages/help/Ticket.vue'),
          meta: {
            requiresWallet: true,
            requiresAuth: true,
          },
        },
        {
          path: '/Systems/nfts',
          name: 'nfts',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/pages/nfts/NftList.vue'),
          ...serviceModeProps(),
        },
        {
          path: '/Systems/nfts/:contractAddress-:tokenId',
          name: 'nft',
          meta: {
            requiresWallet: true,
          },
          component: () => import('@/pages/nfts/Nft.vue'),
          ...serviceModeProps(),
        },
        {
          path: '/Systems/reputationInfo',
          name: 'reputationInfoPage',
          meta: {
            requiresWallet: true,
          },
          props: true,
          component: () => import('@/pages/nfts/ReputationAboutPage.vue'),
          ...serviceModeProps(),
        },
      ],
    },
    {
      path: '/AppLayoutService',
      name: 'AppLayoutService',
      component: AppLayoutService,
      children: [
        {
          path: '/user-post/:id?',
          name: 'userPost',
          component: () => import('@/pages/userPost/userPost.vue'),
        },
        {
          path: '/signup',
          name: 'signup',
          component: () => import('@/pages/landing/LandingRegister.vue'),
        },
        {
          path: '/r/:referral',
          name: 'referral',
          component: () => import('@/pages/landing/LandingRegister.vue'),
          props: true,
          ...serviceModeProps(),
        },
        {
          path: '/news-portal/:locale?',
          name: 'academy',
          component: () => import('@/pages/academy/TheAcademy.vue'),
          props: true,
          children: [
            {
              path: '/news-portal/:locale?/:id',
              name: 'showCategory',
              component: () => import('@/pages/academy/TheAcademy.vue'),
              props: true,
            },
          ],
        },
        {
          path: '/news-portal/:locale?/post/:postId',
          name: 'showPost',
          component: () => import('@/pages/academy/Post.vue'),
          props: true,
        },
        {
          path: '/reviews',
          name: 'Reviews',
          component: () => import('@/pages/landing/LandingReviews.vue'),
        },
        {
          path: '/contests',
          name: 'contests',
          component: () => import('@/pages/contests/ContestsPage.vue'),
          ...serviceModeProps(),
        },
        {
          path: '/contests/:locale?/post/:postId',
          name: 'showСontestPost',
          component: () => import('@/pages/contests/ContestsSingle.vue'),
          props: true,
        },
        // {
        //   path: '/academy/:locale?/academy',
        //   name: 'academyPage',
        //   component: () => import('@/pages/academy/AcademyPage.vue'),
        //   props: true,
        // },
      ],
    },
  ],
})

let showed = false

router.beforeResolve((to, from, next) => {
  if (to.path && !showed) {
    preloader.show(true)
    showed = true
  }
  next()
})

router.beforeEach((to, from, next) => {
  function hasQueryParams(route) {
    return !!Object.keys(route.query).length
  }
  if (!to.matched.some((record) => record.meta.requiresWallet)) {
    next()
  } else {
    let lsMetamaskConnected = getLocalStorageElement(LSTORAGE.connected)

    const connected = store.getters['crypto/isConnected'] || lsMetamaskConnected

    if (connected) {
      if (!hasQueryParams(to) && hasQueryParams(from)) {
        next({ ...to, query: from.query })
      } else {
        next()
      }
      return
    }

    // @TODO add some alert
    // router.app.$swal(router.app.$t("errors.connectWalletError"))
    // router.app.$swal(i18n.$vm.t("errors.connectWalletError"))
    next({ name: 'academy' })
  }
})

router.afterEach(() => {
  setTimeout(function () {
    preloader.show(false)
  }, 888)
})

export default router
