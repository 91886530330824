import {
  addCommentService,
  addMyPublicPostService, deleteCommentService, deletePublicPostService, editCommentService, getCommentService,
  getFollowPublicPostService,
  getLikedPublicPostService,
  getMyPublicPostService, getOnePublicPostService, likePublicPostService, updatePublicPostService
} from '@/api/publicPost'

export default {
  state: {
    onePost: null,
    myPosts: [],
    myPostPage: null,
    followPosts: [],
    followPostPage: null,
    myLiked: [],
    myLikedPostPage: null,
    followPostFetched: false,
    likedPostFetched: false,
    myPostFetched: false,
    guestPost: [],
    guestPostPage: null,
    guestLikedPost: [],
    guestLikedPostPage: null,
    guestPostFetched: false,
    guestLikedPostFetched: false,
    showDeleteModal: {
      type: null,
      isShow: false,
      data: null
    },
    comments: [],
    commentPage: null
  },
  getters: {
    getFollowPostPage(state) {
      return state.followPostPage
    },
    getGuestPostPage(state) {
      return state.guestPostPage
    },
    getGuestLikedPostPage(state) {
      return state.guestLikedPostPage
    },
    getMyPostPage(state) {
      return state.myPostPage
    },
    getMyLikedPostPage(state) {
      return state.myLikedPostPage
    },
    getComments(state) {
      return state.comments
    },
    getShowDeleteModal(state) {
      return state.showDeleteModal
    },
    getOnePost(state) {
      return state.onePost
    },
    getGuestPost(state) {
      return state.guestPost
    },
    getGuestLikedPost(state) {
      return state.guestLikedPost
    },
    getFollowPostFetched(state) {
      return state.followPostFetched
    },
    getLikedPostFetched(state) {
      return state.likedPostFetched
    },
    getMyPostFetched(state) {
      return state.myPostFetched
    },
    getMyLiked(state) {
      return state.myLiked
    },
    getMyPosts(state) {
      return state.myPosts
    },
    getFollowPosts(state) {
      return state.followPosts
    },
    getGuestPostFetched(state) {
      return state.guestPostFetched
    },
    getGuestLikedPostFetched(state) {
      return state.guestLikedPostFetched
    },
    getCommentPage(state) {
      return  state.commentPage
    },
  },
  mutations: {
    clearGuestPostData(state) {
      state.guestPost = []
      state.guestPostPage = null
      state.guestLikedPost = []
      state.guestLikedPostPage = null
      state.guestPostFetched = false
      state.guestLikedPostFetched = false
    },
    setFollowPostPage(state, data) {
      state.followPostPage = data
    },
    setCommentPage(state, data) {
      state.commentPage = data
    },
    addMoreComment(state, data) {
      state.comments.push(...data)
    },
    setMyPostPage(state, data) {
      state.myPostPage = data
    },
    setMyLikedPostPage(state, data) {
      state.myLikedPostPage = data
    },
    setShowDeleteModal(state, data) {
      state.showDeleteModal = data
    },
    clearCommentsHistory(state) {
      state.comments = []
    },
    setOnePost(state, data) {
      state.onePost = data
    },
    setGuestPost(state, data) {
      state.guestPost = data
    },
    setMoreGuestPost(state, data) {
      state.guestPost.push(...data)
    },
    setGuestPostPage(state, data) {
      state.guestPostPage = data
    },
    setFollowPostFetched(state) {
      state.followPostFetched = true
    },
    setLikedPostFetched(state) {
      state.likedPostFetched = true
    },
    setMyPostFetched(state) {
      state.myPostFetched = true
    },
    setGuestPostFetched(state) {
      state.guestPostFetched = true
    },
    setGuestLikedPostFetched(state) {
      state.guestLikedPostFetched = true
    },
    setFollowPosts(state, data) {
      state.followPosts = data
    },
    setMoreFollowPosts(state, data) {
      state.followPosts.push(...data)
    },
    setLikedPosts(state, data) {
      state.myLiked = data
    },
    setMoreLikedPosts(state, data) {
      state.myLiked.push(...data)
    },
    setGuestLikedPosts(state, data) {
      state.guestLikedPost = data
    },
    setMoreGuestLikedPosts(state, data) {
      state.guestLikedPost.push(...data)
    },
    setGuestLikedPostsPage(state, data) {
      state.guestLikedPostPage = data
    },
    setMyPosts(state, data) {
      state.myPosts = data
    },
    setMoreMyPosts(state, data) {
      state.myPosts.push(...data)
    },
    setMyPost(state, data) {
      data.likes = 0
      state.myPosts.unshift(data)
      state.followPosts.unshift(data)
    },
    updateMyPost(state, data) {
      const idx = state.myPosts.findIndex(item => item.id === data.id)
      state.myPosts[idx] = data
    },
    setLikedPost(state, [data, name]) {
      state.myLiked.unshift(data)
      if (name === 'onePost') {
        state[name].likes ? state[name].likes += 1
          : state[name].likes = 1
      } else {
        state[name].find(item => {
          if (item.id === data.id) {
            if (item.likes) {
              item.likes += 1
            } else {
              item.likes = 1
            }
          }
        })
      }
    },
    unSetLikedPost(state, [data, name]) {
      state.myLiked = state.myLiked.filter(item => item.id !== data.id)
      if (name === 'onePost') {
        state[name].likes -= 1
      } else {
        state[name].find(item => {
          if (item.id === data.id) {
            item.likes ? item.likes -= 1 : ""
          }
        })
      }
    },
    deletePost(state, id) {
      state.myPosts = state.myPosts.filter(item => item.id !== id)
    },
    setComment(state, [data, name]) {
      state.comments.unshift(data)
      if (name === 'onePost') {
        state[name].comments ? state[name].comments += 1
          : state[name].comments = 1
      } else {
        state[name].find(item => {
          if (item.id === data.publication_id) {
            if (item.comments) {
              item.comments += 1
            } else {
              item.comments = 1
            }
          }
        })
      }
    },
    addComment(state, data) {
      state.comments = data
    },
    editComment(state, data) {
      const idx = state.comments.findIndex(item => item.id === data.id)
      state.comments[idx] = data
    },
    deleteComment(state, [data, name]) {
      state.comments = state.comments.filter(item => item.id !== data.id)
      if (name === 'onePost') {
        state[name].comments -= 1
      } else {
        state[name].find(item => {
          if (item.id === data.publication_id) {
            item.comments ? item.comments -= 1 : ""
          }
        })
      }
    }
  },
  actions: {
    async clearGuestPostAction({commit}) {
      commit('clearGuestPostData')
    },
    async getFollowPublicPostsAction({commit}, payload) {
      const [err, result] = await getFollowPublicPostService(payload)
      if (err) {
        commit('setFollowPostFetched')
        throw err
      }
      const {data, ...rest} = result
      commit('setFollowPosts', data)
      commit('setFollowPostPage', rest)
      commit('setFollowPostFetched')
    },
    async getMoreFollowPublicPostsAction({commit}, payload) {
      const [err, result] = await getFollowPublicPostService(payload)
      if (err) {
        commit('setFollowPostFetched')
        throw err
      }
      const {data, ...rest} = result
      commit('setMoreFollowPosts', data)
      commit('setFollowPostPage', rest)
      commit('setFollowPostFetched')
    },
    async getLikedPublicPostsAction({commit}, payload) {
      const [err, result] = await getLikedPublicPostService(payload)
      if (err) {
        commit('setLikedPostFetched')
        throw err
      }
      const {data, ...rest} = result
      commit('setLikedPosts', data)
      commit('setMyLikedPostPage', rest)
      commit('setLikedPostFetched')
    },
    async getMoreLikedPublicPostsAction({commit}, payload) {
      const [err, result] = await getLikedPublicPostService(payload)
      if (err) throw err
      const {data, ...rest} = result
      commit('setMoreLikedPosts', data)
      commit('setMyLikedPostPage', rest)
      commit('setLikedPostFetched')
    },
    async getMyPublicPostsAction({commit}, payload) {
      const [err, result] = await getMyPublicPostService(payload)
      if (err) {
        commit('setMyPostFetched')
        throw err
      }
      const {data, ...rest} = result
      commit('setMyPosts', data)
      commit('setMyPostPage', rest)
      commit('setMyPostFetched')
    },
    async getMoreMyPublicPostsAction({commit}, payload) {
      const [err, result] = await getMyPublicPostService(payload)
      if (err) throw err
      const {data, ...rest} = result
      commit('setMoreMyPosts', data)
      commit('setMyPostPage', rest)
      commit('setMyPostFetched')
    },
    async getGuestPublicPostsAction({commit}, payload) {
      const [err, result] = await getMyPublicPostService(payload)
      if (err) {
        commit('setGuestPostFetched')
        throw err
      }
      const {data, ...rest} = result
      commit('setGuestPost', data)
      commit('setGuestPostPage', rest)
      commit('setGuestPostFetched')
    },
    async getGuestMorePublicPostsAction({commit}, payload) {
      const [err, result] = await getMyPublicPostService(payload)
      if (err) throw err
      const {data, ...rest} = result
      commit('setMoreGuestPost', data)
      commit('setGuestPostPage', rest)
      commit('setGuestPostFetched')
    },
    async getGuestLikedPublicPostsAction({commit}, payload) {
      const [err, result] = await getLikedPublicPostService(payload)
      if (err) {
        commit('setGuestLikedPostFetched')
        throw err
      }
      const {data, ...rest} = result
      commit('setGuestLikedPosts', data)
      commit('setGuestLikedPostsPage', rest)
      commit('setGuestLikedPostFetched')
    },
    async getGuestMoreLikedPublicPostsAction({commit}, payload) {
      const [err, result] = await getLikedPublicPostService(payload)
      if (err) throw err
      const {data, ...rest} = result
      commit('setMoreGuestLikedPosts', data)
      commit('setGuestLikedPostsPage', rest)
      commit('setGuestLikedPostFetched')
    },
    async getOnePublicPostsAction({commit}, id) {
      const [err, result] = await getOnePublicPostService(id)
      if (err) throw err
      commit('setOnePost', result)
    },
    async deletePublicPostsAction({commit}, id) {
      const [err, result] = await deletePublicPostService(id)
      if (err) throw err
      commit('deletePost', id)
    },
    async likePublicPostsAction({commit}, [data, name]) {
      commit('setLikedPost', [data, name])
      const [err, result] = await likePublicPostService(data.id)
      if (err) throw err
    },
    async unSetLikedPostAction({commit}, [data, name]) {
      commit('unSetLikedPost', [data, name])
      const [err, result] = await likePublicPostService(data.id)
      if (err) throw err
    },
    async addNewPostAction({commit}, payload) {
      const [err, result] = await addMyPublicPostService(payload)
      if (err) throw err
      commit('setMyPost', result)
    },
    async updatePostAction({commit}, payload) {
      const [err, result] = await updatePublicPostService(payload)
      if (err) throw err
      commit('updateMyPost', result)
    },
    async addCommentAction({commit},[data, actualName]) {
      const [err, result] = await addCommentService(data)
      if (err) throw err
      commit('setComment', [result, actualName])
    },
    async getCommentAction({commit},payload) {
      const [err, result] = await getCommentService(payload)
      if (err) throw err
      const {data, ...rest} = result
      commit('addComment', data)
      commit('setCommentPage', rest)
    },
    async getMoreCommentAction({commit},payload) {
      const [err, result] = await getCommentService(payload)
      if (err) throw err
      const {data, ...rest} = result
      commit('addMoreComment', data)
      commit('setCommentPage', rest)
    },
    async editCommentAction({commit},payload) {
      const [err, result] = await editCommentService(payload)
      if (err) throw err
      commit('editComment', result)
    },
    async deleteCommentAction({commit},[data, name]) {
      const [err, result] = await deleteCommentService(data.id)
      if (err) throw err
      commit('deleteComment', [data, name])
    }

  },
  namespaced: true,
}