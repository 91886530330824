import { DEFAULT_AVATAR, DICT, LSTORAGE } from '@/config/constants'
import { getLocalStorageElement } from '@/helpers/localstorage'
import { Log, SentryLog } from '@/helpers/dev'
import cryptoStore from '@/store/modules/cryptoModule/crypto'
import { vm } from '@/main.js'
import { EthereumProvider } from '@walletconnect/ethereum-provider'
export const isEthereumWindow = () => {
  try {
    const providerName = getLocalStorageElement(LSTORAGE.wallet)

    if (providerName === 'walletconnect') {
      return [null, 'walletconnect']
    }

    const { ethereum } = window
    let condition = ethereum
    let errMessage = `${vm.$t('helpers.crypto.installProvider')}`

    if (providerName === 'metamask') {
      condition = ethereum && ethereum.isMetaMask
      errMessage = `${vm.$t('helpers.crypto.installMetaMask')}`
    } else if (providerName === 'trust') {
      condition = ethereum && ethereum.isTrust
      errMessage = `${vm.$t('helpers.crypto.installTrustWallet')}`
    } else if (providerName === 'coinbase') {
      condition = ethereum && ethereum.providerMap.has('CoinbaseWallet')
      errMessage = `${vm.$t('helpers.crypto.installCoinbaseWallet')}`
    }

    if (!condition) {
      console.log(errMessage)
    }
    return [null, providerName]
  } catch (error) {
    return [error, null]
  }
}

export const createProvider = async () => {
  let provider = window.ethereum
  const providerName = getLocalStorageElement(LSTORAGE.wallet)
  let isConnected = true
  if (localStorage.getItem(LSTORAGE.token) && localStorage.getItem(LSTORAGE.connected)) {
    isConnected = false
  } else {
    localStorage.removeItem(LSTORAGE.token)
    localStorage.removeItem(LSTORAGE.connected)
    localStorage.removeItem('myAccount')
    localStorage.removeItem('currentUserLocal')
  }
  Log('provider detection', providerName)

  if (providerName === "walletconnect") {
    provider = await EthereumProvider.init({
      projectId: DICT.VUE_APP_CONNECT_WALLET_PROJECT_ID,
      rpcMap: {
        [DICT.CHAIN_ID]: DICT.PROVIDER
      },
      chains: [DICT.CHAIN_ID],
      metadata: {
        name: "Meta Force",
        description: "Welcome to the Meta Force universe",
        url: "https://meta-force.space/",
        icons: [DEFAULT_AVATAR],
      },
      showQrModal: isConnected
    })
    await provider.enable()
    provider.sendAsync({ method: 'eth_requestAccounts' },() => {})
  }
  return provider
}

export const switchNetwork = async () => {
  const { provider, web3 } = cryptoStore.state
  const providerName = getLocalStorageElement(LSTORAGE.wallet)

  let isMetamaskLike = providerName === 'metamask'
  if (providerName === 'walletconnect') {
    try {
      Log(
        'PeerMeta',
        provider.connector._peerMeta,
        provider.connector._peerMeta.name
      )
      isMetamaskLike = ['MetaMask'].includes(provider.connector._peerMeta.name)
    } catch (e) {
      isMetamaskLike = false
    }
  }

  try {
    if (isMetamaskLike) {
      try {
        // проверить правильность сети и предложить переключиться если добавлена
        const curChain = await web3.eth.getChainId()
        if (curChain !== DICT.CHAIN_ID) {
          await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3.utils.toHex(DICT.CHAIN_ID) }],
          })
        }
      } catch (error) {
        if (error.code === 4902) {
          // предложить добавить нужную сеть если нет
          try {
            await provider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: web3.utils.toHex(DICT.CHAIN_ID),
                  chainName: DICT.CHAIN_NAME[DICT.IS_TESTNET ? 1 : 0],
                  nativeCurrency: {
                    name: DICT.NATIVE_CURRENCY_SYMBOL,
                    symbol: DICT.NATIVE_CURRENCY_SYMBOL,
                    decimals: 18,
                  },
                  blockExplorerUrls:
                    DICT.EXPLORER_URLS[DICT.IS_TESTNET ? 1 : 0],
                  rpcUrls: [DICT.PROVIDER],
                },
              ],
            })

            return [null, true]
          } catch (addError) {
            console.log(
              `${vm.$t('helpers.crypto.switchNetwork.lineOne')}
                         ${DICT.CHAIN_ID}
                         ${vm.$t('helpers.crypto.switchNetwork.lineTwo')}`
            )
          }
        }

        Log('provider.request error', error)
        console.log(
          `${vm.$t('helpers.crypto.switchNetwork.to')} ${DICT.CHAIN_ID}`
        )
      }
    } else {
      const curChain = await web3.eth.getChainId()
      Log('curChain', curChain)
      if (curChain !== DICT.CHAIN_ID) {
        // vm.$toast.error(`Invalid chain, should be: ${DICT.CHAIN_ID}`)
        try {
          await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3.utils.toHex(DICT.CHAIN_ID) }],
          })

          return [null, 'redispatch']
        } catch {
          // DEBUG
          // vm.$toast.error(`Invalid network: ${curChain}`)
          window.location.reload()
          console.log(
            `${vm.$t('helpers.crypto.switchNetwork.to')} ${DICT.CHAIN_ID}`
          )
        }
      }
    }

    return [null, true]
  } catch (error) {
    return [error, null]
  }
}

export const getLatestBlock = async (name = 'latest') => {
  const { web3 } = cryptoStore.state

  try {
    const block = await web3.eth.getBlock(name)

    return [null, block]
  } catch (error) {
    // some rpc throw error on pending syntax
    if (name === 'pending') {
      const block = await web3.eth.getBlock('latest')
      if (block) return [null, block]
    }
    SentryLog(error, 'getLatestBlock')
    return [new Error(`${vm.$t('helpers.crypto.lastBlockError')}`), null]
  }
}

export const tokenFormatFromWei = (wei, decimals = 'ether') => {
  const { web3 } = cryptoStore.state

  const amountBN = web3.utils.toBN(wei)
  const ethers = web3.utils.fromWei(amountBN, decimals)
  return Number(ethers)
}

export const getEthAccount = async () => {
  const { provider, web3 } = cryptoStore.state
  // const providerName = getLocalStorageElement(LSTORAGE.wallet)

  try {
    let accounts = []
    // if (providerName === "metamask") {
    try {
      accounts = await provider.request({ method: 'eth_requestAccounts' })
    } catch {
      accounts = await web3.eth.getAccounts()
    }

    if (!accounts[0]) {
      console.log('account not found')
    }

    Log('my account', accounts[0])
    return [null, accounts[0]]
  } catch (error) {
    Log('connect error?', error)
    let errorMsg = `${vm.$t('errors.connectWalletError')}`

    switch (error.code) {
      case -32002:
        errorMsg = `${vm.$t('helpers.crypto.checkPlugin')}`
        break

      default:
        break
    }

    return [new Error(errorMsg), null]
  }
}

export const getRPCErrorJSON = (err) => {
  const open = err.indexOf('{')
  const close = err.lastIndexOf('}')
  const j_s = err.substring(open, close + 1)

  return JSON.parse(j_s)
}

export const increaseGas = (gas, key = 'ESTIMATED_GAS_INCREASE') => {
  return Math.round(Number(gas) * DICT[key])
}

// error codes
// https://eips.ethereum.org/EIPS/eip-1193#provider-errors
// https://eips.ethereum.org/EIPS/eip-1474#error-codes

export const getRevertReason = (
  err,
  defaultMessage = `${vm.$t('helpers.crypto.error')}`
) => {
  try {
    if (Number(err.code)) {
      // regular metamask error
      switch (err.code) {
        case 4001:
          return new Error(`${vm.$t('helpers.crypto.cancelled')}`)

        default:
          return new Error(err.message)
      }
    } else if (err.message.split('(argument').length > 1) {
      // RCP error with argument error ??? (i.e registration)
      return new Error(err.message.split('(argument')[0])
    } else if (err.message.indexOf('{') === -1) {
      // regular api or custom error
      return err
    } else {
      // likelly RPC Error wioth require syntax
      const { message } = getRPCErrorJSON(err.message)

      return new Error(message)
    }

    // const reason = j.data[Object.keys(j.data)[0]].reason
  } catch (e) {
    return new Error(defaultMessage)
  }
}

export const isValidAddress = (address) => {
  const { web3 } = cryptoStore.state
  return web3.utils.isAddress(address)
}
