<template>
  <div id="app" :class='(this.isCopyLinkModal || this.isOpenModal) ? "modal-open" : "" '>
    <preloader />
    <app-layout>
      <router-view />
    </app-layout>
  </div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout.vue"
import Preloader from "@/components/UI/Preloader.vue"
import { mapGetters } from 'vuex'
export default {
  name: "App",
  components: { Preloader, AppLayout },
  computed: {
    ...mapGetters('ui', ['isCopyLinkModal', 'isOpenModal'])
  },
}
</script>
