import cryptoActions from '@/store/modules/cryptoModule/cryptoActions'
import Web3 from 'web3'
import { Log } from '@/helpers/dev'

import { DICT, LSTORAGE } from '@/config/constants'
import {
  setLocalStorageElement,
} from '@/helpers/localstorage'
import '@/helpers/promises'

const emulateSlowTx = false
const LAST_NFT_VERSION = 3
const NFT_LEVELS = 7

export default {
  state: {
    provider: null,
    connecting: null, // при первой инициализации dApp страниц (полная прогрузка)
    web3: {},
    connected: null, // критичная прогрузка от checkConnect
    account: null,
    remember_token: null,
    contracts: {
      main: null,
      sfc: null,
      sfcNew: null,
      sfc2: null,
      mfs: null,
      energy: null,
      nftControls: [], // [version] => address
      nftRegistries: [], // [version] => address
      nftMigration: null,
      royaltyNfts: [], // [version][level] => address

      metacore: null,
      metapayment: null,
      uvRegistry: null,
      uvCore: null,

      royaltyDistribution: null,
      distributionBannedNFT: null,
    },
    metaCoreUserId: null,
    daiHistory: [],
    daiHistoryPage: {},
    isDaiHistoryFetched: false,
    innerCoins: [],
    balance: {
      bnb: 0.0,
      sfc: 0.0,
      sfcNew: 0.0,
      sfc2: 0.0,
      busd: 0.0,
      energy: 0.0,
    },
    meta: {
      parent: null,
      nonce: 0,
      gasLimit: DICT.DEFAULT_GAS_LIMIT,
      gasPrice: DICT.DEFAULT_GAS_PRICE,
      maxFeePerGas: DICT.DEFAULT_GAS_PRICE,
    },
    connectionWallet: '',
    addedDashboardData: null,
  },
  getters: {
    getMetaCoreUserId(state) {
      return state.metaCoreUserId
    },
    getAddedDashboardData(state) {
      return state.addedDashboardData
    },
    getTotalDai(state) {
      return (Number(state.balance.busd) + Number(state.innerCoins[0])).toFixed(1)
    },
    getInnerCoins(state) {
      return state.innerCoins
    },
    isConnecting(state) {
      return state.connecting
    },
    isActiveWallet(state) {
      return state.account !== null
    },
    isConnected(state) {
      return state.connected
    },
    getAccount(state) {
      return state.account
    },
    getToken(state) {
      return state.remember_token
    },
    getParent(state) {
      return state.meta.parent
    },
    getBalance(state) {
      return {
        bnb: state.balance.bnb.toFixed(4),
        sfc: state.balance.sfc.toFixed(2),
        sfcNew: state.balance.sfcNew.toFixed(2),
        sfc2: state.balance.sfc2.toFixed(2),
        busd: state.balance.busd,
        energy: state.balance.energy.toFixed(2),
      }
    },
    getGas(state) {
      const isValidNumber = (n) => Number.isFinite(Number(n))

      return {
        limit: isValidNumber(state.meta.gasLimit)
          ? state.meta.gasLimit
          : DICT.DEFAULT_GAS_LIMIT,
        price: isValidNumber(state.meta.gasPrice)
          ? state.meta.gasPrice
          : DICT.DEFAULT_GAS_PRICE,
        max: isValidNumber(state.meta.maxFeePerGas)
          ? state.meta.maxFeePerGas
          : DICT.DEFAULT_GAS_MAX,
      }
    },
    getNonce(state) {
      return state.meta.nonce
    },
    getMinTransactionFee(state, getters) {
      const { limit, price } = getters.getGas
      return Number(price) * Number(limit)
    },
    getMainContract(state) {
      return state.contracts.main
    },
    getMFSContract(state) {
      return state.contracts.mfs
    },
    getSFCContract(state) {
      return state.contracts.sfc
    },
    getSFCNewContract(state) {
      return state.contracts.sfcNew
    },
    getSFC2Contract(state) {
      return state.contracts.sfc2
    },
    getEnergyContract(state) {
      return state.contracts.energy
    },
    getRoyaltyDistributionContract(state) {
      return state.contracts.royaltyDistribution
    },
    getDistributionBannedNFTContract(state) {
      return state.contracts.distributionBannedNFT
    },
    getNftControlContract:
      (state) =>
      (version = LAST_NFT_VERSION) => {
        return state.contracts.nftControls[version]
    },
    getNftRegistryContract:
      (state) =>
      (version = LAST_NFT_VERSION) => {
        return state.contracts.nftRegistries[version]
    },
    getNftMigrationContract(state) {
      return state.contracts.nftMigration
    },
    getNftContract:
      (state) =>
      (address, version = 3) => {
        return state.contracts.royaltyNfts[version].find(
          (x) => x._address.toLowerCase() == address.toLowerCase()
        )
      },
    getNftLvl: (state) => (address) => {
      for (let i in state.contracts.royaltyNfts) {
        let nftLvl = state.contracts.royaltyNfts[i]
          .map((x) => x.options.address.toLowerCase())
          .indexOf(address.toLowerCase())
        if (nftLvl !== -1) {
          return nftLvl
        }
      }
      console.log(`Invalid nft address: ${address}`)
    },
    isAddressJustNFT: (state) => (address) => {
      console.log(
        'this.isJustNft xx',
        state.contracts.royaltyNfts[1][0].options.address,
        address
      )

      for (let i in state.contracts.royaltyNfts) {
        if (
          state.contracts.royaltyNfts[i][0]?.options.address.toLowerCase() ===
          address.toLowerCase()
        ) {
          return true
        }
      }
      return false
    },
    getLastNftAddress(state) {
      return state.contracts.royaltyNfts[LAST_NFT_VERSION][
        NFT_LEVELS
      ]?.options.address.toLowerCase()
    },
    getRoyaltyNFTVersion: (state) => (address) => {
      for (let i in state.contracts.royaltyNfts) {
        if (
          state.contracts.royaltyNfts[i]
            .map((x) => x.options.address.toLowerCase())
            .indexOf(address.toLowerCase()) !== -1
        ) {
          return i
        }
      }
      console.log(`Invalid nft address: ${address}`)
    },
    getMetaCore(state) {
      return state.contracts.metacore
    },
    getMetaPayment(state) {
      return state.contracts.metapayment
    },
    getUVCore(state) {
      return state.contracts.uvCore
    },
    getEstimateParams(state) {
      return {
        from: state.account,
        // value: getters.getGas.price,
      }
    },
    getSendParams(state, getters) {
      return {
        nonce: getters.getNonce,
        from: state.account,
        gas: Number(getters.getGas.limit),
        type: '0x2',
        // gasPrice: getters.getGas.price,
        maxFeePerGas: emulateSlowTx ? '30000000001' : getters.getGas.max,
        maxPriorityFeePerGas: emulateSlowTx
          ? '30000000000'
          : getters.getGas.price,
      }
    },
    getConnectionWallet(state) {
      return state.connectionWallet
    },
    getDaiHistory(state) {
      return state.daiHistory
    },
    getDaiHistoryFetched(state) {
      return state.isDaiHistoryFetched
    },
    getDaiHistoryPage(state) {
      return state.daiHistoryPage
    }
  },
  mutations: {
    setMetaCoreId(state, userId) {
      state.metaCoreUserId = userId
    },
    setAddedDashboardData(state, bool) {
      state.addedDashboardData = bool
    },
    setTransactionDaiHistory(state, history) {
      state.daiHistory = history
    },
    setMoreTransactionDaiHistory(state, history) {
      state.daiHistory = [...state.daiHistory, ...history]
    },
    isTransactionDaiHistoryFetched(state) {
      state.isDaiHistoryFetched = true
    },
    setDaiHistoryPage(state, data) {
      return state.daiHistoryPage = data
    },
    addTransactionDaiHistory(state, history) {
      state.daiHistory.unshift(history)
    },
    setWalletDai(state, daiToken) {
      state.balance.busd = daiToken
    },
    setInnerCoins(state, amount) {
      state.innerCoins = amount
    },
    setConnecting(state, val) {
      state.connecting = val
    },
    setProvider(state, provider) {
      state.provider = provider
    },
    setWeb3Instance(state) {
      const web3Instance = new Web3(state.provider)
      web3Instance.eth.transactionBlockTimeout = emulateSlowTx ? 10 : 250
      web3Instance.eth.transactionConfirmationBlocks = 1

      state.web3 = web3Instance

      if (process.env.MODE === 'development') {
        window.Web3 = web3Instance
      }
    },
    setContract(state, { value, name }) {
      state.contracts = {
        ...state.contracts,
        [name]: value,
      }
    },
    setNftControl(state, { version, value }) {
      state.contracts.nftControls[version] = value
    },
    setNftRegistry(state, { version, value }) {
      state.contracts.nftRegistries[version] = value
    },
    setRoyaltyNftContract(state, { version, index, value }) {
      if (state.contracts.royaltyNfts[version] === undefined) {
        state.contracts.royaltyNfts[version] = []
      }
      state.contracts.royaltyNfts[version][index] = value
    },
    setConnect(state, val) {
      state.connected = val

      setLocalStorageElement(LSTORAGE.connected, val)
    },
    setAccount(state, account) {
      state.account = account
      setLocalStorageElement('myAccount', account)
      // Sentry.setUser({ id: account })
    },
    setToken(state, token) {
      state.remember_token = token

      setLocalStorageElement(LSTORAGE.token, token)
    },
    setBalance(state, { value, symbol }) {
      state.balance = {
        ...state.balance,
        [symbol]: value,
      }
    },
    setMeta(state, { value, name }) {
      Log(`set meta ${name} : ${value}`)
      state.meta = {
        ...state.meta,
        [name]: value,
      }
    },
    setConnectionWallet(state, { name, type = 'default' }) {
      setLocalStorageElement(LSTORAGE.wallet, name)
      const timeStamp = new Date().getTime()
      state.connectionWallet = `${name}:${type}:${timeStamp}`
    },

    resetState(state) {
      state.provider = null
      state.web3 = null
      state.connecting = null
      state.connected = null
      state.account = null
      state.remember_token = null
      state.contracts = {
        main: null,
        sfc: null,
        mfs: null,
        control: null,
        registry: null,
        metacore: null,
        metapayment: null,
        uvCore: null,
        uvRegistry: null,

        sfcNew: null,
        sfc2: null,

        nftControls: [],
        nftRegistries: [],
        nftMigration: null,
        royaltyNfts: [],
        royaltyDistribution: null,
        distributionBannedNFT: null,
      }
      state.balance = {
        bnb: 0.0,
        sfc: 0.0,
        sfcNew: 0.0,
        sfc2: 0.0,
        busd: 0.0,
        energy: 0.0,
      }
      state.meta = {
        parent: null,
        nonce: 0,
        gasLimit: DICT.DEFAULT_GAS_LIMIT,
        gasPrice: DICT.DEFAULT_GAS_PRICE,
        maxFeePerGas: DICT.DEFAULT_GAS_PRICE,
      }
      state.connectionWallet = ''
    },
  },
  actions: cryptoActions,
  namespaced: true,
}
