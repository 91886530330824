<template>
  <div class="modal" :class="[visible && 'show', modifier]" @click="closeModal">
    <div class="modal__inner" @click.stop>

      <button class="modal__close btn-reset" type="button" @click="closeModal">
        <BaseIcon name='closeServiceIcon' @event='closeModal'/>
      </button>
      <div class="modal__title" v-if="title">{{ title }}</div>

      <slot></slot>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'

export default {
  components: { BaseIcon },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    modifier: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.2);
  backdrop-filter: blur(5px);
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease-in-out;
  &.show {
    opacity: 1;
    pointer-events: all;
  }
  &.wide {
    .modal__inner {
      max-width: 920px;
    }
  }
  &__inner {
    border-radius: 20px;
    max-width: 540px;
    width: 90%;
    padding: 50px 40px;
    background-color: var(--color-black-750);
    text-align: center;
    position: relative;
    overflow-y: auto;
    max-height: calc(100vh - 20px);
    @media screen and (max-width: 576px) {
      padding: 30px 15px;
      max-width: calc(100% - 30px);
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #23222c;
      border-radius: 8px;
    }
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.25s ease-in-out;
    svg {
      transform: scale(1.5);
    }
    &:hover {
      opacity: 0.7;
    }
  }
  &__title {
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    margin-bottom: var(--main-offset);
    @media screen and (max-width: 576px) {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: var(--second-offset);
    }
  }
}
</style>
