<template>
  <header
    class="headerLanding"
    :style='{top: isHeader ? 0 : "-70px", opacity: isHeader ? 1 : 0}'
    :class='{isContestSinglePage: getActiveClass}'
  >
    <div class="logo" @click.stop='toLanding'>
<!--      <router-link :to="{ name: 'landing' }">-->
<!--      </router-link>-->
      <lottie-animation
        path="./assets/files/logo_header.json"
        :auto-play="true"
        :loop="true"
        :loop-delay-min='minDelay'
        :speed="1"
        class="logo-anim"
      />
    </div>
    <ul class="menu" v-if="$route.path === '/metaverse'">
      <li v-for="section in sectionsMeta" :key="section.id" :class="{ active: section.id === activeSection }">
        <a :href="`#${section.id}`"> {{ $t(section.name) }}</a>
      </li>
    </ul>
    <ul class="menu">
      <li v-for="section in sections" :key="section.id" :class="{ active: section.id === activeSection }">
        <a :href="`#${section.id}`" @click="scrollTo(section.id)"> {{ $t(section.name) }}</a>
      </li>
    </ul>

    <div class="btn-group">
      <button class='btn-group__btn btn-connect' @click.stop="handleAuthClick" v-if='activeButtonConnect && !this.isUserConnected'>{{$t('connectWallet')}}</button>
      <router-link :to="{ name: 'dashboard' }" class='btn-group__btn' v-if='this.isUserConnected'>
        {{$t('landing.menu.home')}}
      </router-link>
      <div class="language">
        <span>{{ $i18n.locale.toUpperCase() }}</span>
        <ul>
          <li
            @click="switchLocale(lang)"
            v-for="(lang, i) in langs"
            :key="`Lang${i}`"
            :value="lang"
            class="select__option"
            data-select="option"
            :data-value="lang"
            :data-index="i"
          >
            {{ lang.toUpperCase() }}
          </li>
        </ul>
      </div>

      <button class="menu-btn btn-open" data-modal="menuModal" @click.stop='openService'></button>
    </div>
  </header>
</template>

<script>
import { LANGS, LSTORAGE } from '@/config/constants'
import {
  setLocalStorageElement,
  getLocalStorageElement,
} from '@/helpers/localstorage.js'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import VueScrollTo from 'vue-scrollto'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'

export default {
  name: 'Header',
  components: {
    LottieAnimation
  },
  data() {
    return {
      langs: LANGS,
      activeSection: '',
      sections: [
        { id: 'hero', name: 'landingNEW.menu.hero'  },
        { id: 'about', name:  'landingNEW.menu.about' },
        { id: 'services', name:  'landingNEW.menu.services' },
        { id: 'roadmap', name:  'landingNEW.menu.roadmap' },
        { id: 'statistics', name:  'landingNEW.menu.statistics' },
        { id: 'how', name:  'landingNEW.menu.how' },
        { id: 'reviews', name:  'landingNEW.menu.reviews' },
        { id: 'faq', name:  'landingNEW.menu.faq' }
      ],
      sectionsMeta: [
        { id: 'welcomeMeta', name: 'landing.menu.main'  },
        { id: 'videoMeta', name:  'landing.menuMeta.video' },
        { id: 'new-roadmapMeta', name:  'landing.menu.roadmap' },
        { id: 'play-nowMeta', name:  'landing.menuMeta.playNow' },
        { id: 'technical-characteristics', name:  'landing.menuMeta.technical-characteristics' },
        { id: 'gameplay-reviewMeta', name:  'landing.menuMeta.gameplay' },
        { id: 'featuresMeta', name:  'landing.menuMeta.features' },
        { id: 'join-us', name:  'landing.menuMeta.join-us' }
      ],
      isHeader: false,
      minDelay: 4000
    }
  },

  async mounted() {
    if (this.$route.path === "/") {
      setTimeout(() => {
        this.isHeader = true
      }, 3000)
    } else {
      this.isHeader = true
    }
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();

    if (+this.getAllUsersInfoVariables === 0) {
      await this.getUsersInfo()
    }
    if (localStorage.getItem('walletChanged') || localStorage.getItem('newRegister')) {
      this.resetState()
      await this.handleAuthClick()
      localStorage.removeItem('newRegister')
    }
    setLocalStorageElement(LSTORAGE.visited, true)
    setTimeout(() => {
      this.minDelay = 20000
    }, 4000)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapGetters('crypto', [
      'isConnected',
      'getParent',
      'getConnectionWallet',
      'getAccount'
    ]),
    ...mapGetters('post', ['getCategoryId']),
    ...mapGetters('user', ['getAllUsersInfoVariables']),

    getActiveClass() {
      return this.$route.name !== "landing" && this.$route.name !== "academy"
    },

    getActualPage() {
      return this.getCategoryId
    },
    isUserConnected() {
      return this.isConnected || getLocalStorageElement(LSTORAGE.connected)
    },
    showActualPages() {
      return this.$route.name === "academy" || this.$route.name === "showCategory" || this.$route.name === "showPost";
    },
    activeButtonConnect() {
      if (this.$route.path === '/') return true
      if (!this.getAccount) {
       if (this.$route.name !== "academy"
         || this.$route.name !== "showCategory"
         || this.$route.name !== "showPost") return true
      }
      return false
    }
  },
  methods: {
    ...mapMutations('ui', ['setConnectionModal']),
    ...mapMutations('crypto', ['resetState']),
    ...mapMutations('post', ['setCategoryId']),
    ...mapActions('crypto', ['checkConnect', 'logOut']),
    ...mapActions('user', ['getUsersInfo']),
    ...mapMutations('user', ['setUsersInfoFromLocal']),

    async toLanding() {
      this.$route.name !== "landing" ? await this.$router.push({name: "landing"}): ""
      await this.scrollTo('hero')
    },
    async scrollTo(elementId) {
      this.$route.name !== "landing" ? await this.$router.push({name: "landing"}): ""
      VueScrollTo.scrollTo(`#${elementId}`, 500, { easing: 'easeInOutQuart' });
    },
    toNewsPortal(link) {
      this.setCategoryId(link)
      if (this.$route.name !== 'academy') {
        this.$router.push({name: 'academy'})
      }
    },
    handleScroll() {
      const scrollPosition = window.scrollY;
      for (const section of this.sections) {
        const sectionElement = document.getElementById(section.id);
        if (sectionElement) {
          const sectionTop = sectionElement.offsetTop;
          const sectionBottom = sectionTop + sectionElement.offsetHeight;
          if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
            this.activeSection = section.id;
            break;
          }
        }
      }
    },
    openService() {
      this.$emit('openService')
    },
    async handleAuthClick() {
      this.setConnectionModal({ visible: true, type: 'connect' })
    },
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        localStorage.setItem('lang', locale)
        this.$i18n.locale = locale
        this.$moment.locale(locale)
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/assets/scss/mixins';
/*.newsPortalBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  margin-left: 40px;
  button {
    &:hover {
      color: var(--color-orange-900);
    }
  }
  &_active {
    color: var(--color-orange-900);
  }
}*/
.isContestSinglePage {
  position: relative !important;
  background: rgba(17, 17, 17, 0.96) !important;
  transition: none !important;
}
header.headerLanding {
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  padding: 12px 40px;
  border-bottom: 1px solid rgba(255, 255, 255, .1);
  background: rgba(255, 255, 255, .04);
  backdrop-filter: blur(20px);
  z-index: 97;
  transition: 1s ease;
  font-family: 'Inter', sans-serif;

  .logo-anim {
    display: flex;
    max-width: 112px;
  }

  .logo {
    width: 112px;
    height: 32px;
    cursor: pointer;

    a {
      @include flex-center;

      img {
        max-width: 100%;

        @media(max-width: 1025px) {
          max-width: none;
        }
      }

      span {
        font-weight: var(--font-400);
        @include font-20;

        @include _768 {
          display: none;
        }
      }

      @media(max-width: 1025px) {
        max-width: 32px;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .menu {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-left: 40px;

    li {
      a {
        color: #FFFFFF;
        @include font-14;
        transition: .3s ease;

        &:hover,
        &:focus {
          color: #EB7104;
        }
      }

      &.active {
        a {
          color: #EB7104;
        }
      }
    }

    @media(max-width: 1281px) {
      display: none;
    }
  }

  .btn-group {
    display: flex;
    gap: 16px;
    margin-left: auto;
    &__btn {
      background: #EB7104;
      padding: var(--indent-8) var(--indent-16);
      border-radius: var(--indent-8);
      @include font-12;
      transition: .3s ease;

      &:hover {
        background: #EB5704;
      }
      @media (max-width: 1025px) {
        display: none;
      }

      &.btn-connect {
        width: 123px;
      }
    }
    .language {
      display: flex;
      align-items: center;
      position: relative;
      font-weight: 500;
      letter-spacing: -0.056px;

      span {
        display: flex;
        align-items: center;
        height: 32px;
        padding: 0 16px;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, .2);
        cursor: pointer;
        @include font-14;
        font-weight: var(--font-500);
      }

      ul {
        position: absolute;
        top: 80%;
        left: 0;
        width: 100%;
        padding: 12px 16px;
        opacity: 0;
        pointer-events: none;

        li {
          cursor: pointer;
        }

        li + li {
          margin-top: 8px;
        }
      }

      &:hover {
        ul {
          top: 100%;
          opacity: 1;
          pointer-events: auto;
        }
      }

      @media(max-width: 1025px) {
        display: none;
      }
    }

    .menu-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      background: rgba(255, 255, 255, .05);
      border-radius: 8px;

      &::before {
        width: 24px;
        height: 24px;
        background: url('~@/assets/images/homenew/menu-icon.svg') center center no-repeat;
        content: '';

        @media(max-width: 1025px) {
          width: 40px;
          height: 32px;
          background: url('~@/assets/images/homenew/menu-icon-xs.svg') center center no-repeat;
        }
      }

      @media(max-width: 1025px) {
        padding: 0;
      }
    }

    .connect {
      @media(max-width: 1025px) {
        display: none;
      }
    }
  }

  @media(max-width: 1025px) {
    height: 56px;
    padding: 16px;
  }
}

//.loaded {
//  .headerLanding {
//    top: 0;
//    opacity: 1;
//  }
//}
</style>
