import axios from 'axios'
import { getLocalStorageElement } from '@/helpers/localstorage'
import { LSTORAGE } from '@/config/constants'
import store from '@/store'
import { isDevelopment } from '@/config/isDevelopment'

const handleError = async (error) => {
  if (error.response && !error.response.config.url.includes('auth')) {
    if (parseInt(error.response && error.response.status) === 401) {
      await store.dispatch('crypto/logOut')
    }
  }
}
// create instance
const axiosNewApi = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_API_PROTOCOL}://${isDevelopment ? process.env.VUE_APP_BASE_API_URL_TEST : process.env.VUE_APP_BASE_API_URL_PROD}/api/v1`,
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
})
axiosNewApi.interceptors.request.use(
  async (x) => {
    let lsToken = getLocalStorageElement(LSTORAGE.token)
    x.headers = {
      ...x.headers,
      Authorization: `Bearer ${lsToken}`,
    }

    // console.log(`${x.method.toUpperCase()} | ${x.url}`, x.params, x.data)

    return x
  },
  async (error) => {
    await handleError(error)
    console.error(error)
    return Promise.reject(error)
  }
)
const axiosApiWs = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_API_PROTOCOL}://${isDevelopment ? process.env.VUE_APP_BASE_API_URL_WS_TEST : process.env.VUE_APP_BASE_API_URL_WS}/api/v1`,
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
})
axiosApiWs.interceptors.request.use(
  async (x) => {
    let lsTokenWs = getLocalStorageElement(LSTORAGE.token)
    x.headers = {
      ...x.headers,
      Authorization: `Bearer ${lsTokenWs}`,
    }
    // console.log(`${x.method.toUpperCase()} | ${x.url}`, x.params, x.data)
    return x
  },
  async (error) => {
    await handleError(error)
    console.error(error)
    return Promise.reject(error)
  }
)
const initAxios = (app) => {
  app.prototype.$axiosNewApi = axiosNewApi
}

export {
  initAxios,
  axiosNewApi as $axiosNewApi,
  axiosApiWs as $axiosApiWs,
}
