<template>
  <div class="modal" id="walletModalInner" @click.stop :class='!isModal && "closeModalService"'>
    <button class="btn-close">
      <BaseIcon name='closeServiceIcon' @event='closeServiceModal' ver-align />
    </button>
    <div class="modal-header">
      <button class="modal-back" @click.stop='backServiceModal'>
        <BaseIcon name='pagArrowLeft' @event='backServiceModal' ver-align />
        {{ $t('matrix.back') }}
      </button>
      <div class="modal-header__title">
        <template v-if='daiPropsName === "inner"'>
          {{$t('dashboardInfo.modalWallet.innerBalance')}}
        </template>
        <template v-else>
          {{$t('dashboardInfo.modalWallet.walletBalance')}}
        </template>
      </div>
    </div>
    <div class="modal-content">
      <div class="wallet__total">
          <span>
            {{$t('dashboardInfo.modalWallet.balance')}} DAI
          </span>
        <div class='wallet__total-balanceIcon'>
          $ {{ innerDaiProps }}
          <BaseIcon name='addCashToBalance' ver-align/>
          <span class="tooltip">{{ $t('nfts.comingSoon') }}</span>
        </div>
        <span>
          ${{ innerDaiProps }}
        </span>
      </div>
      <div class="wallet__btn-group innerGroup">
        <button class="wallet-receive disabledBtn">
          <BaseIcon name='sendToken' ver-align/>
          {{ $t('help.chat.send') }}
          <span class="tooltip">{{ $t('nfts.comingSoon') }}</span>
        </button>
        <button class="wallet-send disabledBtn">
          <BaseIcon name='receiveToken' ver-align/>
          {{ $t('dashboardInfo.modalWallet.deposit') }}
          <span class="tooltip">{{ $t('nfts.comingSoon') }}</span>
        </button>
        <button class="wallet-send" @click.stop='transactionModal'>
          <BaseIcon name='transferToken' ver-align @event='transactionModal'/>
          {{ $t('dashboardInfo.modalWallet.transfer.title') }}
        </button>
      </div>
      <div class="wallet__balances">
        <div class="balances__title">
          {{ $t('dashboardInfo.modalWallet.transactionHistory') }}
        </div>
        <div class="balances__item historyBlockHeight" style='padding-bottom: 40px;'>
          <div class="balances__item" v-if='getDaiHistory.length !== 0'>
            <div
              v-for='history in getDaiHistory'
              :key='history.id'
            >
              <div
                class="balances__item-token"
              >
                <div class="img" v-if='history.type === "wallet"'>
                  <BaseIcon name='depositHistory' ver-align/>
                </div>
                <div class="img" v-if='history.type === "inner"'>
                  <BaseIcon name='sendHistory' ver-align/>
                </div>
                <div class="caption">
                  <div class="name">
                    <p>{{ history.title }}</p>
                    <div class="date">
                      {{ formattedDate(history.created_at) }}
                    </div>
                  </div>
                  <div class="qty">
                    {{history.amount.toFixed(1)}} {{history.amount_type}}
                    <span>${{history.amount.toFixed(1)}}</span>
                  </div>
                </div>
              </div>
              <div class='tokenBorder'></div>
            </div>
            <UiLoader v-if='moreDataFetchLoading' theme="block" :loading="true" />
            <div class='moreBtn' v-if='getDaiHistoryPage && getDaiHistoryPage.current_page < getDaiHistoryPage.last_page'>
              <button :disabled='moreDataFetchLoading' @click.stop='getMoreTransactionsHistory'>More...</button>
            </div>
          </div>
          <div class='empty-transaction-history' v-else>
            <base-icon name='comingSoon' ver-align/>
            <p>No transactions for the wallet yet.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    isModal: {
      type: Boolean,
      default() {
        return false
      }
    },
    innerDaiProps: {
      type: Number
    },
    daiPropsName: {
      type: String
    }
  },
  components: {
    BaseIcon
  },
  data() {
    return {
      moreDataFetchLoading: false
    }
  },
  async mounted() {
    if (!this.getDaiHistoryFetched) {
      await this.getData()
    }
  },
  computed: {
    ...mapGetters('crypto', ['getDaiHistory', 'getDaiHistoryFetched','getDaiHistoryPage'])
  },
  methods: {
    ...mapActions('crypto', ['getTransactionDaiHistory', 'getMoreTransactionDaiHistory']),

    async getData() {
      try {
        this.moreDataFetchLoading = true
        await this.getTransactionDaiHistory({page: 1})
      } catch (e) {
        console.log(e)
      } finally {
        this.moreDataFetchLoading = false
      }
    },
    async getMoreTransactionsHistory() {
      if (this.getDaiHistoryPage.current_page >= this.getDaiHistoryPage.last_page) return
      try {
        this.moreDataFetchLoading = true
        await this.getMoreTransactionDaiHistory({page: this.getDaiHistoryPage.current_page + 1})
      } catch (e) {
        console.log(e)
      } finally {
        this.moreDataFetchLoading = false
      }
    },
    transactionModal() {
      this.$emit('innerEmitTransaction')
    },
    closeServiceModal() {
      this.$emit('closeInner')
    },
    backServiceModal() {
      this.$emit('innerEmit')
    },
    formattedDate(date) {
      return this.$moment(date).format("DD.MM.YY")
    },
  }
}
</script>