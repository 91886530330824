<template>
  <header class="headernew">
    <div class="logo">
      <router-link :to="{ name: 'landing' }">
        <svg-vue icon="logo" class="w-5 h-5" />
        <span>
          Meta Force
        </span>
      </router-link>
    </div>
    <!--    <ul class="menu" v-if="$route.path === '/metaverse'">-->
    <!--      <li v-for="section in sectionsMeta" :key="section.id" :class="{ active: section.id === activeSection }">-->
    <!--        <a :href="`#${section.id}`"> {{ $t(section.name) }}</a>-->
    <!--      </li>-->
    <!--    </ul>-->
    <!--    <ul class="menu" v-else>-->
    <!--      <li v-for="section in sections" :key="section.id" :class="{ active: section.id === activeSection }">-->
    <!--        <a :href="`#${section.id}`"> {{ $t(section.name) }}</a>-->
    <!--      </li>-->
    <!--    </ul>-->

    <InfoBlockUsers v-if='$route.path === "/"'/>
    <!--    <div class='newsPortalBtn'-->
    <!--         v-show='showActualPages'-->
    <!--    >-->
    <!--      <button @click.stop='toNewsPortal(1)' :class='getActualPage === 1 && "newsPortalBtn_active"'>{{ $t('academy.news') }}</button>-->
    <!--      <button @click.stop='toNewsPortal(5)' :class='getActualPage === 5 && "newsPortalBtn_active"'>{{ $t('academy.events') }}</button>-->
    <!--    </div>-->
    <div class="btn-group">
      <button class='btn-group__btn' @click.stop="handleAuthClick" v-if='activeButtonConnect && !this.isUserConnected'>{{$t('connectWallet')}}</button>
      <router-link :to="{ name: 'dashboard' }" class='btn-group__btn' v-if='this.isUserConnected && ($route.path === "/" || $route.path === "/reviews")'>
        {{$t('landing.menu.login')}}
      </router-link>
      <div class="language">
        <span>{{ $i18n.locale.toUpperCase() }}</span>
        <ul>
          <li
            @click="switchLocale(lang)"
            v-for="(lang, i) in langs"
            :key="`Lang${i}`"
            :value="lang"
            class="select__option"
            data-select="option"
            :data-value="lang"
            :data-index="i"
          >
            {{ lang.toUpperCase() }}
          </li>
        </ul>
      </div>

      <button class="menu-btn btn-open" data-modal="menuModal" @click.stop='openService'></button>
    </div>
  </header>
</template>

<script>
import { LANGS, LSTORAGE } from '@/config/constants'
import {
  setLocalStorageElement,
  getLocalStorageElement,
} from '@/helpers/localstorage.js'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import InfoBlockUsers from '../InfoBlockUsers.vue'

export default {
  name: 'Header',
  components: { InfoBlockUsers},
  // components: { ConnectModal },
  data() {
    return {
      langs: LANGS,
      activeSection: '', // Идентификатор активной секции
      sections: [
        { id: 'welcome', name: 'landing.menu.main'  },
        { id: 'description', name:  'landing.menu.about' },
        { id: 'slider', name:  'landing.menu.service' },
        { id: 'roadmap', name:  'landing.menu.roadmap' },
        { id: 'news', name:  'landing.menu.news' },
        { id: 'academy', name:  'landing.menu.academy' },
        { id: 'reviews', name:  'landing.menu.reviews' },
        { id: 'faq', name:  'landing.menu.faq' }
      ],
      sectionsMeta: [
        { id: 'welcomeMeta', name: 'landing.menu.main'  },
        { id: 'videoMeta', name:  'landing.menuMeta.video' },
        { id: 'new-roadmapMeta', name:  'landing.menu.roadmap' },
        { id: 'play-nowMeta', name:  'landing.menuMeta.playNow' },
        { id: 'technical-characteristics', name:  'landing.menuMeta.technical-characteristics' },
        { id: 'gameplay-reviewMeta', name:  'landing.menuMeta.gameplay' },
        { id: 'featuresMeta', name:  'landing.menuMeta.features' },
        { id: 'join-us', name:  'landing.menuMeta.join-us' }
      ]
    }
  },

  async mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();

    if (+this.getAllUsersInfoVariables === 0) {
      //   this.setUsersInfoFromLocal()
      // } else {
      await this.getUsersInfo()
    }
    if (localStorage.getItem('walletChanged') || localStorage.getItem('newRegister')) {
      this.resetState()
      await this.handleAuthClick()
      localStorage.removeItem('newRegister')
    }
    setLocalStorageElement(LSTORAGE.visited, true)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapGetters('crypto', [
      'isConnected',
      'getParent',
      'getConnectionWallet',
      'getAccount'
    ]),
    ...mapGetters('post', ['getCategoryId']),
    ...mapGetters('user', ['getAllUsersInfoVariables']),
    getActualPage() {
      return this.getCategoryId
    },
    isUserConnected() {
      return this.isConnected || getLocalStorageElement(LSTORAGE.connected)
    },
    showActualPages() {
      return this.$route.name === "academy" || this.$route.name === "showCategory" || this.$route.name === "showPost";
    },
    activeButtonConnect() {
      if (this.$route.path === '/') return true
      if (!this.getAccount) {
        if (this.$route.name !== "academy"
          || this.$route.name !== "showCategory"
          || this.$route.name !== "showPost") return true
      }
      return false
    }
  },
  methods: {
    ...mapMutations('ui', ['setConnectionModal']),
    ...mapMutations('crypto', ['resetState']),
    ...mapMutations('post', ['setCategoryId']),
    ...mapActions('crypto', ['checkConnect', 'logOut']),
    ...mapActions('user', ['getUsersInfo']),
    ...mapMutations('user', ['setUsersInfoFromLocal']),

    toNewsPortal(link) {
      this.setCategoryId(link)
      if (this.$route.name !== 'academy') {
        this.$router.push({name: 'academy'})
      }
    },
    handleScroll() {
      const scrollPosition = window.scrollY;
      // const windowHeight = window.innerHeight;

      for (const section of this.sections) {
        const sectionElement = document.getElementById(section.id);
        if (sectionElement) {
          const sectionTop = sectionElement.offsetTop;
          const sectionBottom = sectionTop + sectionElement.offsetHeight;
          if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
            this.activeSection = section.id;
            break;
          }
        }
      }
    },
    openService() {
      this.$emit('openService')
    },
    async handleAuthClick() {
      console.log('111')
      this.setConnectionModal({ visible: true, type: 'connect' })
    },
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        localStorage.setItem('lang', locale)
        this.$i18n.locale = locale
        this.$moment.locale(locale)
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/assets/scss/mixins';
.newsPortalBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  margin-left: 40px;
  button {
    &:hover {
      color: var(--color-orange-900);
    }
  }
  &_active {
    color: var(--color-orange-900);
  }
}
header.headernew {
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  padding: 16px 40px;
  border-bottom: 1px solid rgba(255, 255, 255, .1);
  background: rgba(255, 255, 255, .04);
  backdrop-filter: blur(20px);
  z-index: 97;
  transition: none;

  .logo {
    a {
      @include flex-center;
      gap: var(--indent-8);

      img {
        width: var(--indent-32);
        height: var(--indent-32);
      }

      span {
        font-weight: var(--font-400);
        @include font-20;

        @include _768 {
          display: none;
        }
      }
    }
  }

  .menu {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-left: 40px;

    li {
      a {
        letter-spacing: -0.056px;
        color: #FFFFFF;

        &:hover,
        &:focus {
          color: #EB7104;
        }
      }

      &.active {
        a {
          color: #EB7104;
        }
      }
    }

    @media(max-width: 1025px) {
      display: none;
    }
  }

  .btn-group {
    display: flex;
    gap: 16px;
    margin-left: auto;
    &__btn {
      background: var(--color-orange-900);
      padding: var(--indent-6) var(--indent-12);
      border-radius: var(--indent-8);
      &:hover {
        background: var(--color-orange-800);
      }
      @media (max-width: 1025px) {
        display: none;
      }
    }
    .language {
      display: flex;
      align-items: center;
      position: relative;
      font-weight: 500;
      letter-spacing: -0.056px;

      span {
        display: flex;
        align-items: center;
        height: 32px;
        padding: 0 16px;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, .2);
        cursor: pointer;
      }

      ul {
        position: absolute;
        top: 80%;
        left: 0;
        width: 100%;
        padding: 12px 16px;
        opacity: 0;
        pointer-events: none;

        li {
          cursor: pointer;
        }

        li + li {
          margin-top: 8px;
        }
      }

      &:hover {
        ul {
          top: 100%;
          opacity: 1;
          pointer-events: auto;
        }
      }

      @media(max-width: 1025px) {
        display: none;
      }
    }

    .menu-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      background: rgba(255, 255, 255, .05);
      border-radius: 8px;

      &::before {
        width: 24px;
        height: 24px;
        background: url('~@/assets/images/homenew/menu-icon.svg') center center no-repeat;
        content: '';

        @media(max-width: 1025px) {
          width: 40px;
          height: 32px;
          background: url('~@/assets/images/homenew/menu-icon-xs.svg') center center no-repeat;
        }
      }

      @media(max-width: 1025px) {
        padding: 0;
      }
    }

    .connect {
      @media(max-width: 1025px) {
        display: none;
      }
    }
  }

  @media(max-width: 1025px) {
    height: 64px;
    padding: 16px;
  }
}
</style>
