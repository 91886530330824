<template>
  <nav aria-label="breadcrumbs">
    <ul v-if='$route.name !== "reputationInfoPage"'>
        <li v-if="forceIdDemo" class="forceidDemoMode"><p>{{ forceIdDemo }}</p><BaseIcon name="arrowRight" /></li>
        <li v-for="(crumb, index) in  crumbs" :key="index">
          <button
            @click="toRoute(crumb)"
            :class="crumb.isLast === true && 'span'"
          ><BaseIcon name="arrowRight" v-if="index !== 0"/>
            <p v-if="index !== 3 && crumb.label === '1'">News</p>
            <p v-else-if="index !== 3 && crumb.label === '5'">Events</p>
            <p v-else-if="index !== 3 && crumb.label !== 'My_profile' && crumb.label !== 'User_Search'">{{ crumb.label }}</p>
            <p v-else-if="crumb.label === 'My_profile'">Profile</p>
            <p v-else-if="crumb.label === 'User_Search'">User search</p>
          </button>
        </li>
    </ul>
    <ul v-else>
     <li>
       <button @click.stop='$router.go(-1)'>
         <BaseIcon name="arrowRight" class='reputationInfoArrow' ver-align/>Back
       </button>
     </li>
    </ul>
  </nav>
</template>

<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'
import { sliceString } from '@/helpers/sliceString'

export default {
  components: {
    BaseIcon,
  },
  data() {
    return {
      forceIdDemo: ''
    }
  },
  mounted() {
    if (this.$route.query.account) this.forceIdDemo = this.$route.query.account
  },
  computed: {
        crumbs() {
          const router = this.$route.path;
          const paths = router.split('/').filter(p => !!p)
          return paths.map((path, index) => {
            const isLast = index === paths.length - 1;
            let label = path;
            const routePath = `/${paths.slice(0, index + 1).join('/')}`;
            if (label.length > 15) {
              label = sliceString(label) 
            }
            return { label, route: routePath, isLast };
          })
        },
  },
  methods: {
    toRoute(route) {
      if (route.isLast) return
      if (route.label === 'Profile') return
      if (route.label === this.$i18n.locale) return
      this.$router.push(route.route)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/mixins";
.breadcrumbs {
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  ul {
    color: #666666;
    width: 100%;
    display: flex;
    flex-direction: row;
    .forceidDemoMode {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 5px;
      p {
          padding: 0;
          line-height: 20px;
      }
      .ico {
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--indent-16);
        height: var(--indent-16);
        margin: 0 var(--indent-8);
      }
      &:hover {
        color: #666666;
      }
      @media (max-width: 500px) {
        p {
          margin: 0;
        }
      }
    }
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      transition: color 600ms;
      &:hover {
        color: var(--color-black-50);
      }
      button {
        display: flex;
        align-items: center;
        p {
          padding: 0;
          //text-transform: capitalize;
          @include font-16;
        }
        p + p {
          margin: 0 10px;
        }
        .ico {
          display: flex;
          align-items: center;
          justify-content: center;
          width: var(--indent-16);
          height: var(--indent-16);
          margin: 0 var(--indent-8);
        }
        @media (max-width: 500px) {
          p {
            margin: 0;
          }
        }
      }
    }
  }
  .reputationInfoArrow {
    transform: rotate(180deg);
  }
}

.span {
    color: var(--color-black-50);
}
</style>