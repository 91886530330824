<template>
  <nav class="nav" :class="isClosed && 'marginNav'">
    <div class="nav-inner">
      <div class="nav-top">
        <ul class="nav-list nav-list-top list-reset" v-show='!isClosed'>
          <li
            v-for="item in renderItems"
            :key="item.link"
            class="nav-list__item"
            :class="$route.name === item.link ? 'nav-list__currentPage' : ''"
          >
            <p @click='toPage(item)' class="nav-list__link">
              <BaseIcon @event='toPage(item)' :name="item.iconName" class="ico--indent" verAlign />
              <span v-show="!isClosed">{{ $t(item.title) }}</span>
              <span v-if="item.link === 'messenger'" class="beta">beta</span>
              <span v-if="item.link === 'messenger' && getTotalUnread && getToken" class="bellNotice">{{ getTotalUnread }}</span>
<!--              <span v-show="!isClosed" class="bellNotice" v-if="count && getToken && item.link === 'help'">{{ count }}</span>-->
              <span class="bellNotice" v-if="(ticketUpdate || unreadHelpMessages ) && item.link === 'help'">{{ unreadHelpMessages }}</span>
            </p>
          </li>
          <li class="nav-list__item" @click.stop='openSocial' :class='isSocial && "isSocialBlock"'>
            <p class="nav-list__link">
              <BaseIcon @event='openSocial' :name="socialMedia.iconName" class="ico--indent" verAlign :class='isSocial && "isSocialIcon"' />
              <span v-show="!isClosed">{{ $t(socialMedia.title) }}</span>
            </p>
            <BaseIcon @event='openSocial' name='arrowRight' v-show="!isClosed" :class="isSocial && 'isSocialMediaOpen'" />
          </li>
          <li v-show='isSocial && !isClosed'><SocialLinks /></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from '@/components/UI/BaseIcon.vue'
import SocialLinks from '@/components/UI/SocialLinks.vue'
export default {
  components: {
    SocialLinks,
    BaseIcon,
  },
  props: {
    isClosed: {
      type: Boolean,
    },
    count: {
      type: Number,
    },
  },
  data() {
    return {
      items: [
        {
          title: 'menu.mainPage',
          link: 'dashboard',
          iconName: 'home',
          auth: true,
        },
        {
          title: 'menu.forceSystem',
          link: 'classic',
          iconName: 'forceSystems',
          auth: true,
        },
        {
          title: 'menu.uniteVerse',
          link: 'uniteverse',
          iconName: 'uniteverse',
          auth: true,
        },
        {
          title: 'menu.forceCoin',
          link: 'forceCoin',
          iconName: 'forceCoin',
          auth: true,
        },
        {
          title: 'menu.referral',
          link: 'systems_referral',
          iconName: 'myTeam',
          auth: true,
        },
        {
          title: 'menu.messenger',
          link: 'messenger',
          iconName: 'messenger',
          auth: true,
        },
        {
          title: 'menu.search',
          iconName: 'search',
          link: 'search',
          auth: true,
        },
        // {
        //   title: 'menu.userSettings',
        //   iconName: 'profile',
        //   link: 'userSettings',
        //   auth: true,
        // },
        {
          title: 'menu.help',
          iconName: 'help',
          link: 'help',
          auth: true,
        },
        {
          title: 'menu.settings',
          iconName: 'settings',
          link: 'settings',
          auth: true,
        },
      ],
      socialMedia: {
        title: 'menu.socialMedia',
        iconName: 'socialMedia',
      },
      isSocial: false
    }
  },
  computed: {
    ...mapGetters('crypto', ['isActiveWallet', 'getToken']),
    ...mapGetters('messenger', ['getTotalUnread']),
    ...mapGetters('ticket', ['ticketUpdate', 'unreadHelpMessages']),
    renderItems() {
      if (this.$route.query.account) {
        return this.items.filter((x) => x.link !== 'uniteverse' && x.link !== 'forceCoin' && x.auth && x.link !== 'messenger' && x.auth)
      }
      if (this.isActiveWallet && this.getToken) {
        return this.items.filter((x) => x.auth)
      }
      return this.items.filter((x) => !x.auth)
    },
  },
  methods: {
    toPage(item) {
      if (this.$route.name === item.link) return
      this.$router.push({ name: item.link })
    },
    openSocial() {
      this.isSocial = !this.isSocial
    }
  },
}
</script>

<style lang="scss">
.beta {
  border-radius: 4px;
  color: white;
  background: #5E5CE6;
  font-size: 10px;
  padding:0 6px;
  margin: 0;
}
.isSocialBlock {
  background: var(--color-white-5);

  span {
    color: white;
  }

  .isSocialIcon {
    svg path {
      fill-opacity: 1;
    }
  }
}

.nav {
  position: relative;
  padding: 0 var(--indent-16);
  transition: 0.3s all ease-in-out;
  white-space: nowrap;
  opacity: 1;

  @media (max-width: 991px) {
    padding-left: var(--indent-30);
    padding-top: var(--indent-20);
  }

  &-list__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: .3s ease;
    border-radius: var(--indent-8);
    min-width: 247px;
    span {
      display: flex;
      svg {
        path {
          transition: .3s ease;
        }
      }
    }
    .bellNotice {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 2px 8px;
      font-size: var(--font-12);
      min-width: var(--indent-20);
      height: var(--indent-20);
      border-radius: 12px;
      font-weight: var(--font-600);
      color: var(--color-white);
      background: #EB7104;
    }
    .ico {
      margin-right: var(--indent-16);
    }
    &:hover {
      background: var(--color-white-5);
    }

    & > span.ico {
      transition: .3s ease;
    }

    &.isSocialBlock {
      & > span.ico {
        transform: rotate(90deg);
      }
    }
  }
  &-bottom {
    margin-top: auto;
    display: flex;
    flex-direction: column;
  }
  &-list__currentPage {
    background: var(--color-white-5);
    p {
      span {
        color: #ffffff;
        svg {
          path {
            fill: #ffffff;
            fill-opacity: 1;
          }
        }
      }
    }
  }
  &-list__link {
    display: flex;
    align-items: center;
    gap: var(--indent-8);
    width: 100%;
    padding: var(--indent-8);
    font-weight: var(--font-500);
    font-size: var(--font-15);
    line-height: var(--line-24);
    letter-spacing: var(--spacing-15);
    color: var(--color-white-40);
    cursor: pointer;

    span {
      transition: .3s ease;
    }

    .ico {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin: 0;
    }

    &.router-link-active {
      color: var(--color-black-100);
      cursor: pointer;
    }

  }

  &-list-top {
    display: flex;
    flex-direction: column;
    gap: var(--indent-4);
  }

  &-list-top,
  &-sign-out {
    margin-bottom: var(--indent-15);
    @media screen and (max-width: 991px) {
      border: 0;
    }
  }
  &-sign-out {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.hideDesctopMenu {
  .nav {
    opacity: 0;
  }
}

.hide-sidebar {
  width: 29px;
  height: 29px;
  border-radius: 100%;
  border: 2px solid var(--color-white);
  // margin-left: auto;
  @media screen and (max-width: 991px) {
    display: none;
  }
}

.ico--indent {
  margin-right: var(--indent-15);
}
</style>
