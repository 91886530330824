<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import BaseIcon from '@/components/UI/BaseIcon.vue'

export default {
  name: 'HackedWalletModal',
  data() {
    return {
      successLocked: false,
      btnLoad: false
    }
  },
  components: { BaseIcon },
  computed: {
    ...mapGetters('ui', ['getIsHackedWalletModal'])
  },
  methods: {
    ...mapMutations('ui',['setIsHackedWalletModal']),
    ...mapMutations('user',['setInnerBlocked']),
    ...mapActions('crypto', ['blockInnerBalance']),
    closeHackedWalletModal() {
      this.setIsHackedWalletModal(false)
    },
    async lockInner() {
      try {
        this.btnLoad = true
        const [err, resolve] = await this.blockInnerBalance()
        if (err) {
          throw err
        }
        if (resolve) {
          this.successLocked = true
          this.setInnerBlocked(true)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.btnLoad = false
      }
    },
    done() {
      this.successLocked = false
      this.closeHackedWalletModal()
    }
  }
}
</script>

<template>
  <UiModalService :visible="getIsHackedWalletModal" @closeModal="closeHackedWalletModal">
    <div class="modal" id="walletModal" @click.stop :class='!getIsHackedWalletModal && "closeModalService"'>
      <button class="btn-close">
        <BaseIcon name='closeServiceIcon' @event='closeHackedWalletModal' ver-align />
      </button>
      <div class='LockWrapper'>
        <span class='LockWrapper-title'>{{ $t('lockInnerBalance.modalTitle') }}</span>
        <div class='LockWrapper-progress'>
          <p class='LockWrapper-progress__info'><span>{{ $t('lockInnerBalance.modalProgress') }} 1</span><span>{{ $t('lockInnerBalance.modalOf') }} 3</span></p>
          <p class='LockWrapper-progress__bar'><span class='LockWrapper-progress__bar__fill'></span></p>
        </div>
        <div class='LockWrapper-desc'>{{ $t('lockInnerBalance.modalDesc1') }}</div>
        <div class='LockWrapper-desc'>
          <span>With a locked inner balance you cannot:</span> <br>
          1. Withdraw tokens from your internal balance to your wallet <br>
          2. Buy Royalty NFTs <br>
          3. Buy Tactile Goods NFTs <br>
          4. Put NFTs on sale <br>
          5. Swap <br>
          6. Change profit receiving from inner to wallet
        </div>
        <div class='LockWrapper-desc'>
          <span>With a locked inner balance you can</span> <br>
          1. Receive income from Uniteverse <br>
          2. Receive income from the Loyalty program <br>
          3. Withdraw Royalty rewards to your internal balance <br>
          4. Buy Uniteverse Tiers <br>
          5. Buy ForceCoin
        </div>
        <div class='LockWrapper-desc'>
          {{ $t('lockInnerBalance.modalDesc2') }}
        </div>
        <button class='LockWrapper-btn' @click.stop='lockInner'>
          <template v-if='!btnLoad'>
            {{ $t('lockInnerBalance.modalTitle') }}
          </template>
          <UiLoader v-else :loading="true" />
        </button>
      </div>
    </div>
    <div class="modal" id="walletModalInner" @click.stop :class='!successLocked && "closeModalService"'>
      <div class='lockedInner'>
        <div>
          <BaseIcon name='lockedInner' ver-align></BaseIcon>
        </div>
        <div class='lockedInner-title'>{{ $t('lockInnerBalance.lockedTitle') }}</div>
        <div class='lockedInner-desc'>{{ $t('lockInnerBalance.lockedDesc') }}</div>
        <button class='lockedInner-btn' @click.stop='done'>{{ $t('lockInnerBalance.lockedDone') }}</button>
      </div>
    </div>
  </UiModalService>
</template>

<style lang='scss'>
.lockedInner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 16px;
  &-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0;
    text-align: center;
  }
  &-desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
  }
  &-btn {
    background: #EB7104;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
    width: 180px;
  }
}
.LockWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  overflow-y: auto;
  &-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
  }
  &-progress {
    width: 100%;
    display: flex;
    flex-direction: column;
    &__info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &__bar {
      width: 100%;
      height: 8px;
      border-radius: 7px;
      background: rgba(255, 255, 255, 0.05);
      display: flex;
      justify-content: flex-start;
      overflow: hidden;
      &__fill {
        background: white;
        width: 33%;
        height: 8px;
      }
    }
  }
  &-desc {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    span {
      font-size: 16px;
      font-weight: 500;
    }
  }
  &-btn {
    background: #EB7104;
    width: 100%;
    border-radius: 8px;
    padding: 8px 16px;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
  }
}
</style>