<template>
  <section class="modal" v-if="connectionModalVisible">
    <button @click="closeModal" class="modal-close btn-close">
      <BaseIcon name='closeServiceIcon' ver-align @event='closeModal' />
    </button>
    <div class="modal-title">
      {{ title }}
    </div>
    <div class="modal-content">
      <div class="modal-list">
        <li
          class="modal-list__btn"
          :class="[!metaMaskAvailable && 'disabled']"
          @click="handleWalletSelect('metamask')"
        >
          <div class="modal-list__img">
            <svg-vue icon="wallet_metamask" />
          </div>
          <div class="modal-list__data">
            <h4>MetaMask</h4>
            <p>
              {{ this.$t('connectModal.desktop') }} /
              {{ this.$t('connectModal.dapp') }}
            </p>
          </div>
        </li>
        <li
          class="modal-list__btn"
          :class="[!tokenPocketAvailable && 'disabled']"
          @click="handleWalletSelect('tokenPocket')"
        >
          <div class="modal-list__img">
            <svg-vue icon="wallet_tokenpocket" />
          </div>
          <div class="modal-list__data">
            <h4>Token Pocket</h4>
            <p>
              {{ this.$t('connectModal.desktop') }} /
              {{ this.$t('connectModal.dapp') }}
            </p>
          </div>
        </li>
        <li
          class="modal-list__btn"
          :class="[!coinbaseAvailable && 'disabled']"
          @click="handleWalletSelect('metamask')"
        >
          <div class="modal-list__img">
            <svg-vue icon="wallet_coinbase" />
          </div>
          <div class="modal-list__data">
            <h4>Coinbase</h4>
            <p>
              {{ this.$t('connectModal.desktop') }} /
              {{ this.$t('connectModal.dapp') }}
            </p>
          </div>
        </li>
        <li
          class="modal-list__btn"
          :class="[!exodusAvailable && 'disabled']"
          @click="handleWalletSelect('metamask')"
        >
          <div class="modal-list__img">
            <svg-vue icon="wallet_exodus" />
          </div>
          <div class="modal-list__data">
            <h4>Exodus</h4>
            <p>
              {{ this.$t('connectModal.desktop') }} /
              {{ this.$t('connectModal.dapp') }}
            </p>
          </div>
        </li>
        <li
          v-if='trustAvailable'
          class="modal-list__btn"
          :class="[!trustAvailable && 'disabled']"
          @click="handleWalletSelect('trust')"
        >
          <div class="modal-list__img">
            <svg-vue icon="wallet_trustwallet" />
          </div>
          <div class="modal-list__data">
            <h4>Trust Wallet</h4>
            <p>
              {{ `${this.$t('connectModal.dapp')}` }}
            </p>
          </div>
        </li>
        <li
          class="modal-list__btn"
          @click="handleWalletSelect('walletconnect')"
        >
          <div class="modal-list__img">
            <svg-vue icon="wallet_walletconnect" />
          </div>
          <div class="modal-list__data">
            <h3>WalletConnect</h3>
            <p>
              {{ `${this.$t('connectModal.anyApp')}` }}
            </p>
          </div>
        </li>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { nullEmptyHash } from '@/helpers/matrix'
import { SentryLog } from '@/helpers/dev'
import BaseIcon from '@/components/UI/BaseIcon.vue'
import { getLocalStorageElement, setLocalStorageElement } from '@/helpers/localstorage'

export default {
  components: { BaseIcon },
  data() {
    return {}
  },
  // mounted() {
  //   const { ethereum } = window
  //   console.log()
  // },
  computed: {
    ...mapGetters('ui', ['connectionModal', 'connectionModalVisible']),
    ...mapGetters('crypto', ['isConnecting', 'getParent']),

    metaMaskAvailable() {
      const { ethereum } = window
      try {
        return ethereum && ethereum.isMetaMask
      } catch (e) {
        return false
      }
    },
    tokenPocketAvailable() {
      const { ethereum } = window
      try {
        return ethereum && ethereum && ethereum.isTokenPocket
      } catch (e) {
        return false
      }
    },
    exodusAvailable() {
      const { ethereum } = window
      try {
        return ethereum && ethereum && ethereum.isExodus
      } catch (e) {
        return false
      }
    },
    trustAvailable() {
      const { ethereum } = window
      try {
        return ethereum && ethereum.isTrust
      } catch (e) {
        return false
      }
    },
    coinbaseAvailable() {
      const { ethereum } = window
      try {
        return ethereum && ethereum.providerMap.has('CoinbaseWallet')
      } catch (e) {
        return false
      }
    },
    isSelectWallet() {
      return this.connectionModal.type === 'select'
    },
    title() {
      return this.isSelectWallet
        ? this.$t('selectWallet')
        : this.$t('connectWallet')
    },
  },
  methods: {
    ...mapMutations('ui', ['setConnectionModal']),
    ...mapActions('crypto', [
      'initApp',
      'init',
      'logOut',
      'checkMetaCoreRegistration',
    ]),
    ...mapMutations('crypto', ['setConnecting', 'setConnectionWallet', 'resetState']),
    ...mapMutations('user', ['clearUser']),

    rpcErrorTrow() {
      this.$swal.fire({
        confirmButtonText: 'Reload',
        preConfirm: () => {
          document.location.reload(true)
        },
        allowOutsideClick: false,
        title: "Polygon RPC error",
        html: `${this.$t('connectModal.messageRpcError')}`
      })
    },
    async handleWalletSelect(name) {
      this.clearUser()

      if (this.isSelectWallet) {
        const params = this.connectionModal.params || {}
        this.setConnectionWallet({ name, ...params })
        this.closeModal()
        return
      }

      await this.initApp({ name, onConnect: this.closeModal() })
        .then(async () => {
          if (this.isConnecting === false) {
            if (nullEmptyHash(this.getParent) === null) {
              this.$swal({
                title: this.$t('connectModal.swal.title'),
                showCancelButton: true,
                confirmButtonColor: '#38c948',
                cancelButtonColor: '#828282',
                confirmButtonText: this.$t(
                  'connectModal.swal.confirmButtonText'
                ),
                cancelButtonText: this.$t('connectModal.swal.cancelButtonText'),
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await this.$router.push({ name: 'signup' })
                } else {
                  await this.logOut()
                }
              })
            } else {
              try {
                this.$store.commit('loader/showProcessingModal', {
                  isShown: true,
                  text: `${this.$t(
                    'matrix.activationModal.blockchainPendingFirstLine'
                  )} <br />
                                ${this.$t(
                    'matrix.activationModal.blockchainPendingSecondLine'
                  )}`,
                })

                await this.checkMetaCoreRegistration({
                  onBlockchainPending: () => {
                    this.$store.commit('loader/showProcessingModal', {
                      isShown: true,
                      text: `${this.$t(
                        'matrix.activationModal.blockchainPendingFirstLine'
                      )} <br />
                                ${this.$t(
                        'matrix.activationModal.blockchainPendingSecondLine'
                      )}`,
                    })
                  },
                })
                await this.init()
                if (this.$route.path === '/') {
                  await this.$router.push('/home')
                } else {
                  this.$toast.success(`${this.$t('register.information.loggedIn')}`)
                }
              } catch (e) {
                SentryLog(e, 'connect')
                console.log("error rpc 1", e.message)
                this.rpcErrorTrow()
                this.setConnecting(null)
                if (getLocalStorageElement('error_count')) {
                  setLocalStorageElement('error_count', getLocalStorageElement('error_count') + 1)
                  if (Number(getLocalStorageElement('error_count')) === 2) {
                    localStorage.clear()
                  }
                } else {
                  setLocalStorageElement('error_count', 1)
                }
              } finally {
                this.$store.commit('loader/showProcessingModal', {
                  isShown: false,
                })
              }
            }
          }
        })
        .catch((err) => {
          this.resetState()
          SentryLog(err, 'connect')
          console.log("error rpc 2", err.message)
          this.rpcErrorTrow()
          this.setConnecting(null)
          if (getLocalStorageElement('error_count')) {
            setLocalStorageElement('error_count', getLocalStorageElement('error_count') + 1)
            if (Number(getLocalStorageElement('error_count')) === 2) {
              localStorage.clear()
            }
          } else {
            setLocalStorageElement('error_count', 1)
          }
        })
    },

    closeModal() {
      this.setConnectionModal({ visible: false })
    },
  },
}
</script>

<style lang="scss">
.disabled {
  pointer-events: none;
  h4,h3 {
    color: #666666 !important;
  }
}
.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 480px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background: #1A1A1A;
  z-index: 500;
  padding: 16px 24px 24px;
  transition: all 500ms;
  animation-name: isModal;
  animation-duration: 500ms;
  @keyframes isModal {
    from {
      right: -500px;
    }
    to {
      right: 0;
    }
  }
  .btn-close {
    position: absolute;
    top: 16px;
    right: 24px;
    width: 40px;
    height: 40px;
    background: none;
    z-index: 1;

    &:hover {
      svg path {
        fill-opacity: 1;
      }
    }
  }

  &-title {
    margin-bottom: 32px;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.096px;
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__btn {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 8px 16px;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        background: #EB7104;
      }
    }

    &__img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;

      @media(max-width: 1025px) {
        width: 24px;
        height: 24px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    &__data {
      h4,
      a {
        margin-bottom: 4px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.064px;
        color: #FFFFFF;

        @media(max-width: 1025px) {
          color: #ffffff;

          &:hover {
            color: #FFFFFF;
          }
        }
      }

      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.048px;
        color: rgba(255, 255, 255, 0.40);

        @media(max-width: 1025px) {
          display: none;
        }
      }
    }

    &.menu-list {
      .modal-list__btn {
        background: transparent;

        &::after {
          width: 24px;
          height: 24px;
          margin-left: auto;
          //background: url('~@/assets/images/homenew/arrow-right-orange.svg') center center no-repeat;
          opacity: 0;
          content: '';

          @media(max-width: 1025px) {
            display: none;
          }
        }

        &:hover {
          background: rgba(255, 255, 255, 0.05);

          &::after {
            opacity: 1;
          }
        }

        &.active {
          background: rgba(255, 255, 255, 0.1);
          pointer-events: none;

          svg {
            path {
              fill-opacity: 1;
            }
          }

          &::after {
            opacity: 0;
          }
        }
      }
    }

    @media(max-width: 1025px) {
      gap: 8px;
    }
  }

  &.hidden {
    right: -100%;
  }

  .content-xs {
    .connect {
      width: 100%;
      margin-bottom: 24px;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.06px;
    }

    .languages-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 8px;
      margin-top: 24px;

      li {
        padding: 4px 0;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.06px;
        text-align: center;

        &.active {
          border: 1px solid #EB7104;
        }

        &:hover,
        &:focus {
          border: 1px solid rgba(255, 255, 255, .4);
        }
      }
    }

    @media(width > 1024px) {
      display: none;
    }
  }

  &#connectModal {
    z-index: 100;
  }

  &#menuModal {
    @media(max-width: 1025px) {
      justify-content: flex-start;
      padding-top: 72px;

      .modal-title {
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 24px 0 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.056px;
        color: rgba(255, 255, 255, 0.40);

        &::before {
          width: 24px;
          height: 24px;
          background: url('~@/assets/images/homenew/menu-icon.svg') center center no-repeat;
          content: "";
        }
      }

      .modal-content {
        overflow: auto;
      }

      .modal-list__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 8px 16px;
        background: rgba(255, 255, 255, .05);
        border-radius: 8px;
        cursor: pointer;

        h4,
        p {
          margin: 0;
          color: rgba(255, 255, 255, .4);
        }

        &:hover,
        &.active {
          background: rgba(255, 255, 255, .1);

          h4,
          a {
            color: #FFFFFF;
          }

          p {
            color: #FFFFFF;
          }
        }
      }
    }
  }
}
</style>