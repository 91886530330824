import { $axiosApiWs, $axiosNewApi } from '@/plugins/Axios'
import endpoint from '@/config/api-endpoint'
import { mapApiError, mapData } from './helpers'

export const createChatService = async (userId) => {
    try {
        const { data } = await $axiosApiWs.post(endpoint.messenger.createChat, {
            user_id: userId
        })
        return [null, mapData(data)]
    } catch (e) {
        return [mapApiError(e), null]
    }
}

export const deleteChatService = async (chat_key) => {
    try {
        const { data } = await $axiosApiWs.post(endpoint.messenger.deleteChat, {
            chat_key: chat_key
        })
        return [null, mapData(data)]
    } catch (e) {
        return [mapApiError(e), null]
    }
}

export const getMyChatService = async () => {
    try {
        const { data } = await $axiosApiWs.get(endpoint.messenger.getMyChats)
        return [null, mapData(data)]
    } catch (e) {
        if (e.response.status === 401) {
            localStorage.clear()
            window.location = '/'
        }
        return [mapApiError(e), null]
    }
}

export const getChatMessagesService = async (payload) => {
    try {
        const { data } = await $axiosApiWs.get(endpoint.messenger.getMessages, {
            params: { chat_key : payload.key, page: payload.page && payload.page }
        })
        return [null, mapData(data)]
    } catch (e) {
        if (e.response.status === 401) {
            localStorage.clear()
            window.location = '/'
        }
        return [mapApiError(e), null]
    }
}

export const getPrivacyService = async () => {
    try {
        const { data } = await $axiosApiWs.get(endpoint.messenger.getPrivacy)
        return [null, mapData(data)]
    } catch (e) {
        if (e.response.status === 401) {
            localStorage.clear()
            window.location = '/'
        }
        return [mapApiError(e), null]
    }
}

export const setPrivacyService = async (id) => {
    try {
        const { data } = await $axiosApiWs.post(endpoint.messenger.getPrivacy, {
            value: id
        })
        return [null, mapData(data)]
    } catch (e) {
        if (e.response.status === 401) {
            localStorage.clear()
            window.location = '/'
        }
        return [mapApiError(e), null]
    }
}

export const getBlockListService = async (payload) => {
    try {
        const { data } = await $axiosNewApi.get(endpoint.messenger.getBlockList, {
            params: {user_id: payload.id, page: payload.page && payload.page, limit:10}
        })
        return [null, data]
    } catch (e) {
        if (e.response.status === 401) {
            localStorage.clear()
            window.location = '/'
        }
        return [mapApiError(e), null]
    }
}

export const complaintChatService = async (request) => {
    try {
        const { data } = await $axiosApiWs.post(endpoint.messenger.complaint, request)
        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const uploadFileService = async (request) => {
    try {
        const formData = new FormData()
        if (request.files) {
            for (let i = 0; i < request.files.length; i++) {
                formData.append(`files[${i}]`, request.files[i])
            }
        }
        const { data } = await $axiosApiWs.post(endpoint.messenger.upload, formData)
        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const deleteFileService = async (request) => {
    try {
        const { data } = await $axiosApiWs.post(endpoint.messenger.deleteFile, request)
        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const unBlockService = async (id) => {
    try {
        const { data } = await $axiosNewApi.post(endpoint.messenger.unBlock, {
            user_id: id
        })
        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const setBlockService = async (id) => {
    try {
        const { data } = await $axiosNewApi.post(endpoint.messenger.setBlock, {
            user_id: id
        })
        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const setBlockStatusService = async (id) => {
    try {
        const { data } = await $axiosNewApi.get(endpoint.messenger.getBlockStatus, {
            params: { user_id: id }
        })
        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}
