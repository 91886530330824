import {
  closeTicketService,
  createMessageService,
  createTicketService,
  getFaqService,
  getNotificationsService,
  getTicketListService,
  getTicketMessagesService,
  getTicketService,
  getTopicsService,
  setReadNotificationItemService,
  setReadNotificationsService,
  switchTicketMessagesIsRead,
  feedbackTicketService
} from '@/api/ticket'

export default {
  state: {
    topics: [],
    tickets: [],
    faq: [],
    ticket: {},
    messages: [],
    messagesMeta: {},
    unreadMessages: 0,
    notifications: [],
    newNotifications: [],
    noticeLoader: false,
    isNoticeLoaded: false,
    helpLoaded: false,
    ticketUpdate: null,
    unreadHelpMessages: null,
    page: null
  },
  getters: {
    getIsNoticeLoaded(state) {
      return state.isNoticeLoaded
    },
    getHelpLoaded(state) {
      return state.helpLoaded
    },
    getNoticeLoader(state) {
      return state.noticeLoader
    },
    getFollowingNotices(state) {
      return state.notifications.filter(item => item.type === "subscribe")
    },
    getTopics(state) {
      return state.topics.map((x) => ({
        id: x.id,
        label: x.title,
      }))
    },
    tickets(state) {
      return state.tickets
    },
    faq(state) {
      return state.faq
    },
    messages(state) {
      return state.messages
    },
    messagesMeta(state) {
      return state.messagesMeta
    },
    unreadMessages(state) {
      return state.unreadMessages
    },
    getNotifications(state) {
      return state.notifications
    },
    getUnreadNotifications(state) {
      let count = 0
      state.notifications.forEach((item) => {
        if (item.status['is_read'] === false) {
          count++
        }
      })
      return count
    },
    getReadNotifications(state) {
      return state.newNotifications
    },
    ticketUpdate(state) {
      return state.ticketUpdate
    },
    unreadHelpMessages(state) {
      return state.unreadHelpMessages
    },
    getPage(state) {
      return state.page
    },
  },
  mutations: {
    setIsNoticeLoaded(state, bool) {
      state.isNoticeLoaded = bool
    },
    setHelpLoaded(state, bool) {
      state.helpLoaded = bool
    },
    setNoticeLoader(state, bool) {
      state.noticeLoader = bool
    },
    setTopics(state, arr) {
      state.topics = arr
    },
    setTickets(state, tickets) {
      state.tickets.push(...tickets)
    },
    clearTickets(state) {
      state.tickets = []
    },
    updatePage(state, page) {
      state.page = page
    },
    setTicket(state, ticket) {
      state.ticket = {
        ...state.ticket,
        ...ticket,
      }
    },
    setTicketsUnread(state, ticketUpdate) {
      state.tickets = state.tickets.map(ticket => {
        if (ticket.id === ticketUpdate) {
          return { ...ticket, unread_count: 0 };
        }
        return ticket;
      });
    },
    setFaq(state, faq) {
      state.faq = faq
    },
    setMessages(state, messages) {
      state.messages = messages
    },
    setMessagesMeta(state, meta) {
      state.messagesMeta = {
        ...meta,
      }
    },
    setSendMessage(state, message) {
      state.messages.push(message)
    },
    addMessages(state, message) {
      let newMessages = message
      if (!Array.isArray(message)) {
        newMessages = [message]
      }
      state.messages = newMessages.concat(state.messages)
    },
    setUnreadMessages(state, message) {
      state.unreadMessages = message
    },
    setNotifications(state, notice) {
      state.notifications = notice
    },
    setReadNotifications(state) {
      state.notifications.forEach((item) => {
        if (item.status['is_read'] === false) {
          state.newNotifications.push(item.id)
          item.status['is_read'] = true
        }
      })
    },
    setReadNotificationItem(state, { notification_id }) {
      state.notifications.forEach(item => {
        if (item.id === notification_id) {
          item.status['is_read'] = true
        }
      })
    },
    ADD_SOCKET_NOTIFICATION(state, notification) {
      state.notifications.unshift(notification);
    },
    ticketStatusUpdate(state, ticket) {
        state.ticketUpdate = ticket
        state.unreadHelpMessages++
    },
    setUnreadHelpMessagesInit(state, listUnread) {
      state.unreadHelpMessages = listUnread
    },
  },
  actions: {
    async getTopics({ commit, dispatch }, request) {
      const [err, result] = await getTopicsService(request)

      if (err) throw err

      commit('setTopics', result)

      return result
    },
    async createTicket({ commit }, request) {
      const [err, result] = await createTicketService(request)


      if (err) throw err
      return result
    },
    async getTickets({ commit }, request) {
      const [err, result] = await getTicketListService(request)

      if (err) throw err

      let pageInfo = {
        page: result.page,
        total: result.total
      };
      commit('updatePage', pageInfo)

      commit('setTickets', result.list)

      return result
    },
    async getFaq({ commit }, request) {
      const [err, result] = await getFaqService(request)

      if (err) throw err

      commit('setFaq', result)

      return result
    },
    async getTicket({ commit, dispatch }, request) {
      const [err, ticket] = await getTicketService(request)

      if (err) throw err

      commit('setTicket', ticket)

      const messages = dispatch('getMessages', request)

      return {
        ...ticket,
        messages: messages ? messages.data : [],
      }
    },
    async getMessages({ commit }, request) {
      const [err, messages] = await getTicketMessagesService({
        ...request,
        count: 20,
      })

      if (err) throw err

      commit(request.page ? 'addMessages' : 'setMessages', messages.data)
      commit('setMessagesMeta', messages.meta)

      return messages
    },
    async sendChatMessage({ commit }, request) {
      const [err, result] = await createMessageService(request)

      if (err) throw err

      commit('setSendMessage', result)

      return result
    },
    async closeTicket({ commit }, request) {
      const [err, result] = await closeTicketService(request)

      if (err) throw err

      commit('setTicket', result)

      return result
    },
    // todo should be removed
    // async getUnreadMessages({ commit }, userId) {
    //   const data = await getTicketMessagesCount(userId)
    //   commit('setUnreadMessages', data.state)
    // },
    async switchUnreadMessagesAction({ commit }, user) {
      return await switchTicketMessagesIsRead(user)
    },

    async getNotificationAction({ commit }, userId) {
      const [err, result] = await getNotificationsService(userId)
      if (err) throw err
      commit('setNotifications', result.data)
      return result
    },

    async setReadNotificationAction({ commit, getters }) {
      await commit('setReadNotifications')
      const [err, result]  = await setReadNotificationsService({
        request: getters.getReadNotifications,
      })
      if (err) throw err
      return result
    },
    async setReadNotificationItemAction({ commit }, request ) {
      const [err, result]  = await setReadNotificationItemService({
        ...request
      })
      if (err) throw err
      commit('setReadNotificationItem', result)
      return result
    },
    addSocketNotification({ commit }, notification) {
      commit('ADD_SOCKET_NOTIFICATION', notification);
    },
    async feedbackTicket({ commit }, request) {
      const [err, result] = await feedbackTicketService(request)

      if (err) throw err

      commit('setTicket', { allow_feedback: result.allow_feedback })

      return result
    },
  },
  namespaced: true,
}
