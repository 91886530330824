<template>
  <div class="InfoUsersBlock">
    <div class="InfoUsersBlock-cells">
      <BaseIcon name="usersIcon" verAlign />
      <p v-if='$i18n.locale === "ar"' class='arabText'>
        <span v-if="getAllUsersInfo" class="usersCount">{{
            totalUsers
        }}</span>
        :{{ $t('home.usersInfo.users') }}
      </p>
      <p v-else-if='$i18n.locale === "ur"' class='arabText'>
        <span v-if="getAllUsersInfo" class="usersCount">{{
            totalUsers
          }}</span>
        :{{ $t('home.usersInfo.users') }}
      </p>
      <p v-else>
        {{ $t('home.usersInfo.users') }}:
        <span v-if="getAllUsersInfo" class="usersCount">{{
            totalUsers
          }}</span>
      </p>
    </div>
    <div class="InfoUsersBlock-cells">
      <BaseIcon name="usersEarned" verAlign />
      <p v-if='$i18n.locale === "ar"'>
        <span v-if="getAllUsersEarned" class="earnedDai"
          >{{ totalEarn }}</span
        >
        :{{ $t('home.usersInfo.earned') }}
      </p>
      <p v-else-if='$i18n.locale === "ur"'>
        <span v-if="getAllUsersEarned" class="earnedDai"
        >{{ totalEarn }}</span
        >
        :{{ $t('home.usersInfo.earned') }}
      </p>
      <p v-else>
        {{ $t('home.usersInfo.earned') }}:
        <span v-if="getAllUsersEarned" class="earnedDai"
        >{{ totalEarn }} DAI</span
        >
      </p>
    </div>
  </div>
</template>
<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'
import { mapGetters } from 'vuex'
import { formatNumberWithSpaces } from '@/helpers/filterLengthNumbers'
export default {
  components: { BaseIcon },
  computed: {
    ...mapGetters('user', ['getAllUsersInfo', 'getAllUsersEarned']),
    totalUsers() {
      return formatNumberWithSpaces(this.getAllUsersInfo)
    },
    totalEarn() {
      return formatNumberWithSpaces(this.getAllUsersEarned)
    },
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
.InfoUsersBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  gap: 16px;
  padding: 12px 24px;
  background-color: rgba(30,41,48,.6);
  border-radius: 16px;
  color: var(--color-white-50);
  margin: 0 auto;
  height: max-content;
  @media (max-width: 1350px) {
    top: 13%;
    left: 20%;
    width: fit-content;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 6px 10px;
  }
  @media (max-width: 1025px) {
    position: absolute;
    top: 90px;
    left: 10%;
    right: 10%;
  }
  @media (max-width: 840px) {
    position: fixed;
    margin: 0 auto;
  }
  @media (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
  }
  &-cells {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    .arabText {
      margin-right: 15px;
      .usersCount {
        margin-right: 0;
      }
    }
    svg {
      width: 25px;
      height: 20px;
    }

    &:nth-child(1) {
      border-right: 1px solid var(--color-white-30);
      @media (max-width: 1200px) {
        border-right: 0;
      }
    }
    .usersCount {
      color: white;
      margin-right: 16px;
      white-space: nowrap;
    }
    .earnedDai {
      color: #ff9501;
      white-space: nowrap;
    }
  }
}
</style>
