<template>
  <!--  <component :is="layout">-->
  <!--    <slot />-->
  <!--  </component>-->
  <router-view />
</template>

<script>
// const defaultLayout = "AppLayoutDefault"
export default {
  name: 'AppLayout',
  components: {},
  computed: {},
  methods: {},
}
</script>
