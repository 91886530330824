import {getCategoriesService, getCategoryService} from '@/api/post'
export default {
    state: {
        categories: [],
        category: {},
    },
    getters: {
        allCategories(state) {
            const data =  state.categories
            data.splice(2,2)
            return data
        },
        getCategory(state) {
            return state.category
        },
    },
    mutations: {
        updateCategories(state, categories) {
            state.categories = categories
        },
        updateCategory(state, category) {
            state.category = category
        },
    },
    actions: {
        async fetchCategories(ctx) {
            const [err, result] = await getCategoriesService()
            if (err) throw err

            ctx.commit("updateCategories", result)
        },

        async fetchCategory(ctx, id = 0) {
            let get = ""
            if (id !== 0) {
                get += `${id}`
            }

            const [err, result] = await getCategoryService(get)
            if (err) throw err
            ctx.commit("updateCategory", result)
        },
    },
}
