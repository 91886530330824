import { $axiosNewApi } from '@/plugins/Axios'
import endpoint from '@/config/api-endpoint'
import { mapApiError, mapData } from './helpers'

export const getTestimonialsService = async (request) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.testimonial.getTestimonials(request))

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const getTestimonialsVideoService = async (request) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.testimonial.getVideoTestimonials(request))

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const setTestimonialsService = async ({ estimation, description }) => {
  // eslint-disable-next-line no-useless-catch
  try {
    // console.log('111',estimation, description)
    const { data } = await $axiosNewApi.post(
      endpoint.testimonial.setTestimonials,
      {
        estimation: estimation,
        description: description,
        //status: 'accepted'
      }
    )
    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}
