import * as Sentry from "@sentry/browser"
import { DICT } from "@/config/constants"

export const isDevelopment = () => {
    return process.env.NODE_ENV === "development"
}

const allowLogs = () => {
    if (isDevelopment()) {
        return true
    }

    if (window && window.location) {
        if (window.location.search.includes("outputConsole")) {
            return true
        }
    }

    return false
}

export const Log = (...props) => {
    if (allowLogs()) {
        // console.log(...props)
    }
}

export const Warn = (...props) => {
    console.warn(...props)
}

export const isServiceMode = () => {
    const enabled = DICT.SERVICE_MODE

    if (isDevelopment()) {
        return false
    }

    return enabled
}

export const SentryLog = (err, tag) => {
    return false

    // Sentry.captureException(err, {
    //     tags: {
    //         section: tag,
    //     },
    // })
}
