import { $axiosNewApi } from '@/plugins/Axios'
import endpoint from '@/config/api-endpoint'
import { mapApiError, mapData } from './helpers'

export const getTopicsService = async (request) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.ticket.topics, {
      params: { ...request },
    })

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

// topic_id : integer|required or custom_topic : string|required
// 	incident_date : string
// 	message: string|required
// 	email: string|required
// 	files: array|images
export const createTicketService = async (request) => {
  try {
    const formData = new FormData()
    Object.keys(request).forEach((key) => {
      if (key === 'files') {
        for (let i = 0; i < request[key].length; i++) {
          formData.append(`${key}[${i}]`, request[key][i])
        }
      } else {
        formData.append(key, request[key])
      }
    })

    const { data } = await $axiosNewApi.post(endpoint.ticket.create, formData, {
      headers: {
        Accept: 'application/json',
      },
    })

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const getTicketListService = async (request) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.ticket.list, {
      params: { ...request },
    })

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}

export const getFaqService = async (request) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.ticket.faq, {
      params: { ...request },
    })

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const getTicketService = async (request) => {
  try {
    const { data } = await $axiosNewApi.get(endpoint.ticket.getById(request.id))

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}

export const getTicketMessagesService = async (request) => {
  const { id, ...paramsWithoutId } = request;
  try {
    const { data } = await $axiosNewApi.get(endpoint.ticket.getMessages(id), {
      params: { ...paramsWithoutId },
    })

    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}

// message: string|required
// files: array|images
export const createMessageService = async (request) => {
  try {
    const formData = new FormData()
    if (request.files) {
      for (let i = 0; i < request.files.length; i++) {
        formData.append(`files[${i}]`, request.files[i])
      }
    }
    if (request.message) {
      formData.append('message', request.message)
    }

    const { data } = await $axiosNewApi.post(
      endpoint.ticket.createMessage(request.id),
      formData
    )

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const closeTicketService = async (request) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.ticket.close(request.id))

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const getTicketMessagesCount = async (userId) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const { data } = await $axiosNewApi.get(endpoint.ticket.state(userId))
    return data
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    console.log(error)
  }
}

export const switchTicketMessagesIsRead = async (payload) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const { data } = await $axiosNewApi.put(endpoint.ticket.makeRead, payload)
    return data
  } catch (error) {
    console.log(error)
  }
}
export const getNotificationsService = async (userId) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const { data } = await $axiosNewApi.get(
      endpoint.ticket.getNotifications(userId),
    )
    // console.log("API DATA", data)
    return [null, mapData(data)]
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/'
    }
    return [mapApiError(error), null]
  }
}
export const setReadNotificationsService = async ({ request }) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const { data } = await $axiosNewApi.post(
      endpoint.ticket.setReadNotifications,
      {
        notification_ids: request,
        is_read: true
      }
    )
    // console.log('Set read DATA FROM API', data)
    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}

export const setReadNotificationItemService = async ({ id, userId }) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const { data } = await $axiosNewApi.post(
      endpoint.ticket.setNotificationItem(id),
      {
        user_id: userId,
        is_read: true
      }
    )

    // console.log('Set read DATA FROM API', data)
    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}
export const feedbackTicketService = async (request) => {
  try {
    const { data } = await $axiosNewApi.post(endpoint.ticket.feedback(request.id), request)

    return [null, mapData(data)]
  } catch (error) {
    return [mapApiError(error), null]
  }
}
