import Toast, { POSITION } from "vue-toastification"
import "vue-toastification/dist/index.css"

const init = (app) => {
    const options = {
        position: POSITION.TOP_RIGHT,
    }

    app.use(Toast, options)
}

export default init
