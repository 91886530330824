<template>
  <button :disabled='disabled' :class="classList" @click.stop="event">{{ text }}</button>
</template>

<script>
export default {
  props: ['text', 'classes', 'disabled'],
  emits: ['event'],
  methods: {
    event() {
      this.$emit('event')
    },
  },

  computed: {
    classList() {
      if (!this.classes) {
        return ['button-dark', this.disabled ? 'button-dark--disabled' : '']
      } else {
        return [
          ...this.classes.split(' '),
          this.disabled ? 'button-dark--disabled' : '',
        ]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.button-dark {
  background: var(--color-white-10);
  padding: var(--indent-14) var(--indent-32);
  border-radius: var(--indent-8);
  font-size: var(--font-14);
  line-height: var(--line-20);
  letter-spacing: 1px;
  font-weight: var(--font-500);
  color: var(--color-white);
  transition: background 500ms;
  &:hover, &:focus {
    background: var(--color-orange-800);
  }
  &--disabled {
    background: var(--color-white-3);
    color: var(--color-white-30);
    cursor: initial;
    &:hover {
      background: var(--color-white-3);
    }
  }
}
</style>