<template>
  <div class="social-links">
    <a
      v-for="(item, idx) in linksList"
      :key="idx"
      :href="item.link"
      target="_blank"
    >
      <BaseIcon :name="item.name" />
    </a>
  </div>
</template>
<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'

export default {
  components: { BaseIcon },

  data() {
    return {
      linksList: [
        {
          name: 'socIcoYotube',
          link: 'https://www.youtube.com/@MetaFORCESpace',
        },
        {
          name: 'socIcoDiscord',
          link: 'https://discord.gg/yZYzwpJeWg',
        },
        {
          name: 'socIcoTelegram',
          link: 'https://t.me/MetaForceSpace',
        },
        {
          name: 'socIcoTwitter',
          link: 'https://twitter.com/MetaForceSpace',
        },
        {
          name: 'socIcoInstagram',
          link: 'https://www.instagram.com/metaforcespace/',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.social-links {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: var(--indent-8);
  margin-top: 4px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background: var(--color-white-5);
    border-radius: var(--indent-8);
    transition: .3s ease;

    span {
      display: flex;

      svg path {
        transition: .3s ease;
      }
    }

    &:hover {
      background: var(--color-white-10);

      span {
        svg path {
          fill: var(--color-white);
        }
      }
    }
  }
}
</style>
