export const getConnectTypeFromSelectWallet = (v) => {
    const defaultType = "default"

    if (!v) return defaultType

    const vArr = v.split(":")

    if (vArr.length && vArr.length < 2) return defaultType

    const [name, type, stamp] = vArr

    return type
}
