import { getLocalStorageElement } from '@/helpers/localstorage'
import { DICT, LSTORAGE } from '@/config/constants'
import router from '@/router'
import { saveTx, watchLostTxs } from '@/helpers/lostTx'
import {
  countPartnersInLvl,
  countPureRevenue,
  countSpendInLvl,
  defineStructureIndexes,
  getClassicPriceByLevel,
  nullEmptyHash,
} from '@/helpers/matrix'
import { vm } from '@/main'
import {
  getAuthMessageService,
  getTactileFollowers,
  getWalletHistoryService,
  isValidAccountService,
  setWalletHistoryService,
  verifyAuthMessageService,
} from '@/api/user'
import { Log, SentryLog, Warn } from '@/helpers/dev'
import {
  createProvider,
  getEthAccount,
  getLatestBlock,
  getRevertReason,
  increaseGas,
  isEthereumWindow,
  switchNetwork,
  tokenFormatFromWei,
} from '@/helpers/crypto'
import mainAbi from '@/api/main-abi.json'
import mfsAbi from '@/api/mfs-abi.json'
import sfcAbi from '@/api/sfc-abi.json'
import sfcNewAbi from '@/api/sfcnew-abi.json'
import metacoreAbi from '@/api/metacore-abi.json'
import metapaymentAbi from '@/api/metapayment-abi.json'
import uvRegistryAbi from '@/api/uv-registry-abi.json'
import uvCoreAbi from '@/api/uv-core-abi.json'
import nftControlAbiOld from '@/api/nft-control-abi-old.json'
import nftRegistryAbiOld from '@/api/nft-registry-abi-old.json'
import nftControlAbi from '@/api/nft-control-abi.json'
import nftRegistryAbi from '@/api/nft-registry-abi.json'
import nftMigrationAbi from '@/api/nft-migration-abi.json'
import nftAbi from '@/api/nft-abi.json'
import distributorAbi from '@/api/distributor-abi.json'
import distributionAbi from '@/api/distribution-abi.json'
import axios from 'axios'
import nftsApi from '@/api/nftsApi'

const LAST_NFT_VERSION = 3
export default {
  async init({ dispatch }) {
    const lsConnected = getLocalStorageElement(LSTORAGE.connected)

    if (lsConnected === true) {
      // const routerRequireWallet =
      //   router.currentRoute.meta && router.currentRoute.meta.requiresWallet
      //
      // if (routerRequireWallet) {
      //   await dispatch('initApp')
      //   await dispatch('initAuth')
      // } else {
      //   await dispatch('initAppMinimal')
      // }
      await dispatch('initApp')
      await dispatch('initAuth')
      watchLostTxs()
    }
  },

  async checkRegistration({ dispatch, getters, state }, includeSaved) {
    try {
      let parent = null
      if (includeSaved) {
        parent = getters.getParent
      } else {
        parent = await getters.getMainContract.methods
          .parent(state.account)
          .call()
      }

      if (nullEmptyHash(parent) === null) {
        throw new Error(`${vm.$t('errors.registrationError')}`)
      }

      // если есть parent, проверить на наличие в базе
      // покрытие кейса с прерванной транзакцией после регистрации
      if (state.account) {
        const [accErr, accRes] = await isValidAccountService({
          hash: state.account,
        })

        if (accErr) {
          // TODO - как то еще отправлять транзакцию, но хеш операции .register не знаем
          // лучше делать на бекенде или тянуть api polygonscan
          await dispatch(
            'user/registerAccount',
            {
              account: state.account,
              parent: parent,
            },
            { root: true }
          )
        }
      }

      return [null, parent]
    } catch (err) {
      Warn('registrationCheck', err)
      return [err, null]
    }
  },
  async getGuestParent({getters}, account) {
    return await getters.getMainContract.methods
      .parent(account)
      .call()
  },

  // check window.ethereum exists, get user account, check provider chain, create contract instance
  async checkConnect({ commit, dispatch, getters }, payload) {
    try {
      const [windowError, ethWindowName] = await isEthereumWindow()
      payload &&
        payload.onEthWindow &&
        payload.onEthWindow([windowError, ethWindowName])
      if (windowError) throw windowError

      const providerName = getLocalStorageElement(LSTORAGE.wallet)

      const [providerError, provider] = await dispatch('checkProvider')
      if (providerError) throw providerError

      const [connectError, connected] = await dispatch('connectWeb3')
      if (connected === 'redispatch' && providerName !== "walletconnect") {
        Log('WANTED REDISPATCH')
        await dispatch('checkProvider')
        await dispatch('connectWeb3')
      }

      payload &&
        payload.onNetwork &&
        payload.onNetwork([connectError, connected])
      if (connectError) throw connectError

      Log('at getEthAccount')
      const [accError, account] = await getEthAccount()
      // vm.$toast.info(account)
      if (accError) throw accError
      Log('after getEthAccount')

      // ШАГ 1. критичные данные загружены, пользователь имеет кошелек и подключен к правильной сети
      commit('setAccount', account)
      await dispatch('connectAppsContracts', account)

      if (getters.getParent === null) {
        const parent = await getters.getMainContract.methods
          .parent(account)
          .call()
        // vm.$toast.info(parent)

        commit('setMeta', { name: 'parent', value: parent })
        // Шаг 2. Подключены контракты, пользователь может начать взаимодействие с сайтом
        commit('setConnect', true)

        return [null, account]
      }
    } catch (err) {
      // doesn't work $swal
      // vm.$swal(err)
      console.log('error 1', err)
      // vm.$toast.error(err.message)
      Warn('connect', err)
      commit('setConnect', false)
      return [err, null]
    }
  },

  async initApp({ commit, dispatch, state }, payload) {
    // проблемы с лейаутом, mounted от layout вызывается всегда
    if (state.connecting === false) return
    commit('setConnecting', true)
    // как правило ставится один раз при подключении кошелька, не требует повторной установки для дальнейших действий
    if (payload && payload.name) {
      commit('setConnectionWallet', { name: payload.name })
    }

    const [connectError, account] = await dispatch('checkConnect')
    if (connectError) throw connectError
    payload && payload.onConnect && payload.onConnect()
    // ШАГ 3. Получены данные по газу и информация пользователя
    // занимает много времени, не имеет высокой кртичности для отображения данных, но критично для выполнения транзакций

    commit('setConnecting', false)
  },

  // async initAppMinimal({ dispatch, commit }) {
  //   const [providerError, provider] = await dispatch('checkProvider')
  //   const [connectError, connected] = await dispatch('connectWeb3')
  //   const [accError, account] = await getEthAccount()
  //   account && commit('setAccount', account)
  // },

  async initAuth({ commit, state }) {
    const lsToken = getLocalStorageElement(LSTORAGE.token)
    if (lsToken) {
      commit('setToken', lsToken)
      return lsToken
    }

    const [err, message] = await getAuthMessageService()
    if (err) throw err

    let signed = true
    const signature = await state.web3.eth.personal
      .sign(message.message, state.account)
      .catch(() => (signed = false))

    if (!signature || !signed) {
      await vm.$swal
        .fire({
          confirmButtonText: vm.$t('confirm'),
          text: vm.$t('errors.auth'),
        })
        .then(async (result) => {
          window.location = '/news-portal/en'
          commit('resetState')
        })
    }
    const [authErr, auth] = await verifyAuthMessageService({
      address: state.account,
      signature: signature,
      message: message.message,
    })

    if (authErr) throw authErr

    commit('setToken', auth.remember_token)

    return auth.remember_token
  },

  async checkProvider({ commit, dispatch }) {
    try {
      const provider = await createProvider()
      const providerName = getLocalStorageElement(LSTORAGE.wallet)

      commit('setProvider', provider)

      if (providerName === 'walletconnect' && provider.enable) {
        provider.on('disconnect', () => dispatch('logOut'))
        Log('enable?', !provider.connected)
        if (!provider.connected) {
          await provider.enable()
        }
      }

      return [null, provider]
    } catch (err) {
      Warn(err)
      return [err, null]
    }
  },

  async connectWeb3({ commit }) {
    try {
      commit('setWeb3Instance')

      const [networkErr, networkRes] = await switchNetwork()

      if (networkErr) throw networkErr

      return [null, networkRes]
    } catch (err) {
      Warn(err)
      return [err, null]
    }
  },

  async connectAppsContracts({ dispatch, commit }, account) {

    const metacoreContract = await dispatch('connectContract', {
      abi: metacoreAbi,
      contractAddress: DICT.CONTRACT_METACORE,
    })
    commit('setContract', { value: metacoreContract, name: 'metacore' })
    Log('metacoreContract!!!!', metacoreContract)

    const metapaymentContract = await dispatch('connectContract', {
      abi: metapaymentAbi,
      contractAddress: DICT.CONTRACT_METAPAYMENT,
    })
    commit('setContract', { value: metapaymentContract, name: 'metapayment' })
    Log('metapaymentContract!!!!', metapaymentContract)

    const contract = await dispatch('connectContract', {
      abi: mainAbi,
      contractAddress: DICT.CONTRACT_MAIN,
      account,
    })
    commit('setContract', { value: contract, name: 'main' })

    const mfsContract = await dispatch('connectContract', {
      abi: mfsAbi,
      contractAddress: DICT.CONTRACT_MFS,
    })
    commit('setContract', { value: mfsContract, name: 'mfs' })

    const sfcContract = await dispatch('connectContract', {
      abi: sfcAbi,
      contractAddress: DICT.CONTRACT_SFC,
    })
    commit('setContract', { value: sfcContract, name: 'sfc' })

    const sfcNewContract = await dispatch('connectContract', {
      abi: sfcNewAbi,
      contractAddress: DICT.CONTRACT_SFC_NEW,
    })
    commit('setContract', { value: sfcNewContract, name: 'sfcNew' })

    const sfc2Contract = await dispatch('connectContract', {
      abi: sfcAbi,
      contractAddress: DICT.CONTRACT_SFC2,
    })
    commit('setContract', { value: sfc2Contract, name: 'sfc2' })

    const energyContract = await dispatch('connectContract', {
      abi: mfsAbi,
      contractAddress: DICT.CONTRACT_ENERGYCOIN,
    })
    commit('setContract', { value: energyContract, name: 'energy' })


    const uvRegistry = await dispatch('connectContract', {
      abi: uvRegistryAbi,
      contractAddress: DICT.CONTRACT_UV_REGISTRY,
    })
    commit('setContract', { value: uvRegistry, name: 'uvRegistry' })
    Log('uvRegistry!!!!', uvRegistry)

    const uvCoreAddress = await uvRegistry.methods.getCoreContract().call()
    Log('uvCoreAddress!!!!', uvCoreAddress)
    const uvCore = await dispatch('connectContract', {
      abi: uvCoreAbi,
      contractAddress: uvCoreAddress,
    })
    commit('setContract', { value: uvCore, name: 'uvCore' })

    const distributionBannedNFTContract = await dispatch('connectContract', {
      abi: distributionAbi,
      contractAddress: DICT.CONTRACT_DISTRIBUTION_BANNED_NFT,
    })
    commit('setContract', {
      value: distributionBannedNFTContract,
      name: 'distributionBannedNFT',
    })

    const royaltyDistributionContract = await dispatch('connectContract', {
      abi: distributionAbi,
      contractAddress: DICT.CONTRACT_ROYALTY_DISTRIBUTION,
    })
    commit('setContract', {
      value: royaltyDistributionContract,
      name: 'royaltyDistribution',
    })
    Log('royaltyDistribution!!!!', royaltyDistributionContract)
  },

  async connectNftContracts({ dispatch, commit, getters }) {
    const nftControlV1 = await dispatch('connectContract', {
      abi: nftControlAbiOld,
      contractAddress: DICT.CONTRACT_NFT_CONTROL_V1,
    })
    commit('setNftControl', {
      value: nftControlV1,
      version: 1,
    })

    const nftRegistryV1 = await dispatch('connectContract', {
      abi: nftRegistryAbiOld,
      contractAddress: DICT.CONTRACT_NFT_REGISTRY_V1,
    })
    commit('setNftRegistry', {
      value: nftRegistryV1,
      version: 1,
    })

    // version 2
    const nftControlV2 = await dispatch('connectContract', {
      abi: nftControlAbiOld,
      contractAddress: DICT.CONTRACT_NFT_CONTROL_V2,
    })
    commit('setNftControl', {
      value: nftControlV2,
      version: 2,
    })

    const nftRegistryV2 = await dispatch('connectContract', {
      abi: nftRegistryAbiOld,
      contractAddress: DICT.CONTRACT_NFT_REGISTRY_V2,
    })
    commit('setNftRegistry', {
      value: nftRegistryV2,
      version: 2,
    })

    // version 3
    const nftControlV3 = await dispatch('connectContract', {
      abi: nftControlAbi,
      contractAddress: DICT.CONTRACT_NFT_CONTROL_V3,
    })
    commit('setNftControl', {
      value: nftControlV3,
      version: 3,
    })

    const nftRegistryV3 = await dispatch('connectContract', {
      abi: nftRegistryAbi,
      contractAddress: DICT.CONTRACT_NFT_REGISTRY_V3,
    })
    commit('setNftRegistry', {
      value: nftRegistryV3,
      version: 3,
    })

    const nftMigrationContract = await dispatch('connectContract', {
      abi: nftMigrationAbi,
      contractAddress: DICT.CONTRACT_NFT_MIGRATION,
    })
    commit('setContract', {
      value: nftMigrationContract,
      name: 'nftMigration',
    })

    // nft contracts
    let promises = []
    for (let version = 1; version < 4; version++) {
      for (let i = 0; i < 8; i++) {
        promises.push(async () => {
        const registry = getters.getNftRegistryContract(version)
          const contractAddress = await registry.methods.getForceNFT(i).call()

          const nftContract = await dispatch('connectContract2', {
            abi: nftAbi,
            contractAddress: contractAddress,
          })
          commit('setRoyaltyNftContract', {
            version: version,
            index: i,
            value: nftContract,
          })
        })
      }
    }
    // this variant with queue, it takes a little long time but this code works without rpc error
    // async function executeBatch() {
    //   for (let i = 0; i < 24 && promises.length > 0; i++) {
    //     const fn = promises.shift();
    //     if (fn) {
    //       await fn();
    //     }
    //   }
    //   if (promises.length > 0) {
    //     await executeBatch();
    //   }
    // }
    // await executeBatch();

    // this optimized variant above code with queue
    async function executeBatch(promises) {
      const batch = promises.splice(0, 3);
      if (batch.length > 0) {
        await Promise.all(batch.map(fn => fn()));
        await executeBatch(promises);
      }
    }
    await executeBatch(promises);
    // this variant without queue, it some times getting rpc error
    // await Promise.all(
    //   promises.map(
    //     (fn) => fn()
    //   )
    // )
  },

  async connectContract({ state }, { abi, contractAddress, account = null }) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          const contract = new state.web3.eth.Contract(abi, contractAddress, {
            from: account,
          })
          resolve(contract)
        } catch (err) {
          Log('test!!!')
          Warn('contract', err)
          reject(new Error('Error connecting contract'))
        }
      }, 250)
    })
  },

  async connectContract2({ state }, { abi, contractAddress, account = null }) {
    try {
      return new state.web3.eth.Contract(abi, contractAddress, {
        from: account,
      })
    } catch (err) {
      Warn('contract', err)
      throw new Error('Error connecting contract')
    }
  },

  async getGas({ commit, state }) {
    try {
      // get gas params - limit from latest block, price from eth utils
      const [blockErr, latestBlock] = await getLatestBlock('pending')
      if (blockErr) throw blockErr

      const gasPrice = await state.web3.eth.getGasPrice()
      const gasAggressive = Math.round(
        Number(gasPrice) * DICT.ESTIMATED_GAS_PRIORIY
      )

      commit('setMeta', { name: 'gasPrice', value: gasAggressive.toString() })

      if (latestBlock && latestBlock.baseFeePerGas) {
        Log('baseFeePerGas', latestBlock.baseFeePerGas)
        const maxFee =
          Math.round(
            DICT.ESTIMATE_GAS_MAX_PER_BASE * latestBlock.baseFeePerGas
          ) + gasAggressive
        commit('setMeta', { name: 'maxFeePerGas', value: maxFee.toString() })
      }

      // const gasLimit = Math.round(latestBlock.gasLimit / latestBlock.transactions.length).toString()
      // commit("setMeta", { name: "gasLimit", value: gasLimit })

      Log({ gasPrice }, { gasAggressive: gasAggressive.toString() })

      return [null, true]
    } catch (err) {
      Warn('gas', err)
      return [err, null]
    }
  },

  async getBalances({ dispatch, commit, state, getters }, account) {
    try {
      // bnb balance
      let balance = await state.web3.eth.getBalance(account)
      commit('setBalance', {
        symbol: 'bnb',
        value: tokenFormatFromWei(balance),
      })

      const balances = {
        busd: getters.getMFSContract,
        sfc: getters.getSFCContract,
        sfcNew: getters.getSFCNewContract,
        sfc2: getters.getSFC2Contract,
        energy: getters.getEnergyContract,
      }

      let promises = []

      for (let token in balances) {
        const contract = balances[token]
        promises.push(async () => {
          let amount = await contract.methods.balanceOf(account).call()
          commit('setBalance', {
            symbol: token,
            value: tokenFormatFromWei(amount),
          })
        })
      }
      // await Promise.all(promises.map((fn) => fn()))
      await Promise.all(promises.map(fn => new Promise(resolve => {
        setTimeout(() => resolve(fn()), 200);
      })))
      // const nonce = await dispatch("getNonce", account)
      await dispatch('getNonce', account)
      return [null, balance]
    } catch (err) {
      Warn('balances', err)
      return [err, null]
    }
  },

  async getNonce({ state, commit }, account) {
    const nonce = await state.web3.eth.getTransactionCount(
      account || state.account
    )
    commit('setMeta', { name: 'nonce', value: Number(nonce) })

    return nonce
  },

  async getProgramLevels({ getters, state }, account) {
    try {
      const tAccount = account || state.account
      const mainContract = getters.getMainContract

      const lvls = await mainContract.methods.accountLevels(tAccount).call()

      let levels = lvls[0].map((x, index) => {
        return {
          lvl: index,
          active: x,
        }
      })

      Log('levels before gap', levels)

      lvls[1].forEach((x, index) => {
        if (x > 0 && levels[index].active === false) {
          levels[index].active = 'gap'
        }
      })

      Log('levels after gap', levels)

      // check gaps for non-reactivation
      // const lastActive = levels ? levels.findLast((x) => x.active) : null
      let lastActive = null
      for (const lvl of levels) {
        if (lvl.active) {
          lastActive = lvl
        }
      }
      if (lastActive) {
        levels = levels.map((level) => {
          if (lastActive.lvl > level.lvl) {
            if (level.active === false) {
              return {
                ...level,
                active: 'gap',
              }
            }
          }

          return level
        })
      }
      return [null, levels]
    } catch (err) {
      Warn('getProgramLevels', err)
      SentryLog(err, 'levels')

      return [err, null]
    }
  },

  async createTransaction({ dispatch, getters }, { func, onTransactionHash }) {
    await dispatch('getGas')
    await dispatch('getNonce')

    let estimatedGas = await func.estimateGas({
      ...getters.getEstimateParams,
    })
    estimatedGas = increaseGas(estimatedGas)

    return func
      .send({
        ...getters.getSendParams,
        gas: estimatedGas,
      })
      .on('transactionHash', (hash) => {
        onTransactionHash && onTransactionHash(hash)
      })
  },

  async registerNewAccount(
    { dispatch, commit, getters, state },
    { account, parentAcc, onBlockchainPending }
  ) {
    try {
      const mainContract = getters.getMainContract

      let regHash
      const regRes = await dispatch('createTransaction', {
        // func: mainContract.methods.newRegistration(parentAcc),
        func: mainContract.methods.registration(parentAcc),
        onTransactionHash: (hash) => {
          regHash = hash
          onBlockchainPending && onBlockchainPending()
        },
      }).catch((e) => {
        if (e.message.includes('not mined within')) {
          const handle = setInterval(() => {
            state.web3.eth.getTransactionReceipt(regHash).then((resp) => {
              if (resp != null && resp.blockNumber > 0) {
                clearInterval(handle)
                dispatch(
                  'user/registerAccount',
                  {
                    account,
                    parent: parentAcc,
                  },
                  { root: true }
                )
              }
            })
          }, 10000)
        } else {
          throw e
        }
      })

      const accResult = await dispatch(
        'user/registerAccount',
        {
          account,
          parent: parentAcc,
        },
        { root: true }
      )

      Log({ regRes })

      await dispatch(
        'user/sendTransaction',
        {
          transactions: [
            {
              type: 'registration',
              transaction_hash: regRes.transactionHash,
              from: account,
              to: parentAcc,
            },
          ],
        },
        { root: true }
      ).catch(Warn)

      commit('setMeta', { name: 'parent', value: parentAcc })

      await dispatch('getBalances', account)

      return [null, accResult]
    } catch (err) {
      Warn('register', err)
      SentryLog(err, 'register')

      const errParsed = getRevertReason(err)
      return [errParsed, null]
    }
  },

  async verboseBuyGas(
    { state, getters, dispatch },
    { lvl, priceInEther, onAllowancePending, onBlockchainPending }
  ) {
    try {
      await dispatch('getGas')
      await dispatch('getNonce')

      let gasPrice = state.web3.utils.toBN(getters.getGas.price)
      const price = state.web3.utils.toWei(priceInEther.toString(), 'ether')

      let gasApprove = '0'
      let gasBuy = '0'

      await getters.getMFSContract.methods
        .approve(DICT.CONTRACT_MAIN, price)
        .estimateGas({ ...getters.getEstimateParams })
        .then((gasAmount) => {
          gasApprove = Math.round(gasAmount * DICT.ESTIMATED_GAS_INCREASE)
        })

      const allowance = await getters.getMFSContract.methods
        .allowance(getters.getAccount, DICT.CONTRACT_MAIN)
        .call()

      Log({ allowance }, { price })

      if (Number(allowance) < Number(price)) {
        onAllowancePending && onAllowancePending()
        Log('send .approve', {
          ...getters.getSendParams,
          gas: gasApprove,
        })
        await getters.getMFSContract.methods
          .approve(DICT.CONTRACT_MAIN, price)
          .send({
            ...getters.getSendParams,
            gas: gasApprove,
          })
          .on('transactionHash', (hash) => {
            onBlockchainPending && onBlockchainPending()
          })
      }

      Log('estimate buy', { ...getters.getEstimateParams })
      await getters.getMainContract.methods
        .buy(lvl)
        .estimateGas({ ...getters.getEstimateParams })
        .then((gasAmount) => {
          gasBuy = Math.round(gasAmount * DICT.ESTIMATED_GAS_INCREASE)
        })

      gasApprove = state.web3.utils.toBN(gasApprove.toString())
      gasBuy = state.web3.utils.toBN(gasBuy.toString())

      let totalGasPrice = gasPrice.mul(gasApprove.add(gasBuy))
      totalGasPrice = state.web3.utils.fromWei(
        totalGasPrice.toString(),
        'ether'
      )

      Log('totalGasPrice', totalGasPrice)

      return [null, totalGasPrice]
    } catch (err) {
      Warn('verboseBuyGas', err)
      SentryLog(err, 'buy')

      return [getRevertReason(err, 'Error estimating buying gas'), null]
    }
  },

  async buyLevel(
    { dispatch, commit, getters, state },
    { lvl, priceInEther, onTransactionPending, onBlockchainPending }
  ) {
    const { account } = state
    const mainContract = getters.getMainContract
    const mfsContract = getters.getMFSContract
    await dispatch('getBalances', account)

    try {
      const price = state.web3.utils.toWei(priceInEther.toString(), 'ether')
      const curBalance = state.web3.utils.toWei(
        state.balance.busd.toString(),
        'ether'
      )
      const allowance = await mfsContract.methods
        .allowance(account, DICT.CONTRACT_MAIN)
        .call()
      // Log(`allowance - ${allowance}`, `price - ${price}`, "send params", getters.getSendParams)

      if (Number(allowance) < Number(price)) {
        if (Number(price) > Number(curBalance)) {
          // throw new Error(`Недостаточный баланс DAI. Необходимо - ${priceInEther} DAI`)
          vm.$swal(
            `${this.$t('matrix.buyLevel.insufficientFunds')}.
                             ${this.$t(
              'matrix.buyLevel.need'
            )} - ${priceInEther} DAI <br/>
                             <a href="/news-portal" target="_blank">Как пополнить</a>`
          )
          return
        }
      }

      // Approve passed, buy transaction
      let estimatedGas = state.meta.gasLimit
      await mainContract.methods
        .buy(lvl)
        .estimateGas({ ...getters.getEstimateParams })
        .then((gasAmount) => {
          estimatedGas = Math.round(gasAmount * DICT.ESTIMATED_GAS_INCREASE)
        })

      onTransactionPending && onTransactionPending()
      Log('send .buy', {
        ...getters.getSendParams,
        gas: estimatedGas,
      })

      let trHash = null
      const buyResult = await mainContract.methods
        .buy(lvl)
        .send({
          ...getters.getSendParams,
          gas: estimatedGas,
        })
        .on('transactionHash', (hash) => {
          trHash = hash
          Log({ hash })
          onBlockchainPending && onBlockchainPending()
        })
        .on('confirmation', function(confirmationNumber, receipt) {
          Log('confirmation', confirmationNumber, receipt)
        })
        .on('receipt', function(receipt) {
          Log('receipt', receipt)
        })
        .catch((e) => {
          if (e.message.includes('not mined within')) {
            saveTx({
              tx: trHash,
              action: 'buy',
              params: { account, lvl: lvl },
            })
            const error = Error(vm.$t('lostTxs.buyWait'))
            error.status = 202
            throw error
          } else {
            throw e
          }
        })

      Log('.buy await compleate')
      // const [err1, levels1] = await dispatch("getProgramLevels")
      // Log("1lvls", levels1)

      commit(
        'user/setClassMatrixLevel',
        {
          lvl: lvl,
          active: true,
        },
        { root: true }
      )

      await dispatch('sendBuyTransaction', buyResult).catch(Warn)

      // save on backend
      // мы не используем данные этого запроса так как берем из rpc а не из Апи
      // await dispatch(
      //   'user/setLevel',
      //   {
      //     account: account,
      //     level: lvl + 1, // backend starts with 1
      //   },
      //   { root: true }
      // )

      await dispatch('getBalances', account)

      // const [err2, levels2] = await dispatch("getProgramLevels")
      // Log("2lvls", levels2)

      return [null, buyResult]
    } catch (err) {
      Warn('buy', err)
      SentryLog(err, 'buy')
      return [getRevertReason(err, 'Error, contact administrator'), null]
    }
  },

  async sendBuyTransaction({ dispatch }, buyResult) {
    Log({ buyResult })
    if (!buyResult) return

    const txHash = buyResult.transactionHash
    const eventNames = Object.keys(buyResult.events).filter((key) =>
      [
        'simpleBuy',
        'newSlot',
        'upgrade',
        'updateOtherPersonStructure',
      ].includes(key)
    )

    const transactions = eventNames.map((key) => {
      const event = buyResult.events[key]

      let lvlName = event.returnValues.lvl !== undefined ? 'lvl' : 'newLvl'

      return {
        type: key,
        transaction_hash: txHash,
        from: event.returnValues.buyer,
        to: event.returnValues.receiver,
        price: getClassicPriceByLevel(+event.returnValues.lvl),
        lvl: +event.returnValues[lvlName] + 1,
      }
    })

    Log('all events: ', Object.keys(buyResult.events))

    await dispatch(
      'user/sendTransaction',
      { transactions },
      { root: true }
    ).catch((err) => {
      SentryLog(err, 'send transaction')
    })
  },

  async requestStructure(
    { getters, state },
    {
      account,
      level,
      type,
      slot: slotProp,
      previousActiveSlot,
      fetchUser,
      countRevenue,
    }
  ) {
    try {
      let structure = {
        slot: null,
        totalSlots: '0',
        totalPartners: 0,
        totalFrozen: 0,
        totalSpend: 0,
        pureRevenue: 0,
        pureRevenueCycle: 0,
        autoRecycle: null,
        autoUpgrade: null,
        lvl1: [null, null],
        lvl2: [...Array(4)].map((_) => null),
        lvl3: [...Array(8)].map((_) => null),
      }
      const contract = getters.getMainContract

      // получаем slot - индекс
      const method = type === 's3' ? 'matrixS3' : 'matrixS6'
      let matrixResponce = await contract.methods[method](
        account,
        level.toString()
      ).call()
      Log(matrixResponce, account, level.toString())

      // ставим мета параметры и делаем просчеты обьектов
      structure.totalSlots = matrixResponce.slot
      const { partners, frozen } = countPartnersInLvl(type, matrixResponce)
      structure.totalPartners = partners
      structure.totalFrozen = state.web3.utils.fromWei(frozen, 'ether')
      structure.totalSpend = countSpendInLvl(partners, level)

      let { slot } = matrixResponce
      if (slotProp !== undefined) {
        slot = slotProp.toString()
      }
      if (previousActiveSlot) {
        if (+slot > 0) {
          slot = +slot - 1
        }
      }
      structure.slot = slot

      // определяем индексы
      const { indexesS3, indexesS6Lvl1, indexesS6Lvl2 } =
        defineStructureIndexes({ type, slot })
      // Log(indexesS3, indexesS6Lvl1, indexesS6Lvl2)

      // настройки рецикл / автоапгрейд
      const settings = await contract.methods
        .getSettings(account, level.toString())
        .call()
      Log({ settings })

      if (settings) {
        structure.autoRecycle = settings['0']
        structure.autoUpgrade = settings['1']
      }

      // запрашиваем по индексам
      const shouldRequestLvl1 =
        countPartnersInLvl(type, matrixResponce).partners > 0
      let shouldRequestLvl2 = shouldRequestLvl1

      if (type === 's3') {
        if (shouldRequestLvl1) {
          await Promise.all(
            indexesS3.map(async (index, idx) => {
              const childsS3Res = await contract.methods
                .childsS3(account, level, index)
                .call()
                .catch((err) => {
                  Warn(err)
                  console.log('ERRRRRRRRRRR', account, level, index, err)
                })
              structure.lvl1[idx] = nullEmptyHash(childsS3Res)
            })
          )
        }
      } else if (type === 's6') {
        if (shouldRequestLvl1) {
          await Promise.all(
            indexesS6Lvl1.map(async (index, idx) => {
              const childsS6Lvl1 = await contract.methods
                .childsS6Lvl1(account, level, index)
                .call()
                .catch((err) => {
                  Warn(err)
                })
              // Log(idx, { childsS6Lvl1 })
              structure.lvl1[idx] = nullEmptyHash(childsS6Lvl1)
            })
          )
        }

        shouldRequestLvl2 = structure.lvl1.some((x) => x !== null)

        if (shouldRequestLvl2) {
          await Promise.all(
            indexesS6Lvl2.map(async (index, idx) => {
              const childsS6Lvl2 = await contract.methods
                .childsS6Lvl2(account, level, index)
                .call()
                .catch((err) => {
                  Warn(err)
                })
              structure.lvl2[idx] = nullEmptyHash(childsS6Lvl2)
            })
          )
        }
      }

      // count total revenue only once
      structure.pureRevenue = countRevenue
        ? countRevenue
        : countPureRevenue({ ...structure, level })
      structure.pureRevenueCycle = countPureRevenue({
        ...structure,
        level,
        forCurrentSlot: true,
      })

      // fetch users by address
      if (fetchUser) {
        const localLvl1 = structure.lvl1.filter((item) => item !== null)
        const localLvl2 = structure.lvl2.filter((item) => item !== null)

        const followersFirst = await getTactileFollowers({ account: localLvl1 })
        if (followersFirst && followersFirst.users) {
          structure.lvl1 = followersFirst.users
        }
        const followersSecond = await getTactileFollowers({
          account: localLvl2,
        })
        if (followersSecond && followersSecond.users) {
          structure.lvl2 = followersSecond.users
        }
      }

      return [null, structure]
    } catch (err) {
      Warn(err)
      SentryLog(err, 'structure')

      return [err, null]
    }
  },

  async requestTree({ getters }, { account, onNext }) {
    try {
      let tree = []
      const contract = getters.getMainContract

      let shouldGo = true
      let index = 0

      while (shouldGo) {
        const child = await contract.methods
          .childs(account, index.toString())
          .call()
          .catch((err) => {
            shouldGo = false
          })

        if (child) {
          index++
          tree.push({ idx: index, account: child })
          onNext && onNext(tree)
        }
      }

      return [null, tree]
    } catch (err) {
      Warn(err)
      return [err, null]
    }
  },

  async withdrawFrozen({ dispatch, getters }, { lvl, type }) {
    try {
      const contract = getters.getMainContract
      const method = type === 's3' ? 'withdrawS3' : 'withdrawS6'

      await dispatch('createTransaction', {
        func: contract.methods[method](lvl),
      })

      return [null, true]
    } catch (err) {
      Warn(err)
      SentryLog(err, 'frozen')
      return [getRevertReason(err), null]
    }
  },

  async changeAutoReCycle({ dispatch, getters }, { lvl }) {
    try {
      const contract = getters.getMainContract

      await dispatch('createTransaction', {
        func: contract.methods.changeAutoReCycle(lvl),
      })

      return [null, true]
    } catch (err) {
      Warn(err)
      SentryLog(err, 'AutoRecycle')
      return [getRevertReason(err), null]
    }
  },

  async changeAutoUpgrade({ dispatch, getters }, { lvl }) {
    try {
      const contract = getters.getMainContract

      await dispatch('createTransaction', {
        func: contract.methods.changeAutoUpgrade(lvl),
      })

      return [null, true]
    } catch (err) {
      Warn(err)
      SentryLog(err, 'AutoUpgrade')
      return [getRevertReason(err), null]
    }
  },

  async transferAccount({ dispatch, getters }, { to }) {
    try {
      const contract = getters.getMainContract

      await dispatch('createTransaction', {
        func: contract.methods.givePermission(DICT.CREATOR),
        // onTransactionHash: (hash) => {
        //     console.log({ hash })
        // },
      })
      await dispatch('createTransaction', {
        func: contract.methods.changeAddress(to),
      })

      return [null, true]
    } catch (err) {
      Warn(err)
      SentryLog(err, 'Transfer')
      return [getRevertReason(err), null]
    }
  },

  async logOut({ commit, state }) {
    const { provider } = state
    const providerName = getLocalStorageElement(LSTORAGE.wallet)


    if (providerName === 'walletconnect' && provider.enable) {
      if (provider.connected && provider.disconnect) {
        await provider.disconnect()
      }
    }

    localStorage.clear()
    commit('resetState')

    if (router.currentRoute.name !== 'academy') {
      window.location = '/news-portal/en'
    }
  },

  async checkWithdraw({ state }, address) {
    try {
      const distributor = new state.web3.eth.Contract(
        distributorAbi,
        '0xb50dDC3b3CbCDfBc1a3fE8235ffE73a3cD75C59B'
      )
      return await distributor.methods.isClaimed(address).call()
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async withdrawMoney({ state }, data) {
    try {
      const distributor = new state.web3.eth.Contract(
        distributorAbi,
        '0xb50dDC3b3CbCDfBc1a3fE8235ffE73a3cD75C59B',
        { from: data.address }
      )
      const gas = await state.web3.eth.getGasPrice().then((gas) => gas)
      await distributor.methods
        .claim(data.index, data.address, data.amount, data.merkleProof)
        .send({ from: data.address, value: 0, gasPrice: gas })
        .on('receipt', () => {
          axios.post('https://web3up.net/api/set-withdraw', {
            address: data.address,
            sum: data.sum,
          })
        })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  // Royalty methods
  async getJustNFT({ getters, state }, { onBlockchainPending }) {
    try {
      const gasPrice = await state.web3.eth.getGasPrice()

      let txHash
      const res = await getters
        .getNftControlContract()
        .methods.getEmptyNFT()
        .send({
          from: state.account,
          gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
        })
        .on('transactionHash', (hash) => {
          txHash = hash
          Log({ hash })
          onBlockchainPending && onBlockchainPending()
        })
        .on('confirmation', function(confirmationNumber, receipt) {
          Log('confirmation', confirmationNumber, receipt)
        })
        .on('receipt', function(receipt) {
          Log('receipt', receipt)
        })
        .catch((e) => {
          if (e.message.includes('not mined within')) {
            const error = Error(vm.$t('lostTxs.buyWait'))
            error.status = 202
            throw error
          } else {
            throw e
          }
        })

      Log('getJustNFT res: ', res)
      Log('getJustNFT txHash: ', txHash)
      return [null, null]
    } catch (err) {
      Warn('getJustNFT', err)
      SentryLog(err, 'getJustNFT')

      const errParsed = getRevertReason(err)
      return [errParsed, null]
    }
  },

  async approve(
    { getters, state },
    { contract, spender, amount, onAllowancePending }
  ) {
    const alreadyApproved = await contract.methods
      .allowance(state.account, spender)
      .call()

    const one = state.web3.utils.toWei(state.web3.utils.toBN("1"), 'ether')
    const alreadyApprovedBN = state.web3.utils.toBN(alreadyApproved)
    console.log('alreadyApprovedBN', alreadyApprovedBN.toString())
    const amountBN = state.web3.utils.toBN(amount)

    if (amountBN.gt(alreadyApprovedBN)) {
      const gasPrice = await state.web3.eth.getGasPrice()
      await contract.methods
        .approve(spender, amountBN.add(one))
        .send({
          ...getters.getSendParams,
          gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
        })
        .on('transactionHash', (hash) => {
          Log({ hash })
          onAllowancePending && onAllowancePending()
        })
    }
  },

  async approveOnMetapayment(
    { dispatch, state, getters },
    { userId, contract, amount, onAllowancePending }
  ) {
    // a bit complicated
    const amountBN = state.web3.utils.toBN(amount)
    console.log('amountBN', amountBN.toString())
    // balance on the meta payment
    console.log('contract', contract)
    const onMetaPaymentBalance = state.web3.utils.toBN(
      await getters.getMetaPayment.methods
        .getBalance(contract._address, userId)
        .call()
    )
    console.log('onMetaPaymentBalance', onMetaPaymentBalance.toString())
    if (amountBN.gt(onMetaPaymentBalance)) {
      // const needToApprove = amountBN.sub(onMetaPaymentBalance)
      await dispatch('approve', {
        contract: contract,
        spender: DICT.CONTRACT_METAPAYMENT,
        amount: amountBN,
        onAllowancePending: onAllowancePending,
      })
    }
  },

  async levelUpJustNFTBySFC(
    { dispatch, getters, state },
    { tokenId, amounts, onAllowancePending, onBlockchainPending }
  ) {
    try {
      const zero = state.web3.utils.toBN(0)
      const contracts = [
        getters.getSFCNewContract,
        getters.getSFC2Contract,
        getters.getEnergyContract,
      ]
      const amountsBN = []
      var isAllAmountsZero = true
      const userId = await dispatch('getMetaCoreId', state.account)
      for (let i = 0; i < 3; i++) {
        let amountBF = state.web3.utils.toWei(
          state.web3.utils.toBN(amounts[i].toString()),
          'ether'
        )
        if (amountBF.gt(zero)) {
          await dispatch('approveOnMetapayment', {
            userId: userId,
            contract: contracts[i],
            amount: amountBF,
            onAllowancePending: onAllowancePending,
          })
          isAllAmountsZero = false
        }
        amountsBN[i] = amountBF
      }
      if (isAllAmountsZero) {
        throw new Error('Invalid amount')
      }

      // const estimatedGas = await getters
      //   .getNftControlContract()
      //   .methods.levelUpEmptyNFT(tokenId, amounts)
      //   .estimateGas({ ...getters.getEstimateParams })

      onBlockchainPending && onBlockchainPending()
      // await dispatch("getNonce")
      let txHash
      const gasPrice = await state.web3.eth.getGasPrice()
      const res = await getters
        .getNftControlContract()
        .methods.levelUpEmptyNFT(tokenId, amountsBN)
        .send({
          from: state.account,
          gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
          // ...getters.getSendParams,
          // gas: estimatedGas * 2,
        })

      await dispatch('getNonce')
      Log('just nft lvl up: ', res)
      Log('just nft lvl up txHash: ', txHash)
    } catch (err) {
      Warn('getJustNFT', err)
      SentryLog(err, 'getJustNFT')
      throw getRevertReason(err)
    }
  },
  async mergeNfts({ getters, state }, { level, ids }) {
    try {
      const gasPrice = await state.web3.eth.getGasPrice()
      await getters
        .getNftControlContract()
        .methods.mergeNFT(level, ids)
        .send({
          from: state.account,
          gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
          // ...getters.getSendParams
          // gas: 2000000,
        })
        .catch((e) => {
          if (e.message.includes('not mined within')) {
            const error = Error(vm.$t('lostTxs.buyWait'))
            error.status = 202
            throw error
          } else {
            throw e
          }
        })
    } catch (err) {
      Warn('getJustNFT', err)
      SentryLog(err, 'getJustNFT')

      throw new getRevertReason(err)
    }
  },

  async migrateRoyaltyNFTWithSign(
    { getters, state },
    { contractAddress, tokenId }
  ) {
    console.log('migrateRoyaltyNFTWithSign', contractAddress, tokenId)
    try {
      const { data } = await nftsApi.getNftMigrationSignature(
        state.account,
        contractAddress,
        tokenId
      )
      const gasPrice = await state.web3.eth.getGasPrice()
      await getters.getNftMigrationContract.methods
        .migrate(
          data.version,
          data.level,
          data.tokenId,
          data.nonce,
          data.blockLimit,
          data.signature
        )
        .send({
          from: state.account,
          gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
        })
    } catch (err) {
      Warn('migrateRoyaltyNFT ', err)
      SentryLog(err, 'migrateRoyaltyNFT')
      throw new getRevertReason(err)
    }
  },

  async migrateRoyaltyNFT(
    { getters, state },
    { version, level, tokenId, onBlockchainPending }
  ) {
    console.log(
      'migrateRoyaltyNFT',
      version,
      level,
      tokenId,
      onBlockchainPending
    )
    try {
      const gasPrice = await state.web3.eth.getGasPrice()
      await getters.getNftMigrationContract.methods
        .migrate(version - 1, level, tokenId)
        .send({
          from: state.account,
          gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
        })
        .on('transactionHash', (hash) => {
          Log({ hash })
          onBlockchainPending && onBlockchainPending()
        })
        .on('confirmation', function(confirmationNumber, receipt) {
          Log('confirmation', confirmationNumber, receipt)
        })
        .on('receipt', function(receipt) {
          Log('receipt', receipt)
        })
        .catch((e) => {
          throw e
        })
    } catch (err) {
      Warn('migrateRoyaltyNFT ', err)
      SentryLog(err, 'migrateRoyaltyNFT')
      throw new getRevertReason(err)
    }
  },

  async migrateAllRoyaltyNFTs({ getters, state }, { onBlockchainPending }) {
    try {
      await getters.getNftMigrationContract.methods
        .migrateAll()
        .send({
          from: state.account,
        })
        .on('transactionHash', (hash) => {
          Log({ hash })
          onBlockchainPending && onBlockchainPending()
        })
        .on('confirmation', function(confirmationNumber, receipt) {
          Log('confirmation', confirmationNumber, receipt)
        })
        .on('receipt', function(receipt) {
          Log('receipt', receipt)
        })
        .catch((e) => {
          throw e
        })
    } catch (err) {
      Warn('migrateAllRoyaltyNFTs ', err)
      SentryLog(err, 'migrateAllRoyaltyNFTs')
      throw new getRevertReason(err)
    }
  },

  // burn nft and get energy
  async decompileNFT({ getters, state }, { contractAddress, tokenId }) {
    try {
      const { data } = await nftsApi.getNftBurnSignature(
        state.account,
        contractAddress,
        tokenId
      )
      console.log('signature message:', data)
      console.log('getNftMigrationContract', data.level, tokenId)
      const gasPrice = await state.web3.eth.getGasPrice()
      await getters.getNftMigrationContract.methods
        .burnNFTAndGetEnergy(
          data.version,
          data.level,
          data.tokenId,
          state.web3.utils.toWei('' + data.energyAmount, 'wei'),
          data.nonce,
          data.blockLimit,
          data.signature
        )
        .send({
          from: state.account,
          gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
        })
    } catch (e) {
      Warn('decompileNFT ', e)
      SentryLog(e, 'decompileNFT')
      throw new getRevertReason(e)
    }
  },

  // burn nft and get energy
  async upgradeNFTRatingAndMigrateByDAI(
    { getters, state, dispatch },
    { contractAddress, tokenId }
  ) {
    try {
      const { data } = await nftsApi.getNftRatingUpgradeAndMigrateSignature(
        state.account,
        contractAddress,
        tokenId
      )
      const userId = await dispatch('getMetaCoreId', state.account)
      const amount = state.web3.utils.toBN(data.amount)
      await dispatch('approveOnMetapayment', {
        userId: userId,
        contract: getters.getMFSContract,
        amount: amount,
      })
      const gasPrice = await state.web3.eth.getGasPrice()
      await getters.getNftMigrationContract.methods
        .ratingUpgradeAndMigrate(
          data.version,
          data.level,
          data.tokenId,
          data.token,
          amount,
          data.nonce,
          data.blockLimit,
          data.signature
        )
        .send({
          from: state.account,
          gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
        })
    } catch (e) {
      Warn('upgradeNFTRatingAndMigrateByDAI ', e)
      SentryLog(e, 'upgradeNFTRatingAndMigrateByDAI')
      throw new getRevertReason(e)
    }
  },

  async sellNftToSystem({ getters, state }, { contractAddress, tokenId }) {
    try {
      const { data } = await nftsApi.getNftPriceThatSystemReadyToPaySignature(
        state.account,
        contractAddress,
        tokenId
      )

      const amountBN = state.web3.utils.toBN(data.amount)
      if (amountBN == state.web3.utils.toBN(0)) {
        throw new Error('')
      }

      console.log('getters', getters.getNftContract)
      console.log('state', state.contracts.royaltyNfts[3])
      const nftContract = getters.getNftContract(contractAddress)
      console.log('nft contract', nftContract)
      await nftContract.methods
        .approve(getters.getNftMigrationContract._address, tokenId)
        .send({
          ...getters.getSendParams,
          gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
        })
      // .on('transactionHash', (hash) => {
      //   Log({ hash })
      //   onAllowancePending && onAllowancePending()
      // })

      const gasPrice = await state.web3.eth.getGasPrice()
      await getters.getNftMigrationContract.methods
        .burnNFTAndGetAssets(
          data.version,
          data.level,
          data.tokenId,
          data.pool,
          data.token,
          data.amount,
          data.nonce,
          data.blockLimit,
          data.signature
        )
        .send({
          from: state.account,
          gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
        })
    } catch (e) {
      Warn('usellNftToSystem', e)
      SentryLog(e, 'sellNftToSystem')
      // throw new getRevertReason(e)
      throw e.response?.data
        ? new Error(e.response.data)
        : new Error('Transaction has failed')
    }
  },

  async swapSfcNew({ getters, state }, { onBlockchainPending }) {
    try {
      const gasPrice = await state.web3.eth.getGasPrice()
      let txHash
      const res = await getters.getSFCNewContract.methods
        .mint(state.account, 100) // amount will autocalculate by smartcontract
        .send({
          from: state.account,
          gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
          // ...getters.getSendParams
        })
        .on('transactionHash', (hash) => {
          txHash = hash
          Log({ hash })
          onBlockchainPending && onBlockchainPending()
        })
        .on('confirmation', function(confirmationNumber, receipt) {
          Log('confirmation', confirmationNumber, receipt)
        })
        .on('receipt', function(receipt) {
          Log('receipt', receipt)
        })
        .catch((e) => {
          if (e.message.includes('not mined within')) {
            const error = Error(vm.$t('lostTxs.buyWait'))
            error.status = 202
            throw error
          } else {
            throw e
          }
        })

      Log('swapSfc1: ', res)
      Log('swapSfc1 hash: ', txHash)
      return [null, null]
    } catch (err) {
      Warn('swapSfc1', err)
      SentryLog(err, 'swapSfc1')

      const errParsed = getRevertReason(err)
      return [errParsed, null]
    }
  },

  async alreadyReceivedEmptyNFT({ getters, dispatch }, { address }) {
    // @TODO move to cache
    const userId = await dispatch('getMetaCoreId', address)
    try {
      for (let version = 1; version <= LAST_NFT_VERSION; version++) {
        let received = false
        if (version == 1) {
          received = await getters
            .getNftControlContract(version)
            .methods.alreadyReceivedEmptyNFT(address)
            .call()
        } else {
          received = await getters
            .getNftControlContract(version)
            .methods.alreadyReceivedEmptyNFT(userId)
            .call()
        }
        if (received) {
          return true
        }
      }
      return false
    } catch (e) {
      return false
    }
  },

  async alreadySwappedSFC({ getters }, address) {
    return await getters.getSFCNewContract.methods.minted(address).call()
  },

  async signMessage({ state }, message) {
    return await state.web3.eth.personal.sign(message, state.account)
  },

  async isRegisteredInMeraCore({ getters }, account) {
    return (await getters.getMetaCore.methods.getUserId(account).call()) > 0
  },

  async getMetaCoreId({ getters, commit }, account) {
    const userId = await getters.getMetaCore.methods.getUserId(account).call()
    commit('setMetaCoreId', userId)
    return userId
  },

  async isChangedForceId({ getters }, userId) {
    return await getters.getMetaCore.methods.isChangeAl(userId).call()
  },

  async isHasForceIdInMetaCore({ getters }, forceId) {
    return await getters.getMetaCore.methods.getUserIdByAlias(forceId).call()
  },

  async changeForceId({ getters, state }, newForceId) {
    const gasPrice = await state.web3.eth.getGasPrice()
    const gasAggressive = Math.round(
      Number(gasPrice) * DICT.ESTIMATED_GAS_PRIORIY
    )
    try {
      await getters.getMetaCore.methods.setSelfAlias(newForceId).send({
        from: state.account,
        gasPrice: gasAggressive,
        gas: DICT.DEFAULT_GAS_LIMIT
      })
        .on('transactionHash', (hash) => {
          Log(hash)
        })
      return [null, true]
    } catch (err) {
      console.log(err)
      return [true, null]
    }
  },
  async secondTimeChangeForceId({ state, getters }, newForceId) {
    const one = state.web3.utils.toWei(state.web3.utils.toBN("1"), 'ether')
    const price = state.web3.utils.toWei(state.web3.utils.toBN(100), 'ether')

    const gasPrice = await state.web3.eth.getGasPrice()
    const gasAggressive = Math.round(
      Number(gasPrice) * DICT.ESTIMATED_GAS_PRIORIY
    )
    try {
      const allowance = await getters.getMFSContract.methods
        .allowance(getters.getAccount, DICT.CONTRACT_METACORE)
        .call()
      if (price.gt(allowance)) {
        const estimateApprove = await getters.getMFSContract.methods
          .approve(DICT.CONTRACT_METACORE, price.add(one)).estimateGas({
            ...getters.getSendParams
          });
        await getters.getMFSContract.methods
          .approve(DICT.CONTRACT_METACORE, price.add(one))
          .send({
            ...getters.getSendParams,
            gasPrice: gasAggressive,
            gas: Math.round(estimateApprove * DICT.ESTIMATED_GAS_PRIORIY),
          })
          .on('transactionHash', (hash) => {
            Log(hash)
          })
      }

      await getters.getMetaCore.methods.setSelfAlias(newForceId)
        .send({
          from: getters.getAccount,
        })
        .on('transactionHash', (hash) => {
          Log({ hash })
        })
      return [null, true]
    } catch (err) {
      console.log(err)
      return [err, null]
    }
  },

  async checkMetaCoreRegistration(
    { dispatch, getters, state },
    { onBlockchainPending }
  ) {
    if (await dispatch('isRegisteredInMeraCore', state.account)) {
      // if (await getters.isRegisteredInMeraCore(state.account)) {
      return true
    }
    // для чего тут gasPrice ?
    const gasPrice = await state.web3.eth.getGasPrice()

    await getters.getMainContract.methods
      .migrateToCore()
      .send({
        from: state.account,
        // для чего тут gasPrice ?
        gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
      })
      .on('transactionHash', (hash) => {
        Log({ hash })
        onBlockchainPending && onBlockchainPending()
      })
      .on('confirmation', function(confirmationNumber, receipt) {
        Log('confirmation', confirmationNumber, receipt)
      })
      .on('receipt', function(receipt) {
        Log('receipt', receipt)
      })
      .catch((e) => {
        throw e
      })
  },

  async getMetaPaymentBalancesFormatted({ getters, dispatch, commit }, account) {
    // @TODO move to the cache
    const userId = await dispatch('getMetaCoreId', account)
    if (userId === 0) {
      throw new Error('Account does not registered in meta core')
    }
    const tokens = [
      DICT.CONTRACT_MFS,
      DICT.CONTRACT_FORCECOIN,
      DICT.CONTRACT_ENERGYCOIN,
    ]

    const promises = []
    for (let i in tokens) {
      const token = tokens[i]
      promises[i] = tokenFormatFromWei(
        await getters.getMetaPayment.methods.getBalance(token, userId).call()
      )
    }
    if (promises.length === 3) {
      commit('setInnerCoins', promises)
    }
    return await Promise.all(promises)
  },

  async getMetaPaymentReservedAddress({ getters, dispatch }, account) {
    const userId = await dispatch('getMetaCoreId', account)
    const reservedAddress = await getters.getMetaPayment.methods
      .getReservedAddress(userId)
      .call()
    return reservedAddress === '0x0000000000000000000000000000000000000000'
      ? null
      : reservedAddress
  },

  async getMetaPaymentDirectPaymentStatus({ getters, dispatch }, account) {
    const userId = await dispatch('getMetaCoreId', account)
    return await getters.getMetaPayment.methods
      .getDirectPaymentStatus(userId)
      .call()
  },

  async setMetaPaymentDirectPaymentStatus(
    { getters, state },
    { status, onBlockchainPending }
  ) {
    try {
      await getters.getMetaPayment.methods
        .setDirectPayment(status)
        .send({
          from: state.account,
        })
        .on('transactionHash', (hash) => {
          Log({ hash })
          onBlockchainPending && onBlockchainPending()
        })
        .on('confirmation', function(confirmationNumber, receipt) {
          Log('confirmation', confirmationNumber, receipt)
        })
        .on('receipt', function(receipt) {
          Log('receipt', receipt)
        })
        .catch((e) => {
          if (e.message.includes('not mined within')) {
            // saveTx({
            //     tx: txHash,
            //     action: "buy",
            //     params: { account, lvl: lvl }
            // })
            const error = Error(vm.$t('lostTxs.buyWait'))
            error.status = 202
            throw error
          } else {
            throw e
          }
        })

      return status
    } catch (err) {
      Warn('setDirectPaymentStatus ', err)
      SentryLog(err, 'setDirectPaymentStatus')
      throw new getRevertReason(err)
    }
  },
  async getClassicLevel({ getters, dispatch }, account) {
    const userId = await dispatch('getMetaCoreId', account)
    return await getters.getMainContract.methods.getLevelForNFT(userId).call()
  },
  async getUVLevel({ getters, dispatch }, account) {
    try {
      const userId = await dispatch('getMetaCoreId', account)
      return await getters.getUVCore.methods.getLevelForNFT(userId).call()
    } catch (err) {
      console.log(err)
      throw err
    }
  },
  async getMFSRate({ getters }, decimals = 'ether') {
    const rate = await getters.getUVCore.methods.priceMFSInUSD().call()
    return tokenFormatFromWei(rate, decimals)
  },

  async claimRoyaltyDistributionReward(
      { getters, state },
      { distributionId, levels, tokenIds, amounts, sigData, onBlockchainPending }
  ) {
    const gasPrice = await state.web3.eth.getGasPrice()
    const confirmationPromise = new Promise((resolve, reject) => {

        const waitConfirmations = 15;
        let shouldBe = 1;
        console.log('getters.getRoyaltyDistributionContract', distributionId, levels, tokenIds, amounts, sigData)
        getters.getRoyaltyDistributionContract.transactionConfirmationBlocks = waitConfirmations;
        console.log('getters.getRoyaltyDistributionContract', getters.getRoyaltyDistributionContract.transactionConfirmationBlocks)
        // console.log('contract', contract) 
        // contract.setConfig({
        //   transactionConfirmationBlocks: waitConfirmations
        // })
        getters.getRoyaltyDistributionContract.methods
            .claimReward(distributionId, levels, tokenIds, amounts, {
              blockLimit: sigData.blockLimit,
              signature: sigData.signature
            })
            .send({
              from: state.account,
              gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
            })
            .on('transactionHash', (hash) => {
              Log({ hash })
              onBlockchainPending && onBlockchainPending()
            })
            .on('confirmation', function(confirmationNumber, receipt) {
              Log('confirmation', confirmationNumber, receipt)
              shouldBe += 1;
              if (shouldBe > waitConfirmations) {
                resolve();
              }
            })
            .on('receipt', function(receipt) {
              Log('receipt', receipt)
            })
            .catch((err) => {
              reject(new getRevertReason(err))
              //throw err
            })

    })
    await confirmationPromise
  },

  async getIsUserBlockedInRoyalty({ getters, dispatch }, account) {
    // @TODO move to cache
    const userId = await dispatch('getMetaCoreId', account)
    return await getters
      .getNftControlContract()
      .methods.blockedUsers(userId)
      .call()
  },

  async transferTokenFromInner({ getters, state, dispatch }, amount) {
    const price = state.web3.utils.toWei(amount.toString(), 'ether')
    const gasPrice = await state.web3.eth.getGasPrice()
    const gasAggressive = Math.round(
      Number(gasPrice) * DICT.ESTIMATED_GAS_PRIORIY
    )
    try {
      await getters.getMetaPayment.methods.claim(DICT.CONTRACT_MFS, price)
        .send({
          from: state.account,
          gasPrice: gasAggressive,
          gas: DICT.DEFAULT_GAS_LIMIT
        })
        .on('transactionHash', (hash) => {
          Log({ hash })
        })

      //getting actual dai from inner balance
      await dispatch('getMetaPaymentBalancesFormatted', state.account)
      await dispatch('getWalletDai', state.account)
      return [true, null]
    } catch (err) {
      console.log(err)
      return [null, err]
    }
  },

  async transferTokenFromWallet({ getters, state, dispatch }, amount) {
    const one = state.web3.utils.toBN(state.web3.utils.toWei('1', 'wei'))
    const price = state.web3.utils.toBN(state.web3.utils.toWei((amount * 10**18).toString(), 'wei'))

    console.log('price', price, amount)

    const gasPrice = await state.web3.eth.getGasPrice()
    const gasAggressive = Math.round(
      Number(gasPrice) * DICT.ESTIMATED_GAS_PRIORIY
    )
    try {

      const allowance = await getters.getMFSContract.methods
        .allowance(getters.getAccount, DICT.CONTRACT_METAPAYMENT)
        .call()

      if (price.gt(allowance)) {
        // if (Number(allowance) < Number(price)) {
        const estimateApprove = await getters.getMFSContract.methods
          .approve(DICT.CONTRACT_METAPAYMENT, price.add(one)).estimateGas({
            ...getters.getSendParams
          });
        await getters.getMFSContract.methods
          .approve(DICT.CONTRACT_METAPAYMENT, price.add(one))
          .send({
            ...getters.getSendParams,
            gasPrice: gasAggressive,
            gas: Math.round(estimateApprove * DICT.ESTIMATED_GAS_PRIORIY),
          })
          .on('transactionHash', (hash) => {
            Log(hash)
          })
      }

      const estimateMetaPayment = await getters.getMetaPayment.methods.add(DICT.CONTRACT_MFS, price).estimateGas({
        ...getters.getSendParams
      })

      await getters.getMetaPayment.methods.add(DICT.CONTRACT_MFS, price)
        .send({
          from: getters.getAccount,
          gasPrice: gasAggressive,
          gas: Math.round(estimateMetaPayment * DICT.ESTIMATED_GAS_PRIORIY)
        })
        .on('transactionHash', (hash) => {
          Log({ hash })
        })
      //getting actual dai from inner balance
      await dispatch('getMetaPaymentBalancesFormatted', state.account)
      await dispatch('getWalletDai', state.account)
      return [true, null]
    } catch (err) {
      console.log(err)
      return [null, err]
    }
  },

  async getWalletDai({ getters, commit, dispatch }, account) {
    let dai = await getters.getMFSContract.methods.balanceOf(account).call()
    commit('setWalletDai', tokenFormatFromWei(dai))

    await dispatch('getNonce', account)
  },

  async getTransactionDaiHistory({commit}, payload) {
    const [err, history] = await getWalletHistoryService(payload)
    if (err) {
      throw err
    }
    commit('setTransactionDaiHistory', history.data)
    commit('setDaiHistoryPage', history.meta)
    commit('isTransactionDaiHistoryFetched')
  },
  async getMoreTransactionDaiHistory({commit}, payload) {
    const [err, history] = await getWalletHistoryService(payload)
    if (err) {
      throw err
    }
    commit('setMoreTransactionDaiHistory', history.data)
    commit('setDaiHistoryPage', history.meta)
    commit('isTransactionDaiHistoryFetched')
  },

  async setTransactionDaiHistory({commit}, payload) {
    const [err, history] = await setWalletHistoryService(payload)
    if (err) {
      throw err
    }
    commit('addTransactionDaiHistory', history)
  },
  async blockInnerBalance({getters, state}) {
    const gasPrice = await state.web3.eth.getGasPrice()
    const gasAggressive = Math.round(
      Number(gasPrice) * DICT.ESTIMATED_GAS_PRIORIY
    )
    try {
      await getters.getMetaPayment.methods.blockAccount().send({
        from: getters.getAccount,
        gasPrice: gasAggressive,
        gas: DICT.DEFAULT_GAS_LIMIT,
      })
      return [null, true]
    } catch (err) {
      console.log(err)
      return [true, null]
    }
  },
  async getIsBlockedInner({getters, dispatch}) {
    try {
      const userId = await dispatch('getMetaCoreId', getters.getAccount)
      return await getters.getMetaPayment.methods.getBlockedAccount(userId).call()
    } catch (e) {
      console.log(e)
      return false
    }
  }
}
