import { Warn } from "@/helpers/dev"
import moment from 'moment/moment'

export const getLocalStorageElement = (name) => {
    let el = localStorage.getItem(name)
    if (el) return JSON.parse(el)

    return null
}

export const setLocalStorageElement = (name, value, expirationDays) => {
    try {
        if (expirationDays) {
            const currentDate = moment();
            const expirationDate = moment(currentDate).add(expirationDays, 'days');

            const item = {
                value: value,
                expiration: expirationDate, // Преобразуем дату истечения в миллисекунды
            };

            localStorage.setItem(name, JSON.stringify(item));
        } else {
            localStorage.setItem(name, JSON.stringify(value))
        }
    } catch (err) {
        Warn(`LocalStorage error on ${name}`, value)
    }
}
