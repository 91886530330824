<template>
  <div class="footer-wrapper" v-if="isMobileMessenger" >
  </div>
  <div v-else>
    <footer class="footer">
      <p class="footer__rights">
        {{ `© ${new Date().getFullYear()} ${$t('rights')}` }}
      </p>
      <button @click="setTermsModal({ visible: true })" class="footer__terms">
        {{ $t('terms') }}
      </button>
      <div class="footer__version">v{{ appVersion }}</div>
    </footer>

    <UiModal
        :visible="termsModal.visible"
        modifier="wide"
        :title="$t('termsModal.title')"
        @closeModal="closeModal"
    >
      <p class="text-justify">
        {{ $t('termsModal.content') }}
      </p>
      <button
          class="activation-modal__pay btn-reset glob-btn modal-btn"
          type="button"
          @click="closeModal"
      >
        <span>{{ $t('termsModal.agree') }}</span>
      </button>
    </UiModal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { encryptUser }  from "@/helpers/bcriptData"
import { isDevelopment } from '@/config/isDevelopment'
export default {
  name: 'Footer-Page',
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
      isMobileMessenger: true,
      currentRoute: this.$route.path,
      isInitGlobalSocket: false,
      socketGlobal: null,
      socketIntervalConnect: null,
      refreshInterval: null,
      pingObj: {
        event: 'ping'
      },
      initMessages: { event: 'init' },
      refreshMessage: { event: 'refresh' }
    }
  },
  mounted() {
    if (this.getCurrentUser) {
      this.setHasToken(true)
    }

    this.checkDeviceType();
    window.addEventListener('resize', this.checkDeviceType);
  },
  watch: {
    getLastInitDone(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (this.isInitGlobalSocket === false) {
          this.globalSocketConnect()
          this.isInitGlobalSocket = true
        }
      }
    },
    '$route'(to, from) {
      if (to.path || from.path === '/messenger') {
        this.checkDeviceType();
      }
    },
  },
  computed: {
    ...mapGetters('user', ['getCurrentUser','getLastInitDone']),
    ...mapGetters('messenger', ['chat', 'getActualChats']),
    ...mapGetters('ui', ['termsModal']),

    globalUrl() {
      let url = `wss://${isDevelopment ? process.env.VUE_APP_WEBSOCKETS_HOST_TEST : process.env.VUE_APP_WEBSOCKETS_HOST}`;
      url += `auth=${encryptUser(
        this.getCurrentUser.id,
        process.env.VUE_APP_WEBSOCKETS_SECRET,
        process.env.VUE_APP_WEBSOCKETS_SALT,
      )}`;
      url += `&appKey=${isDevelopment ? process.env.VUE_APP_WEBSOCKETS_KEY_TEST : process.env.VUE_APP_WEBSOCKETS_KEY}`;
      return url
    }
  },
  methods: {
    ...mapMutations('ui', ['setTermsModal']),
    ...mapMutations('messenger', [
      'setHasToken',
      'SET_MESSENGER_LOADER',
      'setUnreadChatMessagesInit',
      'addUnreadMessagesChat',
      'filterUnreadChat',
      'setChatFromSocket',
      'SET_UNLOCK_USER_WS',
      'SET_BLOCK_CHAT_WS',
      'SET_ONLINE_WS',
    ]),
    ...mapActions("messenger",[
      "UPDATE_SHOW_MESSAGES",
      "GET_ACTIVE_CHATS",
      "SELECT_CHAT"
    ]),
    ...mapActions('ticket',['addSocketNotification']),
    ...mapMutations('ticket', ['ticketStatusUpdate', 'setUnreadHelpMessagesInit']),
    ...mapMutations('user', ['setFollowersCount', 'setFollowingCount']),
    ...mapMutations('social', ['setFollowDataSaved', 'setFollowersDataSaved']),
    ...mapActions('user', ['updateLocalUser']),
    closeModal() {
      this.setTermsModal({ visible: false })
    },
    async globalSocketConnect() {
      try {
        this.socketGlobal = await new WebSocket(this.globalUrl)

        this.socketGlobal.onopen = () => {
          try {
            setTimeout(() => {
              this.socketGlobal.send(JSON.stringify(this.initMessages))
            }, 200)
            this.socketIntervalConnect = setInterval(() => {
              this.socketGlobal.send(JSON.stringify(this.pingObj))
            },30000)
            // this.refreshInterval = setInterval(() => {
            //   this.socketGlobal.send(JSON.stringify(this.refreshMessage))
            // }, 240000)
          } catch (e) {
            console.log('pong error: ',e)
            if (this.socketGlobal !== null) {
              this.socketGlobal.close()
            }
            clearInterval(this.socketIntervalConnect)
            // clearInterval(this.refreshInterval)
          }
        }

        this.socketGlobal.onmessage = async (data) => {
          const globalSocketObj = JSON.parse(data.data)

          // console.log('ws logging global: ', globalSocketObj)
          // console.log('event', globalSocketObj.event)

          if (globalSocketObj.event === 'notification-ticket-update') {
            this.ticketStatusUpdate(globalSocketObj.data)
          }

          if (
              globalSocketObj.event === 'notification-notify-create' ||
              globalSocketObj.event === 'notification-subscribe-followed' ||
              globalSocketObj.event === 'notification-subscribe-follower-update-social' ||
              globalSocketObj.event === 'notification-subscribe-follower-update-nickname' ||
              globalSocketObj.event === 'notification-subscribe-follower-update-bio' ||
              globalSocketObj.event === 'notification-subscribe-follower-update-avatar' ||
              globalSocketObj.event === 'notification-api'
          ) {
            await this.addSocketNotification(globalSocketObj.data)
          }

          if (globalSocketObj.event === 'notification-user-online') {
            this.SET_ONLINE_WS([globalSocketObj.data, true])
          }

          if (globalSocketObj.event === 'notification-user-offline') {
            this.SET_ONLINE_WS([globalSocketObj.data, false])
          }

          if (globalSocketObj.event === 'notification-subscribe-followed') {
            this.setFollowersCount({increment: true, count: 1})
            await this.updateLocalUser(this.getCurrentUser)
            this.setFollowersDataSaved(false)
          }
          if (globalSocketObj.event === 'notification-subscribe-unfollowed') {
            this.setFollowersCount({increment: false, count: 1})
            await this.updateLocalUser(this.getCurrentUser)
            this.setFollowersDataSaved(false)
          }
          if (globalSocketObj.event === 'notification-subscribe-unlink') {
            this.setFollowingCount({increment: false, count: 1})
            await this.updateLocalUser(this.getCurrentUser)
            this.setFollowDataSaved(false)
          }

          if (globalSocketObj.event === 'notification-chat-create') {
            await this.GET_ACTIVE_CHATS()
          }
          if (globalSocketObj.event === 'notification-chat-delete') {
            this.SET_MESSENGER_LOADER(true)
            this.filterUnreadChat(globalSocketObj)
            try {
              if (this.chat && this.chat.chat_key === globalSocketObj.chat_key) {
                this.SELECT_CHAT(null)
                // this.$toast.success(`${this.$t('messenger.deletedChat')}`)
                await this.GET_ACTIVE_CHATS()
                this.SET_MESSENGER_LOADER(false)
              } else {
                await this.GET_ACTIVE_CHATS()
                this.SET_MESSENGER_LOADER(false)
              }
            } catch (e) {
              this.SET_MESSENGER_LOADER(false)
              console.log(e)
            }
          }

          if (globalSocketObj.event === 'notification-messenger-blocked') {
            this.SET_BLOCK_CHAT_WS({ id: globalSocketObj.data.blocked_from })
          }

          if (globalSocketObj.event === 'notification-messenger-unblocked') {
            this.SET_UNLOCK_USER_WS({ id: globalSocketObj.data.from })
          }

          if (globalSocketObj.event === 'notification-init-state') {
            if (globalSocketObj.chat_list.length !== 0) {
              this.setUnreadChatMessagesInit(globalSocketObj.chat_list)
            }
            if (globalSocketObj.unread_tickets_count !== 0) {
              this.setUnreadHelpMessagesInit(globalSocketObj.unread_tickets_count)
            }
          }

          if (globalSocketObj.event === 'notification-message-create') {
            const indexToUpdate = this.getActualChats.findIndex(user => user.chat_key === globalSocketObj.chat_key);

            if (indexToUpdate !== -1) {
              this.setChatFromSocket({
                index: indexToUpdate,
                message: globalSocketObj.data.message,
                attachments_count: globalSocketObj.data.attachments_count,
                created_at: globalSocketObj.created_at,
              });
            }
            if (globalSocketObj.title === this.getCurrentUser.name) return
            if (this.chat === null) {
              this.addUnreadMessagesChat({
                chat_key: globalSocketObj.chat_key,
                unread: 1,
              })
            } else {
              if (this.chat && this.chat.chat_key !== globalSocketObj.chat_key) {
                this.addUnreadMessagesChat({
                  chat_key: globalSocketObj.chat_key,
                  unread: 1,
                })
              }
            }
          }

          if (globalSocketObj.event === 'notification-message-edit') {
            const indexToUpdate = this.getActualChats.findIndex(user => user.chat_key === globalSocketObj.chat_key);
            if (indexToUpdate !== -1) {
              this.setChatFromSocket({
                index: indexToUpdate,
                message: globalSocketObj.data.message,
                attachments_count: globalSocketObj.data.attachments_count,
              });
            }
          }
        };

        this.socketGlobal.onclose = (event) => {
          if (event.code !== 1000) {
            console.error('WebSocket closed unexpectedly:', event);
            if (this.socketGlobal !== null) {
              this.socketGlobal.close()
            }
            clearInterval(this.socketIntervalConnect)
          }
        };
        this.socketGlobal.onerror = (error) => {
          console.error('WebSocket error:', error)
          if (this.socketGlobal !== null) {
            this.socketGlobal.close()
          }
          clearInterval(this.socketIntervalConnect);
        };
      } catch (e) {
        console.log('globalSocket error', e)
      }
    },
    checkDeviceType() {
      // this.isMobileMessenger = window.innerWidth <= 768 && this.$route.path === '/messenger';
      this.isMobileMessenger = true; //display footer none
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDeviceType);
    if (this.socketGlobal !== null) {
      this.socketGlobal.close()
    }
    clearInterval(this.socketIntervalConnect)
    this.setHasToken(false)
  },
}
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--indent-16);
  padding: 0 var(--indent-24);
  width: 100%;
  height: 64px;
  background: var(--color-black-800);
  //margin-top: 10px;
  &-wrapper {
    margin-top: inherit;
  }
  p {
    margin-bottom: 0;
  }
  &-flags {
    display: flex;
    align-items: center;
  }
  &-flag {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }

  &__terms {
    cursor: pointer;
    border: none;
    background: unset;
    border-bottom: solid 1px var(--color-white-50);
    transition: color 500ms;
    &:hover {
      color: var(--color-white-50);
    }
  }

  &__btn {
    background: unset;
    border: unset;
  }

  &__links {
    display: flex;
    gap: 10px;
  }

  .modal-btn {
    display: block;
  }

  @media (max-width: 992px) {
    height: auto;
  }
}

.text-justify {
  text-align: justify;
}

@media screen and (max-width: 960px) {
  .footer {
    flex-direction: column;

    &__rights {
      order: 4;
      margin-top: 10px;
    }

    &__links {
      order: 1;
    }

    &__terms {
      order: 2;
      margin-top: 10px;
    }

    &__version {
      order: 5;
      margin-top: 10px;
    }
  }
}
</style>
