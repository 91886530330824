import moment from "moment"
import { LSTORAGE } from "@/config/constants"
// import "moment/locale/zn-cn"

const init = (app) => {
    let locale = localStorage.getItem(LSTORAGE.lang)
    if (locale === "cn") {
        locale = "zh-cn"
    }

    moment.locale(locale)
    app.prototype.$moment = moment
}

export default init
