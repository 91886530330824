export const formatNumberWithSpaces = (numberString) => {
  if (numberString) {
    const cleanedString = numberString.toString().replace(/[ .]/g, '');
    const parts = cleanedString.trim().split(' ');

    const mergedString = parts.join('');

    const groups = [];
    for (let i = mergedString.length; i > 0; i -= 3) {
      groups.unshift(mergedString.slice(Math.max(0, i - 3), i));
    }
    return groups.join(' ');
  } else {
    return '-'
  }
}