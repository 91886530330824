<template>
  <header class="header" :class="[simple && 'is-simple']">
<!--    <a class="header__logo" href="/">-->
<!--      <img src="/assets/account/logo.png" alt="logo force" />-->
<!--    </a>-->

    <template v-if="simple">
      <p
        @click.stop="handleAuthClick('login')"
        class="header__loginBtn"
      >
        {{ $t('landing.menu.home') }}
      </p>
    </template>

    <div class="header__inner header-bottom">
      <p class="dashboard-logo-xs">
        <BaseIcon name="mainLogo" verAlign @event='$router.push("/")' />
      </p>
      <div class="breadcrumbs">
        <BreadCrumbs />
      </div>
      <div class="" v-if="isConnecting">
        <UiLoader :loading="true" />
      </div>

      <div class="header__items" v-else-if="isConnected">
        <div class="currencies" v-if='getInnerCoins.length'>
          <div class="currency">
            <div class="currency__img">
              <BaseIcon :name="headerMatic.iconName" verAlign />
            </div>
            <div class="currency__balance">
              {{ shortAmount(headerMatic.amount) }} {{ headerMatic.label}}
            </div>
          </div>
          <div class="currency">
            <div class="currency__img">
              <BaseIcon :name="headerDai.iconName" verAlign />
            </div>
            <div class="currency__balance">
              {{ shortAmount(headerDai.amount) }} {{ headerDai.label}}
            </div>
          </div>
          <div class="currency">
            <div class="currency__img">
              <BaseIcon :name="headerForceCoin.iconName" verAlign />
            </div>
            <div class="currency__balance">
              {{ shortAmount(headerForceCoin.amount) }} {{ headerForceCoin.label}}
            </div>
          </div>
        </div>
        <div class="header__wallet" @click.stop='isModalHundler'>
          <BaseIcon name="wallet" verAlign @event="isModalHundler" />
        </div>
        <Bell :count="getUnreadNotifications" @bellEvent="isNoticeMessageHandler" />
        <Language
          :options="langs"
        />
        <BaseIcon class="service-open" name="serviceIcon" @event='openServiceModal' verAlign />
        <BaseIcon class="service-open service-open-xs" name="serviceIconDashboardXs" @event='openServiceModal' verAlign />
      </div>
      <div class="header__items" v-else>
        <ButtonDark :text="$t('connectWallet')" @event="openModal" class='connectBtn' />
        <Language
          :options="langs"
        />
        <BaseIcon class="service-open" name="serviceIcon" @event='openServiceModal' verAlign />
      </div>
    </div>
    <ConnectModal />
    <ServiceModal
      :isModal="isServiceModal"
      @closeWallet="closeServiceModal"
      @openWallet='isModalHundler'
      @openNotice='isNoticeMessageHandler'
    />
    <WalletModal :isModal="isModal" @closeWallet="isModal = false" />
    <MessageModal
      :isNoticeMessage="isNoticeMessage"
      @closeNoticeMessage="isNoticeMessage = false"
      @closeService='closeAllModal'
    />

    <CopyLinkModal
      :isModal='this.isCopyLinkModal'
    />
    <CopyLinkWindow />

  </header>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ConnectModal from '@/components/Layout/ConnectModal.vue'

import WalletModal from '@/components/Layout/WalletModal.vue'
import MessageModal from '@/components/Dashboard/MessageModal.vue'
import ServiceModal from '@/components/Layout/ServiceModal.vue'
import Language from '../UI/Language.vue'
import { isServiceMode } from '@/helpers/dev'
import { nullEmptyHash } from '@/helpers/matrix'
import { getConnectTypeFromSelectWallet } from '@/helpers/params'
import BaseIcon from '@/components/UI/BaseIcon.vue'
import Bell from '@/components/UI/Bell.vue'
import ButtonDark from '@/components/UI/ButtonDark.vue'
import BreadCrumbs from '@/components/UI/BreadCrumbs.vue'
import { shortSumToken } from '@/helpers/shortSumToken'
import { LANGS } from '@/config/constants'
import CopyLinkModal from "@/pages/dashboard/partials/copyLinkModal.vue";
import CopyLinkWindow from "@/pages/dashboard/partials/copyLinkWindow.vue";

export default {
  props: {
    simple: Boolean,
  },
  components: {
    ConnectModal,
    Language,
    BaseIcon,
    WalletModal,
    Bell,
    ButtonDark,
    BreadCrumbs,
    MessageModal,
    ServiceModal,
    CopyLinkModal,
    CopyLinkWindow
  },
  data() {
    return {
      langs: LANGS,
      isModal: false,
      isServiceModal: false,
      isNoticeMessage: false,
      unreadNotifications: 1,
    }
  },

  async mounted() {
    await this.fetchAccount()
    window.addEventListener('click', (event) => {
      if (!this.$el.contains(event.target)) {
        this.isModal = false
      }
    })
    localStorage.removeItem('walletChanged')
    if (+this.getAllUsersInfoVariables === 0) {
      //   this.setUsersInfoFromLocal()
      // } else {
      await this.getUsersInfo()
    }
  },
  beforeDestroy() {
    if (this.getAccount !== this.getCurrentUser.account) {
      localStorage.removeItem('currentUserLocal')
      localStorage.removeItem('currentParent')
    }
  },

  computed: {
    ...mapGetters('user', ['getInitDone', 'getCurrentUser', 'getAllUsersInfoVariables']),
    ...mapGetters('ui', ['isCopyLinkModal','isCopyLinkWindow']),
    ...mapGetters('ticket', ['getUnreadNotifications', 'getIsNoticeLoaded']),
    ...mapGetters('crypto', [
      'isConnecting',
      'isConnected',
      'getAccount',
      'getParent',
      'getConnectionWallet',
      'getToken',
      'getBalance',
      'getInnerCoins',
      'getTotalDai'
    ]),
    statTokens() {
      if (!this.getBalance) return []
      const res = []
      const coinValues = Object.values(this.getBalance).map((el) => +el)
      const coinNames = ['Matic', 'SFC old', 'SFC new', 'SFC2', 'DAI', 'Energy']
      coinValues.forEach((el, idx) => {
        res.push({
          iconName: `header${coinNames[idx].replace(/[' ']/gi, '')}`,
          label: coinNames[idx],
          amount: el,
        })
      })
      return res
    },
    headerMatic() {
      return this.statTokens.find((el) => el.iconName.includes('headerMatic'))
    },
    headerDai() {
      let dai = this.statTokens.find((el) => el.iconName.includes('headerDAI'))
      dai.amount = this.getTotalDai
      return dai
    },
    headerForceCoin() {
      let forceCoin = {
        iconName: 'headerForceCoin',
        label: 'MFS',
        amount: ''
      }
      forceCoin.amount = this.getInnerCoins[1]
      return forceCoin
    },
  },

  methods: {
    ...mapActions('ticket', ['getNotificationAction']),
    ...mapActions('crypto', ['checkConnect', 'logOut']),
    ...mapMutations('user', ['setUsersInfoFromLocal', 'setInitDone', 'setLastInitDone']),
    ...mapActions('user', ['guessUser','saveUser', 'getUsersInfo']),
    ...mapMutations('user', ['setUsersInfoFromLocal']),
    ...mapMutations('ticket', ['setNoticeLoader', 'setIsNoticeLoaded']),
    ...mapMutations('ui', ['setConnectionModal']),

    closeServiceModal() {
      this.isServiceModal = false
    },

    async fetchAccount() {
      this.loading = true
      try {
        let userResponse
        if (!this.getCurrentUser) {
          userResponse = await this.guessUser()
          await this.saveUser(userResponse)
          setTimeout(async () => {
            await this.firstFetchNotice()
          }, 4000)
        } else {
          setTimeout(async () => {
            await this.firstFetchNotice()
          }, 6000)
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },

    closeAllModal() {
      this.isNoticeMessage = false
      this.isServiceModal = false
    },
    openServiceModal() {
      this.isServiceModal = true
    },
    shortAmount(amount) {
      if (amount) {
        return shortSumToken(amount)
      }
      return `0.0`
    },

    openModal: function () {
      if (isServiceMode()) {
        this.$router.push({ name: 'service' })
      } else {
        this.setConnectionModal({ visible: true, type: 'connect' })
      }
    },
    async firstFetchNotice() {
      if (this.getIsNoticeLoaded) return
      try {
        this.setNoticeLoader(true)
        await this.getNotificationAction(this.getCurrentUser.id)
        this.setIsNoticeLoaded(true)
        this.setLastInitDone(true)
      } catch (e) {
        console.log(e)
      } finally {
        this.setNoticeLoader(false)
      }
    },

    async handleAuthClick() {
      this.setConnectionModal({ visible: true, type: 'connect' })
    },

    async processAuth() {
      const hasNoParent = nullEmptyHash(this.getParent) === null

      if (!hasNoParent) {
        await this.$router.push({ name: 'dashboard' })
      } else {
        this.$swal(this.$t('connectModal.swal.notRegistered'))
      }
    },

    isModalHundler() {
      this.isModal = !this.isModal
    },
    isNoticeMessageHandler() {
      this.isNoticeMessage = !this.isNoticeMessage
    },
  },
  watch: {
    // getInitDone(newVal, oldVal) {
    //   if (newVal && newVal === true) {
    //     this.firstFetchNotice()
    //   }
    // },
    getConnectionWallet(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        const type = getConnectTypeFromSelectWallet(newVal)
        if (type === 'login_check') {
          this.processAuth()
        }
      }
    },
  },
}
</script>
<style lang='scss'>
</style>
