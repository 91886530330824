// import "@babel/polyfill";
import '@/assets/scss/normalize.scss'
import '@/assets/scss/app.scss'
import '@/assets/scss/spa.scss'

// import "mutationobserver-shim"
import Vue from 'vue'
import router from './router'
import store from './store'
import initPlugins from './plugins'
import i18n from './plugins/i18n'
import VueAnalytics from 'vue-analytics'
// import * as Sentry from "@sentry/vue";
// import "~@/assets/scss/backCustom.scss";

import App from './App.vue'

import * as filters from '@/filters'
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

import * as components from '@/components/UI'

for (const name in components) {
  Vue.component(name, components[name])
}

import VueCountdown from '@chenfengyuan/vue-countdown';

Vue.component(VueCountdown.name, VueCountdown);

// init plugins from /plugins/index.js list
initPlugins(Vue)

Vue.config.productionTip = false

if (
  localStorage.getItem('clearStorage') !==
  process.env.VUE_APP_STORAGE_CLEAR_APP_VERSION
) {
  localStorage.clear()
  localStorage.setItem(
    'clearStorage',
    process.env.VUE_APP_STORAGE_CLEAR_APP_VERSION
  )
}

Vue.use(VueAnalytics, {
  id: 'G-L2ZPQPRZ9Q',
  debug: {
    sendHitTask: process.env.NODE_ENV === 'production',
  },
})

import VueGtag from 'vue-gtag'
if (process.env.NODE_ENV === 'production') {
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GTAG },
  })
}

import * as svgicon from 'vue-svgicon'
Vue.use(svgicon, {
  classPrefix: 'AppIcon-',
})

// sentry config
// Sentry.init({
//   Vue,
//   dsn: process.env.VUE_APP_SENTRY_PROJECT_URL,
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", 'https://napi.meta-force.space'],
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const vm = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

// @TODO delete
export { vm }
