<template>
  <div class="HomeLayout site-container">
    <Header @openService='openServiceFrom'></Header>
    <router-view />
    <Footer></Footer>

    <!-- move to store -->
    <div class="page-loader" :class="[processingModal.isShown && 'is-active']">
      <UiLoader :loading="true" />
      <div class="page-loader__message" v-html="processingModal.text" />
    </div>
    <ServiceModal
      :isModal="isServiceModal"
      @closeWallet="isServiceModal = false"
    />
  </div>
</template>

<script>
import Header from '@/components/Home/partials/Header.vue'
import Footer from '@/components/Home/partials/Footer.vue'
import { mapGetters } from 'vuex'
import ServiceModal from '@/components/Layout/ServiceModal.vue'
import { getLocalStorageElement, setLocalStorageElement } from '@/helpers/localstorage'

export default {
  name: 'HomeLayout',
  computed: {
    ...mapGetters('loader', ['processingModal']),
  },
  components: { Footer, Header, ServiceModal },
  data() {
    return {
      isServiceModal: false
    }
  },

  mounted() {
    if (getLocalStorageElement('currentUserLocal')) {
      const user = getLocalStorageElement('currentUserLocal')
      if (user.account !== getLocalStorageElement('myAccount')) {
        localStorage.removeItem('currentUserLocal')
      }
    }
    const head = document.getElementsByTagName('head')[0]

    const plugin = document.createElement('script')
    plugin.setAttribute('src', '//code.jivo.ru/widget/b5Vv0BBS3n')
    plugin.setAttribute('class', 'chatBotWidget')
    plugin.async = true
    head.appendChild(plugin)
    setLocalStorageElement('savedChatBot', true)
  },

  beforeDestroy() {
    const head = document.getElementsByTagName('head')[0]

    const plugin = head.getElementsByClassName('chatBotWidget');
    const link = head.getElementsByClassName('jv-css');
    const jivoDiv = document.getElementById('jivo-iframe-container');
    const jivoTagDiv = document.getElementsByTagName('jdiv')[0];

    const scriptElement = document.querySelector('.chatBotWidget');
    if (scriptElement) {
      scriptElement.parentNode.removeChild(scriptElement);
    }

    if (head && plugin[0]) {
      head.removeChild(plugin[0])
    }
    if (head && link[0]) {
      head.removeChild(link[0])
    }
    if (jivoTagDiv && jivoTagDiv.parentNode) {
      jivoTagDiv.parentNode.removeChild(jivoTagDiv)
    }
    if (jivoDiv && jivoDiv.parentNode) {
      jivoDiv.parentNode.removeChild(jivoDiv)
    }
  },
  methods: {
    openServiceFrom() {
      this.isServiceModal = true
    },
  }
}
</script>

<style lang="scss">
/*.HomeLayout {
  background: #1e2930;
  .wrapper {
    h1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 80px;
      color: #ffffff;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        margin-bottom: 32px;
        font-size: 40px;
        border: 2px solid #ffffff;
        border-radius: 50%;
      }
    }

    //
    //#faq {
    //  flex-direction: column;
    //  align-items: center;
    //  padding-top: 150px;
    //  height: auto;
    //}
    //
    //#footer {
    //  height: auto;
    //
    //  .footer__top {
    //    margin: 120px auto 54px;
    //  }
    //}
  }

  #article {
    flex-direction: column;
    align-items: center;
    padding-top: 150px;
    height: auto;

    h1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 80px;
      color: #ffffff;
      font-size: 72px;
      line-height: 60px;
      font-weight: 400;
    }

    article {
      width: calc(100% - 64px);
      padding: 80px 32px;
      background: #ffffff;
      border-radius: 24px;
      margin-left: 32px;

      p {
        margin: 8px auto;
        max-width: 800px;
        width: 100%;
        font-size: 18px;
        line-height: 160%;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  #faq {
    h1 {
      padding-top: 200px;
      font-size: 72px;
      line-height: 1.2;
    }
  }
}*/
</style>
